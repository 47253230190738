import * as React from 'react'
import Paper from '@material-ui/core/Paper'
import {
    CustomPaging,
    FilteringState,
    IntegratedFiltering,
    PagingState,
    SelectionState,
    SortingState,
} from '@devexpress/dx-react-grid'
import {
    Grid,
    PagingPanel,
    Table,
    TableBandHeader,
    TableFixedColumns,
    TableHeaderRow,
    TableSelection,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui'
import LinearProgress from '@material-ui/core/LinearProgress'
import { ClientApi } from 'sdk'
import { toast } from 'react-toastify'
import { ApproveTypeProvider } from './CustomColumns/clients/ApprovalProvider'
import NameTypeProvider from './CustomColumns/clients/NameProvider'
import { CpfCnpjTypeProvider } from './CustomColumns/clients/CpfCnpjProvider'
import { MonthlyIncomeTypeProvider } from './CustomColumns/clients/MonthlyIncomeProvider'
import { TotalAtivesTypeProvider } from './CustomColumns/clients/TotalAtivosProvider'
import { TotalCotesTypeProvider } from './CustomColumns/clients/TotalCotesProvider'
import { TotalFinancingTypeProvider } from './CustomColumns/clients/TotalFinancingProvider'
import { PrePaymentTotalTypeProvider } from './CustomColumns/clients/PrePaymentTotalProvider'
import { PrePayGteTypeProvider } from './CustomColumns/clients/PrePayGteProvider'
import { HighRiskProvider } from './CustomColumns/clients/HighRiskProvider'
import { StatusClientProvider } from './CustomColumns/clients/StatusProvider'
import { PrePagConsorcio10kProvider } from './CustomColumns/clients/PrePagConsorcio10kProvider'
import { MonthlyIncome11xProvider } from './CustomColumns/clients/MonthlyIncome11xProvider'
import { PrePagRetail10kProvider } from './CustomColumns/clients/PrePagRetail10kProvider'
import { PercentPrePagRendaProvider } from './CustomColumns/clients/PercentPrePagRendaProvider'
import { PrePagRetailTypeProvider } from './CustomColumns/clients/PrePagRetail'
import { PrePagConsTypeProvider } from './CustomColumns/clients/PrePagConsProvider'
import { ActionButtonTypeProvider } from './CustomColumns/clients/ActionButtonProvider'

import { DataContratoProvider } from './CustomColumns/Retail/DataContratoProvider'
import { ValorParcelaProvider } from './CustomColumns/Retail/ValorParcelaProvider'
import { DataVencimentoContratoProvider } from './CustomColumns/Retail/DataVencimentoContratoProvider'
import { EntradaProvider } from './CustomColumns/Retail/EntradaProvider'
import { ValorFinanciamentoProvider } from './CustomColumns/Retail/ValorFinanciamentoProvider'
import { ValorPago10kProvider } from './CustomColumns/Retail/ValorPago10kProvider'
import { PerPagCredTypeProvider } from './CustomColumns/Retail/PerPagCredProvider'

import { SaldoDevedorProvider } from './CustomColumns/Consorcio/SaldoDevedorProvider'
import { DataContemplacaoProvider } from './CustomColumns/Consorcio/DataContemplacaoProvider'
import { ValueCreditProvider } from './CustomColumns/Consorcio/ValueCreditProvider'
import { DataTransferenciaProvider } from './CustomColumns/Consorcio/DataTransferenciaProvider'
import { ValorPagoProvider } from './CustomColumns/Consorcio/ValorPagoProvider'
import { DataPagamentoProvider } from './CustomColumns/Consorcio/DataPagamentoProvider'
import { DataAdesaoProvider } from './CustomColumns/Consorcio/DataAdesaoProvider'
import { DataEntregaBemProvider } from './CustomColumns/Consorcio/DataEntregaBemProvider'
import { GTEConsorcio10kProvider } from './CustomColumns/Consorcio/GTE10kConsorcioProvider'

import { DataInclusaoProvider } from './CustomColumns/historico/DataInclusao'
import { ParecerAnalistaProvider } from './CustomColumns/historico/ParecerAnalista'
import { ParecerGerenteProvider } from './CustomColumns/historico/ParecerGerente'
import { ParecerSupergerenteProvider } from './CustomColumns/historico/ParecerSupergerente'
import { PendingProvider } from './CustomColumns/historico/Pending'

const TableRow = (props) => {
    // console.log(props);
    const border = props.row.monthly_income >= 10000 ? false : false
    return (
        <VirtualTable.Row
            { ...props }
            // eslint-disable-next-line no-alert
            // onClick={() => alert(JSON.stringify(props.row))}
            style={ {
                cursor: 'pointer',
                backgroundColor: border ? '#FFFFAA' : '',
            } }
        />
    )
}

const TableHead = (props) => {
    let bgColor = null
    // let borderColor = null

    switch (props.column.name) {
        case 'cpf_f':
        case 'name':
        case 'type':
        case 'highrisk':
        case 'status':
        case 'monthly_income':
        case 'v_prepag.total':
        case 'n_pre_pag.total':
        case 'n_pre_pag.total/renda':
        case 'v_prepag.gte_10k':
        case '11x_monthly_income':
            bgColor = null
            break

        case 'v_prepag.consorcio':
        case 'n_pre_pag.consorcio':
        case 'n_pre_pag.consorcio_10k':
        case 'n_pre_pag.consorcio_carta':
            bgColor = 'coral'
            break

        case 'v_prepag.retail':
        case 'n_pre_pag.retail':
        case 'n_pre_pag.retail_10k':
            bgColor = 'purple'
            break
    }

    return (
        <TableHeaderRow.Cell
            { ...props }
            // eslint-disable-next-line no-alert
            // onClick={() => alert(JSON.stringify(props.row))}
            style={ {
                color: bgColor,
            } }
        />
    )
}

export class DataTable extends React.Component {
    api

    constructor(props) {
        super(props)
        const {
            columns,
            sorting,
            api,
            pageSizes,
            pageSize,
            currentPage,
            tableColumnExtensions,
        } = this.props
        this.state = {
            columns,
            approveColumns: ['approves'],
            actionButtonColumns: ['actionButtonSomething'],
            nameColumns: ['name'],
            cpfCnpjColumns: ['cpf_f'],
            monthlyIncomeColumns: ['monthly_income'],
            totalAtivesColumns: ['v_ativos.total'],
            totalCotesColumns: ['v_ativos.total_con_money'],
            totalFinancingColumns: ['v_ativos.total_ret_money'],
            prePaymentTotalColumns: ['v_prepag.total'],
            prePayGteColumns: ['v_prepag.gte_10k'],
            highRiskCollumns: ['highrisk'],
            statusCollumns: ['status'],
            monthlyIncome11xCollumns: ['11x_monthly_income'],
            prePagRetail10kCollumns: ['n_pre_pag.retail_10k'],
            prePagConsorcio10kCollumns: ['n_pre_pag.consorcio_10k'],
            valorPagoCollumns: ['valor_pago'], // Vale para retail e consorcio
            dataAdesaoCollumns: ['data_adesao'],
            dataEntregaBemCollumns: ['data_entrega_bem'],
            dataPagamentoCollumns: ['data_pagamento'],
            saldoDevedorCollumns: ['saldo_devedor'],
            dataContemplacaoCollumns: ['data_contemplacao'],
            tableColumnExtensions,
            percentPrePagRendaCollumns: ['n_pre_pag.total/renda'],
            PrePagRetailCollumns: ['v_prepag.retail'],
            PrePagConsCollumns: ['v_prepag.consorcio'],
            valueCreditConsCollumns: ['valor_credito'],
            dataTransfConsCollumns: ['data_transferencia'],
            dataContratoRetailCollumns: ['data_contrato'],
            valorParcelaCollumns: ['valor_parcela'],
            dataVencimentoContratoRetailCollumns: ['data_venc_contrato'],
            entradaRetailCollumns: ['entrada'],
            valorFinanciamentoRetailCollumns: ['valor_financiamento'],
            valorPago10kRetailCollumns: ['gte_10k'],
            GTEConsorcio10kCollumns: ['gte_10k_consorcio'],
            perPagCredCollumns: ['perc_pag_cred'],

            dataInclusaoCollumns: ['createdAt'],
            parecerAnalistaCollumns: ['parecer_analista'],
            parecerGerenteCollumns: ['parecer_gerente'],
            parecerSuperGerenteCollumns: ['parecer_supergerente'],
            pendingCollumns: ['pending'],

            rows: [],
            sorting,
            totalCount: 0,
            pageSize,
            pageSizes,
            currentPage,
            loading: true,
            filter: [],
            where: null,
        }

        // this.changeEditingRowIds = editingRowIds => this.setState({ editingRowIds });

        this.api = new api()
    }

    componentDidMount() {
        this.loadData()
    }

    componentWillReceiveProps({ where }, nextContext) {
        // console.log('componentWillReceiveProps', where)
        this.setState({ where })
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const cond =
            this.state.where !== nextState.where ||
            this.state.rows !== nextState.rows ||
            this.state.currentPage !== nextState.currentPage ||
            this.state.pageSize !== nextState.pageSize ||
            this.state.loading !== nextState.loading
        // console.warn(
        //     'shouldComponentUpdate:state',
        //     cond,
        //     this.state.where,
        //     nextState.where,
        // )
        // console.warn(
        //     'shouldComponentUpdate:props',
        //     cond,
        //     this.props.where,
        //     nextProps.where,
        // )
        return cond
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log('didupdate', prevState, this.props)
        if (prevState.where !== this.state.where) {
            // if (!_.isEqual(prevState.where, this.state.where)) {
            // console.log('reload table')
            console.warn('update filters', prevState.where, this.props.where)
            this.loadData()
        }
    }

    changeSorting = async (sorting) => {
        await this.setState({
            loading: true,
            sorting,
        })
        this.loadData()
    }

    onFiltersChange = async (filter) => {
        //console.log(filter);
        //console.log('filter', filter)
        await this.setState(
            {
                loading: true,
                filter: filter,
            },
            () => this.loadData(),
        )
    }

    changeCurrentPage = async (currentPage) => {
        await this.setState({
            loading: true,
            currentPage,
        })
        this.loadData()
    }

    changePageSize = async (pageSize) => {
        const { totalCount, currentPage: stateCurrentPage } = this.state
        const totalPages = Math.ceil(totalCount / pageSize)
        const currentPage = Math.min(stateCurrentPage, totalPages - 1)

        await this.setState({
            loading: true,
            pageSize,
            currentPage,
        })
        this.loadData()
    }

    queryString = () => {
        const { sorting, pageSize, currentPage, where } = this.state
        this.setState({ where: this.props.where })
        // console.log('----filters', where)
        const query = {
            skip: pageSize * currentPage,
            limit: pageSize,
            where: {},
            include: this.props.include ? this.props.include : null,
        }
        if (where) {
            where.map((item) => {
                // console.log('query item', item)
                if (item.type === 'or') {
                    query.where['or'] = item.value
                    return query
                }

                if (item.like) {
                    query.where[item.id] = { like: item.value, options: 'i' }
                } else {
                    query.where[item.id] = item.value
                }

                if (item.type === 'number') {
                    query.where[item.id] = parseInt(
                        item.value.replace(/\D/g, ''),
                    )
                }
            })
        }

        const columnSorting = sorting ? sorting[0] : null
        if (columnSorting) {
            query.order = [
                columnSorting.columnName,
                columnSorting.direction,
            ].join(' ')
        }
        console.info('query', query)
        return query
    }

    loadData = () => {
        const queryString = this.queryString()
        if (queryString === this.lastQuery) {
            this.setState({ loading: false })
            return null
        }
        this.api.paginate(queryString).subscribe(
            (data) => {
                this.setState({
                    rows: data.items,
                    totalCount: data.counters.itemsTotal,
                    loading: false,
                })
                if (this.props.onTotal) {
                    this.props.onTotal(data.counters.itemsTotal)
                }
            },
            (error) => {
                this.setState({ loading: false })
                // g('error', error)
                toast.error(error.message)
            },
        )
        this.lastQuery = queryString
    }

    changeSelection = (selection) => {
        const rowsSelected = []
        selection.forEach((item) => {
            const row = this.state.rows[item]
            rowsSelected.push(row)
        })
        this.props.selected(rowsSelected.filter((item) => item !== undefined))
    }

    render() {
        const {
            rows,
            columns,
            cpfCnpjColumns,
            nameColumns,
            monthlyIncomeColumns,
            totalAtivesColumns,
            totalCotesColumns,
            totalFinancingColumns,
            prePaymentTotalColumns,
            prePayGteColumns,
            highRiskCollumns,
            statusCollumns,
            prePagConsorcio10kCollumns,
            monthlyIncome11xCollumns,
            prePagRetail10kCollumns,
            valorPagoCollumns,
            dataAdesaoCollumns,
            dataEntregaBemCollumns,
            dataPagamentoCollumns,
            saldoDevedorCollumns,
            dataContemplacaoCollumns,
            approveColumns,
            actionButtonColumns,
            tableColumnExtensions,
            sorting,
            pageSize,
            pageSizes,
            currentPage,
            totalCount,
            loading,
            PrePagRetailCollumns,
            percentPrePagRendaCollumns,
            PrePagConsCollumns,
            valueCreditConsCollumns,
            dataTransfConsCollumns,
            dataContratoRetailCollumns,
            valorParcelaCollumns,
            dataVencimentoContratoRetailCollumns,
            entradaRetailCollumns,
            valorFinanciamentoRetailCollumns,
            valorPago10kRetailCollumns,
            GTEConsorcio10kCollumns,
            perPagCredCollumns,
            dataInclusaoCollumns,
            parecerAnalistaCollumns,
            parecerGerenteCollumns,
            parecerSuperGerenteCollumns,
            pendingCollumns,
        } = this.state

        return (
            <Paper style={ { position: 'relative' } }>
                { loading && <LinearProgress/> }

                <Grid rows={ rows } columns={ columns }>
                    <SelectionState onSelectionChange={ this.changeSelection }/>

                    <CpfCnpjTypeProvider for={ cpfCnpjColumns }/>

                    <NameTypeProvider for={ nameColumns }/>

                    <MonthlyIncomeTypeProvider for={ monthlyIncomeColumns }/>

                    <TotalAtivesTypeProvider for={ totalAtivesColumns }/>

                    <TotalCotesTypeProvider for={ totalCotesColumns }/>

                    <TotalFinancingTypeProvider for={ totalFinancingColumns }/>

                    <PrePaymentTotalTypeProvider for={ prePaymentTotalColumns }/>

                    <PrePayGteTypeProvider for={ prePayGteColumns }/>

                    <HighRiskProvider for={ highRiskCollumns }/>

                    <StatusClientProvider for={ statusCollumns }/>

                    <ApproveTypeProvider for={ approveColumns }/>

                    <ActionButtonTypeProvider for={ actionButtonColumns }/>

                    <PrePagConsorcio10kProvider
                        for={ prePagConsorcio10kCollumns }
                    />

                    <PrePagRetail10kProvider for={ prePagRetail10kCollumns }/>

                    <MonthlyIncome11xProvider for={ monthlyIncome11xCollumns }/>

                    <ValorPagoProvider for={ valorPagoCollumns }/>

                    <DataAdesaoProvider for={ dataAdesaoCollumns }/>

                    <DataEntregaBemProvider for={ dataEntregaBemCollumns }/>

                    <DataPagamentoProvider for={ dataPagamentoCollumns }/>

                    <SaldoDevedorProvider for={ saldoDevedorCollumns }/>

                    <DataContemplacaoProvider for={ dataContemplacaoCollumns }/>

                    <PercentPrePagRendaProvider
                        for={ percentPrePagRendaCollumns }
                    />

                    <PrePagRetailTypeProvider for={ PrePagRetailCollumns }/>

                    <PrePagConsTypeProvider for={ PrePagConsCollumns }/>

                    <ValueCreditProvider for={ valueCreditConsCollumns }/>

                    <DataTransferenciaProvider for={ dataTransfConsCollumns }/>

                    <DataContratoProvider for={ dataContratoRetailCollumns }/>

                    <ValorParcelaProvider for={ valorParcelaCollumns }/>

                    <DataVencimentoContratoProvider
                        for={ dataVencimentoContratoRetailCollumns }
                    />

                    <EntradaProvider for={ entradaRetailCollumns }/>

                    <ValorFinanciamentoProvider
                        for={ valorFinanciamentoRetailCollumns }
                    />

                    <ValorPago10kProvider for={ valorPago10kRetailCollumns }/>

                    <GTEConsorcio10kProvider for={ GTEConsorcio10kCollumns }/>

                    <PerPagCredTypeProvider for={ perPagCredCollumns }/>

                    <DataInclusaoProvider for={ dataInclusaoCollumns }/>

                    <ParecerAnalistaProvider for={ parecerAnalistaCollumns }/>

                    <ParecerGerenteProvider for={ parecerGerenteCollumns }/>

                    <ParecerSupergerenteProvider
                        for={ parecerSuperGerenteCollumns }
                    />

                    <PendingProvider for={ pendingCollumns }/>

                    <SortingState
                        sorting={ sorting }
                        onSortingChange={ this.changeSorting }
                    />

                    <FilteringState
                        defaultFilters={ [] }
                        onFiltersChange={ this.onFiltersChange }
                    />
                    <IntegratedFiltering/>

                    <PagingState
                        currentPage={ currentPage }
                        onCurrentPageChange={ this.changeCurrentPage }
                        pageSize={ pageSize }
                        onPageSizeChange={ this.changePageSize }
                    />

                    <CustomPaging totalCount={ totalCount }/>

                    <Table
                        rowComponent={ TableRow }
                        columnExtensions={ tableColumnExtensions }
                        // cellComponent={ TableHead }
                        width="auto"
                    />

                    <TableHeaderRow showSortingControls/>

                    { this.props.selected !== undefined ? (
                        <TableSelection/>
                    ) : null }

                    { this.props.columnBands !== undefined ? (
                        <TableBandHeader columnBands={ this.props.columnBands }/>
                    ) : null }

                    {/* <TableFilterRow /> */ }

                    <PagingPanel pageSizes={ pageSizes }/>

                    <TableFixedColumns leftColumns={ ['name', 'cpf_f'] }/>
                </Grid>
            </Paper>
        )
    }
}

DataTable.defaultProps = {
    api: ClientApi,
    pageSizes: [5, 10, 15, 50, 100],
    pageSize: 10,
    currentPage: 0,
}
