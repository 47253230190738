import { User } from '../index'

export class Decision {
    cpf_cnpj
    parecer_analista
    alineas
    consorcios
    financiamentos
    pre_pags_con
    pre_pags_fin
    parecer_gerente
    parecer_supergerente
    status
    step
    pending
    analistaDate
    gerenteDate
    supergerenteDate
    createdAt
    id
    analistaId
    gerenteId
    supergerenteId
    analista
    gerente
    supergerente

    constructor(data) {
        Object.assign(this, data)
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `Decision`.
     */
    static getModelName() {
        return 'Decision'
    }

    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of Decision for dynamic purposes.
     **/
    static factory(data) {
        return new Decision(data)
    }

    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    static getModelDefinition() {
        return {
            name: 'Decision',
            plural: 'Decisions',
            path: 'Decisions',
            properties: {
                cpf_cnpj: {
                    name: 'cpf_cnpj',
                    type: 'number',
                },
                parecer_analista: {
                    name: 'parecer_analista',
                    type: 'string',
                },
                alineas: {
                    name: 'alineas',
                    type: 'Array&lt;any&gt;',
                },
                consorcios: {
                    name: 'consorcios',
                    type: 'Array&lt;any&gt;',
                },
                financiamentos: {
                    name: 'financiamentos',
                    type: 'Array&lt;any&gt;',
                },
                pre_pags_con: {
                    name: 'pre_pags_con',
                    type: 'any',
                },
                pre_pags_fin: {
                    name: 'pre_pags_fin',
                    type: 'any',
                },
                parecer_gerente: {
                    name: 'parecer_gerente',
                    type: 'string',
                },
                parecer_supergerente: {
                    name: 'parecer_supergerente',
                    type: 'string',
                },
                status: {
                    name: 'status',
                    type: 'string',
                },
                step: {
                    name: 'step',
                    type: 'string',
                },
                pending: {
                    name: 'pending',
                    type: 'boolean',
                    default: true,
                },
                analistaDate: {
                    name: 'analistaDate',
                    type: 'Date',
                },
                gerenteDate: {
                    name: 'gerenteDate',
                    type: 'Date',
                },
                supergerenteDate: {
                    name: 'supergerenteDate',
                    type: 'Date',
                },
                createdAt: {
                    name: 'createdAt',
                    type: 'Date',
                },
                id: {
                    name: 'id',
                    type: 'any',
                },
                analistaId: {
                    name: 'analistaId',
                    type: 'any',
                },
                gerenteId: {
                    name: 'gerenteId',
                    type: 'any',
                },
                supergerenteId: {
                    name: 'supergerenteId',
                    type: 'any',
                },
            },
            relations: {
                analista: {
                    name: 'analista',
                    type: 'User',
                    model: 'User',
                },
                gerente: {
                    name: 'gerente',
                    type: 'User',
                    model: 'User',
                },
                supergerente: {
                    name: 'supergerente',
                    type: 'User',
                    model: 'User',
                },
            },
        }
    }
}
