// Adiciona botões de comandos á tabela.
//thumb_up_alt

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'

let router = null

const NameWrapper = styled.div`
  cursor: pointer;

  &:hover span {
    color: #DDA416;
    font-weight: bold;
  }
`

const openDetails = (client) => {
    router.history.push({
        pathname: '/client/' + client.cpf_f,
        /* state: {
          client: client
        } */
    })
}

const NameFormatter = (props) => {
    return (
        <NameWrapper onClick={ () => openDetails(props.row) }>
            <Tooltip title={ props.row.name }>
                <span>{ props.row.name }</span>
            </Tooltip>
        </NameWrapper>
    )
}

const NameTypeProvider = props => {
    router = props
    return (
        <DataTypeProvider
            formatterComponent={ NameFormatter }
            { ...props }
        />
    )
}


export default withRouter(NameTypeProvider)
