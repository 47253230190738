import * as clientsActions from './Clients.actions'
import ApiService from 'services/ApiService'

import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { ClientApi } from 'sdk'


/* ************************************ GET ALL CLIENTS ************************************ */

export const clientsEpics = (action$, store) => {
    return action$
        .pipe(
            ofType(clientsActions.REQUEST_CLIENTS),
            mergeMap(async (action) => {

                // const { payload } = action;
                const token = store.value.authsReducer.get('token')
                console.log('token', token)

                new ClientApi().find({ where: { cpf_cnpj: '894595717' } })
                    .subscribe(data => {
                        console.log('data', data)
                    }, error => {
                        console.log('error', error)
                    })

                return null

                // console.log(clients);
            }),
        )
}


/* ************************************ SELECT CLIENT ************************************ */

export const clientSelectEpics = (action$, store) => {
    return action$
        .pipe(
            ofType(clientsActions.REQUEST_SELECT_CLIENT),
            mergeMap(async (action) => {

                // const { payload } = action;

                const client = await ApiService.getClient(action.payload.id)

                if (client !== null) {
                    return clientsActions.successSelectClient(client)
                }

                return clientsActions.failSelectClient()
            }),
        )
}
