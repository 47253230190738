import React from 'react'
import { FiberManualRecord } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'

export const Status = ({ value }) => {
    let status = 'pending'
    try {
        status = value
        if (status === undefined || status === null || status === '') {
            status = 'pending'
        }
    } catch (err) {
        status = 'pending'
    }

    let color = ''
    let tooltip = ''

    if (status === 'pending') {
        color = '#ced5da'
        tooltip = 'Pendente'
    } else if (status === 'ok') {
        color = '#a8f7a3'
        tooltip = 'Prosseguir'
    } else if (status === 'gray') {
        color = 'grey'
        tooltip = 'Gray List'
    } else if (status === 'black') {
        color = 'black'
        tooltip = 'Black List'
    } else if (status === 'blackd') {
        color = 'yellow'
        tooltip = 'Gerência'
    } else if (status === 'blackg') {
        color = 'orange'
        tooltip = 'Superintendência'
    }

    return (
        <React.Fragment>
            <Tooltip title={ tooltip }>
                <FiberManualRecord style={ { color: color } }/>
            </Tooltip>
            {/* <span>{props.row.status}</span> */ }
        </React.Fragment>
    )
}
