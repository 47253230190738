export const schema = [
    {
        name: 'cpf_cnpj',
        label: 'CPF ou CNPJ',
        type: String,
        mask: 'cpf_cnpj',
    },
    {
        name: 'name',
        label: 'Nome',
        type: String,
    },
    {
        name: 'type',
        label: 'Tipo',
        type: String,
    },
    {
        name: 'nationality',
        label: 'Nacionalidade',
        type: String,
    },
    {
        name: 'profession',
        label: 'Profissão',
        type: String,
    },
    {
        name: 'uf',
        label: 'Estado',
        type: Array,
    },
    {
        name: 'city',
        label: 'Cidade',
        type: Array,
    },
    {
        name: 'sex',
        label: 'Sexo',
        type: String,
    },
    {
        name: 'matrial_status',
        label: 'Estado Civil',
        type: String,
    },
]
