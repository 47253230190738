//v_prepag.gte_10k

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { ThumbDownAlt, ThumbUpAlt } from '@material-ui/icons'

const PrePayGteFormatter = props => {
    let value = false
    try {
        value = props.row.v_prepag.gte_10k
    } catch (err) {
        value = false
    }
    return (
        <React.Fragment>
            { value ? (
                <ThumbUpAlt color="secondary"/>
            ) : (
                <ThumbDownAlt color="error"/>
            ) }
        </React.Fragment>
    )
}

export const PrePayGteTypeProvider = props => (
    <DataTypeProvider formatterComponent={ PrePayGteFormatter } { ...props } />
)
