//row.v_prepag.consorcio

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { FiberManualRecord } from '@material-ui/icons'

const PrePagConsorcio10kFormatter = (props) => {
    let value = false
    try {
        const entrada =
            props.row.v_prepag.consorcio === undefined ||
            props.row.v_prepag.consorcio === null
                ? 0
                : props.row.v_prepag.consorcio
        const convertMoeda = (val) => {
            val = val.replace('.', '')
            val = val.replace(',', '.')
            return parseFloat(val)
        }

        value =
            typeof entrada === 'string'
                ? convertMoeda(entrada) >= 10000
                ? true
                : false
                : entrada >= 10000
                ? true
                : false
    } catch (err) {
        return false
    }
    return (
        <React.Fragment>
            { value ? (
                <FiberManualRecord style={ { color: 'red' } }/>
            ) : (
                <FiberManualRecord style={ { color: '#ced5da' } }/>
            ) }
        </React.Fragment>
    )
}

export const PrePagConsorcio10kProvider = (props) => (
    <DataTypeProvider
        formatterComponent={ PrePagConsorcio10kFormatter }
        { ...props }
    />
)
