import React from 'react'
import { Date, Money, PrePagConGte10k } from 'components/InovaTable'

export const Trans_pre_pag_rtApi_columns = [
    {
        field: 'concessionario',
        name: 'Concessionária',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <span>{value}</span>
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'contrato',
        name: 'Contrato',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <span>{value}</span>
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'dpd',
        name: 'DPD',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <span>{value}</span>
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'nome',
        name: 'Nome',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <span>{value}</span>
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'data_type',
        name: 'Tipo',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <span>{value}</span>
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'modelo',
        name: 'Modelo',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <span>{value}</span>
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'data_contrato',
        name: 'Data Contrato',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Date value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'data_venc_contrato',
        name: 'Venc Contrato',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Date value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'prazo',
        name: 'Prazo',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <span>{value}</span>
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'data_adesao',
        name: 'Data Adesão',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Date value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'entrada',
        name: 'Entrada',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Money value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'valor_financiamento',
        name: 'Financiado',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Money value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'n_parcela_paga',
        name: 'N Parcela',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <span>{value}</span>
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'valor_parcela',
        name: 'Parcela',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Money value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'valor_pago',
        name: 'Pago',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Money value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'gte_10k',
        name: '>=10k',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <PrePagConGte10k value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'data_pagamento',
        name: 'Data Pagamento',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Date value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'delta_dias',
        name: 'Dias da Adesão',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <span>{value}</span>
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'delta_mes',
        name: 'Meses da Adesão',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <span>{value}</span>
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'perc_pag_cred',
        name: '% Parc Cred',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return (
                        <span>{(parseFloat(value) * 100).toFixed(2) + ''}</span>
                    )
                } catch (error) {
                    return 0
                }
            },
        },
    },
]

export const Trans_pre_pag_rtApi_sort = [
    { columnName: 'name', direction: 'asc' },
]

export const Trans_pre_pag_rtApi_Extensions = [
    { columnName: 'nome', width: 240, align: 'left', wordWrapEnabled: true },
    { columnName: 'cpf_cnpj', width: 160, align: 'right' },
    { columnName: 'concessionario', width: 180, align: 'center' },
    { columnName: 'modelo', align: 'left', width: 250, wordWrapEnabled: true },
    { columnName: 'data_contrato', align: 'center', width: 160 },
    { columnName: 'data_pagamento', align: 'center', width: 160 },
    { columnName: 'data_venc_contrato', align: 'center', width: 160 },
    { columnName: 'n_parcela_paga', align: 'center', width: 160 },
    { columnName: 'perc_pag_cred', width: 140, align: 'center' },
    { columnName: 'delta_dias', width: 140, align: 'center' },
    { columnName: 'delta_mes', width: 140, align: 'center' },
]
