import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FusePageCarded } from '@fuse'
import { connect } from 'react-redux'
import HeaderComponent from './HeaderComponent'
import ImportComponent from './ImportsComponent'

const styles = theme => ({
    layoutRoot: {},
})


class Imports extends Component {
    state = {}

    componentWillMount() {
        const token = this.props.authsReducer.get('token')
        if (token === null) {
            this.props.history.push({
                pathname: '/',
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <FusePageCarded
                    header={ <HeaderComponent/> }
                    content={
                        <ImportComponent/>
                    }
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    authsReducer: state.authsReducer,
})

export default connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(Imports))
