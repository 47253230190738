export class Trans_pre_pag_rt {
    'concessionario'
    'contrato'
    'cpf_cnpj'
    'n_parcela_paga'
    'dpd'
    'valor_pago'
    'data_pagamento'
    'valor_parcela'
    'data_contrato'
    'data_ven_contrato'
    'prazo'
    'entrada'
    'valor_financiamento'
    'modelo'
    'status'
    'product'
    'nome'
    'data_type'
    'delta_dias'
    'delta_mes'
    'data_adesao'
    'perc_pag_cred'
    'gte_10k'
    'id'

    constructor(data) {
        Object.assign(this, data)
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `Trans_pre_pag_rt`.
     */
    static getModelName() {
        return 'Trans_pre_pag_rt'
    }

    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of Trans_pre_pag_rt for dynamic purposes.
     **/
    static factory(data) {
        return new Trans_pre_pag_rt(data)
    }

    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    static getModelDefinition() {
        return {
            name: 'Trans_pre_pag_rt',
            plural: 'Trans_pre_pag_rts',
            path: 'Trans_pre_pag_rts',
            properties: {
                'concessionario': {
                    name: 'concessionario',
                    type: 'string',
                },
                'contrato': {
                    name: 'contrato',
                    type: 'number',
                },
                'cpf_cnpj': {
                    name: 'cpf_cnpj',
                    type: 'number',
                },
                'n_parcela_paga': {
                    name: 'n_parcela_paga',
                    type: 'number',
                },
                'dpd': {
                    name: 'dpd',
                    type: 'number',
                },
                'valor_pago': {
                    name: 'valor_pago',
                    type: 'number',
                },
                'data_pagamento': {
                    name: 'data_pagamento',
                    type: 'any',
                },
                'valor_parcela': {
                    name: 'valor_parcela',
                    type: 'number',
                },
                'data_contrato': {
                    name: 'data_contrato',
                    type: 'Date',
                },
                'data_ven_contrato': {
                    name: 'data_ven_contrato',
                    type: 'Date',
                },
                'prazo': {
                    name: 'prazo',
                    type: 'number',
                },
                'entrada': {
                    name: 'entrada',
                    type: 'number',
                },
                'valor_financiamento': {
                    name: 'valor_financiamento',
                    type: 'number',
                },
                'modelo': {
                    name: 'modelo',
                    type: 'string',
                },
                'status': {
                    name: 'status',
                    type: 'string',
                },
                'product': {
                    name: 'product',
                    type: 'string',
                },
                'nome': {
                    name: 'nome',
                    type: 'string',
                },
                'data_type': {
                    name: 'data_type',
                    type: 'string',
                },
                'delta_dias': {
                    name: 'delta_dias',
                    type: 'number',
                },
                'delta_mes': {
                    name: 'delta_mes',
                    type: 'number',
                },
                'data_adesao': {
                    name: 'data_adesao',
                    type: 'Date',
                },
                'perc_pag_cred': {
                    name: 'perc_pag_cred',
                    type: 'number',
                },
                'gte_10k': {
                    name: 'gte_10k',
                    type: 'boolean',
                },
                'id': {
                    name: 'id',
                    type: 'any',
                },
            },
            relations: {},
        }
    }
}
