import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControl,
    FormControlLabel,
    Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'
import { alineas } from 'pages/clientDetails/alineas'

import Checkbox from '@material-ui/core/Checkbox'

const styles = (theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '100%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
})

class AlineasAccordion extends React.Component {
    state = {
        expanded: null,
    }

    handleChange = (panel) => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        })
    }

    render() {
        const { classes } = this.props
        const { expanded } = this.state

        return (
            <div className={classes.root}>
                {alineas.map((alinea) => {
                    return (
                        <ExpansionPanel
                            expanded={expanded === alinea.id}
                            onChange={this.handleChange(alinea.id)}
                            key={alinea.id}
                        >
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography className={classes.heading}>
                                    {alinea.title}
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <FormControl
                                    component="fieldset"
                                    className={classNames(
                                        classes.formControl,
                                        'flex items-center justify-center',
                                    )}
                                >
                                    {alinea.itens.map((item) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={() =>
                                                        this.props.changeCheckbox(
                                                            item,
                                                        )
                                                    }
                                                    value={item.description}
                                                    color="primary"
                                                />
                                            }
                                            label={item.description}
                                            key={item.description}
                                        />
                                    ))}
                                </FormControl>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )
                })}
            </div>
        )
    }
}

AlineasAccordion.propTypes = {
    classes: PropTypes.object.isRequired,
    // disabled: PropTypes.boolean.optional
}

export default withStyles(styles)(AlineasAccordion)
