export const Trans_pre_pag_conApi_columns = [
    // CLIENTE
    { name: 'descricao_bem', title: 'DESCRIÇÃO DO BEM' },
    { name: 'grupo', title: 'GRUPO' },
    { name: 'cota', title: 'COTA' },
    { name: 'versao', title: 'VERSÃO' },
    { name: 'data_adesao', title: 'DATA ADESÃO' },
    { name: 'data_entrega_bem', title: 'DATA ENTREGA BEM' },
    { name: 'valor_pago', title: 'VALOR PAGO' },
    { name: 'gte_10k_consorcio', title: 'PRÉ PAG. > 10K' },
    {
        name: 'perc_parcela_paga',
        title: '% PARCELA PAGA',
        getCellValue: (row) => {
            const value = row.perc_parcela_paga + ''
            return value.replace('.', ',')
        },
    },
    {
        name: 'perc_amortizado_cota',
        title: '% AMORTIZADO COTA',
        getCellValue: (row) => {
            const value = row.perc_amortizado_cota + ''
            return value.replace('.', ',')
        },
    },
    { name: 'saldo_devedor', title: 'SALDO DEVEDOR' },
    { name: 'data_pagamento', title: 'DATA DO PAGAMENTO' },
    { name: 'cod_mov', title: 'COD. MOV.' },
    { name: 'situacao_cota', title: 'SITUAÇÃO COTA' },
    { name: 'data_contemplacao', title: 'DATA CONTEMPLAÇÃO' },
    { name: 'tipo_conteplacao', title: 'TIPO CONTEMPLAÇÃO' },
    { name: 'favoericdo', title: 'FAVORECIDO' },
    { name: 'delta_dias', title: 'DIAS DA ADESÃO' },
    { name: 'delta_mes', title: 'MESES DA ADESÃO' },
    { name: 'valor_creditado', title: 'VALOR CREDITADO' },
    { name: 'data_transferencia', title: 'DATA TRANSFERÊNCIA' },
    { name: 'product', title: 'TIPO' },
    {
        name: 'pag_rel_renda',
        title: '% PAG. REL. RENDA',
        getCellValue: (row) => {
            try {
                const value =
                    (parseFloat(row.pag_rel_renda) * 100).toFixed(2) + ''
                return value.replace('.', ',')
            } catch (err) {
                return 0
            }
        },
    },
    {
        name: 'perc_pag_cred',
        title: '% PAG CRED.',
        getCellValue: (row) => {
            try {
                return (parseFloat(row.perc_pag_cred) * 100).toFixed(2)
            } catch (err) {
                return 0
            }
        },
    },
]

export const Trans_pre_pag_conApi_sort = [
    { columnName: 'period', direction: 'asc' },
]

export const Trans_pre_pag_conApi_Extensions = [
    { columnName: 'highrisk', align: 'center' },
    { columnName: 'status', align: 'center' },
    { columnName: 'data_entrega_bem', width: 180 },
    { columnName: 'data_adesao', width: 180 },
    { columnName: 'perc_parcela_paga', width: 180, align: 'center' },
    { columnName: 'perc_amortizado_cota', width: 180, align: 'center' },
    { columnName: 'saldo_devedor', width: 180 },
    { columnName: 'data_pagamento', width: 180, align: 'center' },
    { columnName: 'delta_dias', width: 180, align: 'center' },
    { columnName: 'delta_mes', width: 180, align: 'center' },
    { columnName: 'cod_mov', width: 180, align: 'center' },
    { columnName: 'situacao_cota', width: 180 },
    { columnName: 'data_contemplacao', width: 180, align: 'center' },
    { columnName: 'tipo_conteplacao', width: 180, align: 'center' },
    { columnName: 'descricao_bem', width: 200, wordWrapEnabled: true },
    { columnName: 'valor_creditado', width: 180 },
    { columnName: 'gte_10k_consorcio', width: 200, align: 'center' },
    { columnName: 'data_transferencia', width: 180 },
    { columnName: 'pag_rel_renda', width: 200 },
    { columnName: 'perc_pag_cred', width: 200 },
]

export const HistoricoData = [
    // CLIENTE

    { name: 'createdAt', title: 'Data - Hora' },
    { name: 'status', title: 'Status', align: 'center' },
    {
        name: 'step',
        title: 'Etapa',
        getCellValue: (row) => {
            try {
                return row.step.charAt(0).toUpperCase() + row.step.slice(1)
            } catch (err) {
                return 0
            }
        },
    },
    { name: 'pending', title: 'Pendente' },
    {
        name: 'analista.firstName',
        title: 'Analista',
        getCellValue: (row) => {
            try {
                return row.analista.firstName
            } catch (err) {
                return ''
            }
        },
    },
    { name: 'parecer_analista', title: 'Parecer' },
    {
        name: 'gerente.firstName',
        title: 'Gerente',
        getCellValue: (row) => {
            try {
                return row.gerente.firstName
            } catch (err) {
                return ''
            }
        },
    },
    { name: 'parecer_gerente', title: 'Parecer' },
    {
        name: 'supergerente',
        title: 'SuperIntendente',
        getCellValue: (row) => {
            try {
                return row.supergerente.firstName
            } catch (err) {
                return ''
            }
        },
    },
    { name: 'parecer_supergerente', title: 'SuperIntendente' },
    // { name: 'cpf_cnpj', title: 'CPF / CNPJ' },
]

export const Historico_Extensions = [
    { columnName: 'createdAt', width: 160 },
    { columnName: 'status', width: 100, align: 'center' },
    { columnName: 'step', width: 100 },
    { columnName: 'pending', width: 100 },
]
