// perc_pag_cred

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const PerPagCredFormatter = (props) => {
    let value = 0
    try {
        const entrada =
            props.row.perc_pag_cred === undefined ||
            props.row.perc_pag_cred === null
                ? 0
                : props.row.perc_pag_cred

        value = entrada
    } catch (err) {
        value = 0
    }
    value = value.toFixed(2) + ''
    return (
        <div>
            <span>{ value.replace('.', ',') }</span>
        </div>
    )
}

export const PerPagCredTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={ PerPagCredFormatter } { ...props } />
)
