import React from 'react'

const AlineasViewer = ({ alineas }) => (
    <React.Fragment>
        { alineas.map((alinea) => (
            <ul key={ alinea.id }>
                <li
                    key={ alinea.id }
                    style={ { listStyle: 'none', marginLeft: '-40px' } }
                >
                    <strong>{ alinea.title }</strong>
                </li>
                { alinea.itens.map((item) => (
                    <li key={ item.id }>
                        <strong>{ item.id }</strong> - { item.description }
                    </li>
                )) }
            </ul>
        )) }
    </React.Fragment>
)

export default AlineasViewer
