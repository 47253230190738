//data_venc_contrato

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/pt-br'

const DataVencimentoContratoFormatter = (props) => {
    // console.log(props.row)
    const dateToFormat =
        props.row.data_venc_contrato === null ||
        props.row.data_venc_contrato === undefined
            ? ''
            : props.row.data_venc_contrato
    return dateToFormat === '' ? (
        <span>{ dateToFormat }</span>
    ) : (
        <div>
            <Moment locale="pt-br" format="DD[/]MM[/]YYYY">
                { dateToFormat }
            </Moment>
        </div>
    )
}

export const DataVencimentoContratoProvider = (props) => (
    <DataTypeProvider
        formatterComponent={ DataVencimentoContratoFormatter }
        { ...props }
    />
)
