// v_prepag.retail

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import Services from '../../../../services/Services'

const PrePagRetailFormatter = (props) => {
    let value = 0
    try {
        const entrada =
            props.row.v_prepag.retail === undefined ||
            props.row.v_prepag.retail === null
                ? 0
                : props.row.v_prepag.retail
        const convertMoeda = (val) => {
            val = val.replace('.', '')
            val = val.replace(',', '.')
            return parseFloat(val)
        }

        value = typeof entrada === 'string' ? convertMoeda(entrada) : entrada
    } catch (err) {
        return false
    }
    value = Services.maskMoney(value)
    return (
        <div>
            <span>{ value }</span>
        </div>
    )
}

export const PrePagRetailTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={ PrePagRetailFormatter } { ...props } />
)
