export class Trans_pre_pag_con {
    'perc_amortizado_cota'
    'situacao_cota'
    'cpf_cnpj'
    'data_nascimento'
    'renda'
    'data_pagamento'
    'estado_civil'
    'perc_parcela_paga'
    'data_transferencia'
    'valor_creditado'
    'nome'
    'cod_mov'
    'cidade'
    'versao'
    'bairro'
    'favoericdo'
    'grupo'
    'cota'
    'endereco'
    'nome_profissao'
    'cep'
    'descricao_bem'
    'saldo_devedor'
    'data_adesao'
    'data_entrega_bem'
    'codigo_profissao'
    'valor_pago'
    'data_contemplacao'
    'tipo_conteplacao'
    'complemento'
    'product'
    'data_type'
    'pag_rel_renda'
    'delta_dias'
    'delta_mes'
    'perc_pag_cred'
    'gte_10k'
    'id'

    constructor(data) {
        Object.assign(this, data)
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `Trans_pre_pag_con`.
     */
    static getModelName() {
        return 'Trans_pre_pag_con'
    }

    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of Trans_pre_pag_con for dynamic purposes.
     **/
    static factory(data) {
        return new Trans_pre_pag_con(data)
    }

    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    static getModelDefinition() {
        return {
            name: 'Trans_pre_pag_con',
            plural: 'Trans_pre_pag_cons',
            path: 'Trans_pre_pag_cons',
            properties: {
                'perc_amortizado_cota': {
                    name: 'perc_amortizado_cota',
                    type: 'number',
                },
                'situacao_cota': {
                    name: 'situacao_cota',
                    type: 'string',
                },
                'cpf_cnpj': {
                    name: 'cpf_cnpj',
                    type: 'number',
                },
                'data_nascimento': {
                    name: 'data_nascimento',
                    type: 'Date',
                },
                'renda': {
                    name: 'renda',
                    type: 'number',
                },
                'data_pagamento': {
                    name: 'data_pagamento',
                    type: 'Date',
                },
                'estado_civil': {
                    name: 'estado_civil',
                    type: 'string',
                },
                'perc_parcela_paga': {
                    name: 'perc_parcela_paga',
                    type: 'number',
                },
                'data_transferencia': {
                    name: 'data_transferencia',
                    type: 'Date',
                },
                'valor_creditado': {
                    name: 'valor_creditado',
                    type: 'number',
                },
                'nome': {
                    name: 'nome',
                    type: 'string',
                },
                'cod_mov': {
                    name: 'cod_mov',
                    type: 'number',
                },
                'cidade': {
                    name: 'cidade',
                    type: 'string',
                },
                'versao': {
                    name: 'versao',
                    type: 'number',
                },
                'bairro': {
                    name: 'bairro',
                    type: 'string',
                },
                'favoericdo': {
                    name: 'favoericdo',
                    type: 'string',
                },
                'grupo': {
                    name: 'grupo',
                    type: 'number',
                },
                'cota': {
                    name: 'cota',
                    type: 'number',
                },
                'endereco': {
                    name: 'endereco',
                    type: 'string',
                },
                'nome_profissao': {
                    name: 'nome_profissao',
                    type: 'string',
                },
                'cep': {
                    name: 'cep',
                    type: 'number',
                },
                'descricao_bem': {
                    name: 'descricao_bem',
                    type: 'string',
                },
                'saldo_devedor': {
                    name: 'saldo_devedor',
                    type: 'number',
                },
                'data_adesao': {
                    name: 'data_adesao',
                    type: 'Date',
                },
                'data_entrega_bem': {
                    name: 'data_entrega_bem',
                    type: 'Date',
                },
                'codigo_profissao': {
                    name: 'codigo_profissao',
                    type: 'number',
                },
                'valor_pago': {
                    name: 'valor_pago',
                    type: 'number',
                },
                'data_contemplacao': {
                    name: 'data_contemplacao',
                    type: 'Date',
                },
                'tipo_conteplacao': {
                    name: 'tipo_conteplacao',
                    type: 'string',
                },
                'complemento': {
                    name: 'complemento',
                    type: 'string',
                },
                'product': {
                    name: 'product',
                    type: 'string',
                },
                'data_type': {
                    name: 'data_type',
                    type: 'string',
                },
                'pag_rel_renda': {
                    name: 'pag_rel_renda',
                    type: 'number',
                },
                'delta_dias': {
                    name: 'delta_dias',
                    type: 'number',
                },
                'delta_mes': {
                    name: 'delta_mes',
                    type: 'number',
                },
                'perc_pag_cred': {
                    name: 'perc_pag_cred',
                    type: 'number',
                },
                'gte_10k': {
                    name: 'gte_10k',
                    type: 'boolean',
                },
                'id': {
                    name: 'id',
                    type: 'any',
                },
            },
            relations: {},
        }
    }
}
