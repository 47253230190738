/* tslint:disable */
import { BaseLoopBackApi } from '../core/base.service'
import { LoopBackConfig } from '../../lb.config'
import { LoopBackFilter } from '../../models/BaseModels'
import { User } from '../../models/User'

/**
 * Api services for the `User` model.
 */

export class UserApi extends BaseLoopBackApi {
    constructor() {
        super()
    }

    /**
     * Localize um item relacionado por ID para accessTokens.
     *
     * @param {any} id User id
     *
     * @param {any} fk Chave estrangeira para accessTokens
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `User` object.)
     * </em>
     */
    findByIdAccessTokens(id, fk, customHeaders) {
        let _method = 'GET'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/Users/:id/accessTokens/:fk'
        let _routeParams = {
            id: id,
            fk: fk,
        }
        let _postBody = {}
        let _urlParams = {}

        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * Excluir um item relacionado por ID para accessTokens.
     *
     * @param {any} id User id
     *
     * @param {any} fk Chave estrangeira para accessTokens
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    destroyByIdAccessTokens(id, fk, customHeaders) {
        let _method = 'DELETE'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/Users/:id/accessTokens/:fk'
        let _routeParams = {
            id: id,
            fk: fk,
        }
        let _postBody = {}
        let _urlParams = {}

        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * Atualizar um item relacionado por ID para accessTokens.
     *
     * @param {any} id User id
     *
     * @param {any} fk Chave estrangeira para accessTokens
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `User` object.)
     * </em>
     */
    updateByIdAccessTokens(id, fk, data, customHeaders) {
        let _method = 'PUT'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/Users/:id/accessTokens/:fk'
        let _routeParams = {
            id: id,
            fk: fk,
        }
        let _postBody = {
            data: data,
        }
        let _urlParams = {}

        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * accessTokens consultas de User.
     *
     * @param {any} id User id
     *
     * @param {object} filter
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `User` object.)
     * </em>
     */
    getAccessTokens(id, filter, customHeaders) {
        let _method = 'GET'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/Users/:id/accessTokens'
        let _routeParams = {
            id: id,
        }
        let _postBody = {}
        let _urlParams = {}

        if (typeof filter !== 'undefined' && filter !== null)
            _urlParams.filter = filter
        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * Cria uma nova instância no accessTokens deste modelo.
     *
     * @param {any} id User id
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `User` object.)
     * </em>
     */
    createAccessTokens(id, data, customHeaders) {
        let _method = 'POST'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/Users/:id/accessTokens'
        let _routeParams = {
            id: id,
        }
        let _postBody = {
            data: data,
        }
        let _urlParams = {}

        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * Exclui todos os accessTokens deste modelo.
     *
     * @param {any} id User id
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    deleteAccessTokens(id, customHeaders) {
        let _method = 'DELETE'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/Users/:id/accessTokens'
        let _routeParams = {
            id: id,
        }
        let _postBody = {}
        let _urlParams = {}

        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * accessTokens contagens de User.
     *
     * @param {any} id User id
     *
     * @param {object} where Criteria to match model instances
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * Data properties:
     *
     *  - `count` – `{number}` -
     */
    countAccessTokens(id, where, customHeaders) {
        let _method = 'GET'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/Users/:id/accessTokens/count'
        let _routeParams = {
            id: id,
        }
        let _postBody = {}
        let _urlParams = {}

        if (typeof where !== 'undefined' && where !== null)
            _urlParams.where = where
        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     *
     * @param {object} data Request data.
     *
     *  - `data` – `{object}` - Model instance data
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `User` object.)
     * </em>
     */
    patchOrCreate(data, customHeaders) {
        let _method = 'PATCH'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/Users'
        let _routeParams = {}
        let _postBody = {
            data: data,
        }
        let _urlParams = {}

        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     *
     * @param {any} id User id
     *
     * @param {object} data Request data.
     *
     *  - `data` – `{object}` - An object of model property name/value pairs
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `User` object.)
     * </em>
     */
    patchAttributes(id, data, customHeaders) {
        let _method = 'PATCH'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/Users/:id'
        let _routeParams = {
            id: id,
        }
        let _postBody = {
            data: data,
        }
        let _urlParams = {}

        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * Login a user with username/email and password.
     *
     * @param {string} include Related objects to include in the response. See the description of return value for more details.
     *   Default value: `user`.
     *
     *  - `rememberMe` - `boolean` - Whether the authentication credentials
     *     should be remembered in localStorage across app/browser restarts.
     *     Default: `true`.
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * O corpo de resposta contém propriedades do AccessToken criado no login.
     * Dependendo do valor do parâmetro `include`, o corpo poderá conter propriedades adicionais:
     *
     *   - `user` - `U+007BUserU+007D` - Dados do usuário com login efetuado atualmente. (`include=user`)
     *
     *
     */
    login(credentials, include, rememberMe = true, customHeaders) {
        let _method = 'POST'
        let _url = `${ LoopBackConfig.getPath() }/${ LoopBackConfig.getApiVersion() }/Users/login?include=user`
        let _routeParams = {}
        let _postBody = {
            credentials,
        }
        let _urlParams = {
            include,
        }

        return this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
    }

    /**
     * Logout a user with access token.
     *
     * @param {object} data Request data.
     *
     * This method does not accept any data. Supply an empty object.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    logout(customHeaders) {
        let _method = 'POST'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/Users/logout'
        let _routeParams = {}
        let _postBody = {}
        let _urlParams = {}

        _urlParams.access_token = this.auth.getAccessTokenId()
        this.auth.clear()
        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * Trigger user's identity verification with configured verifyOptions
     *
     * @param {any} id User id
     *
     * @param {object} data Request data.
     *
     * This method does not accept any data. Supply an empty object.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    verify(id, customHeaders) {
        let _method = 'POST'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/Users/:id/verify'
        let _routeParams = {
            id: id,
        }
        let _postBody = {}
        let _urlParams = {}

        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * Confirm a user registration with identity verification token.
     *
     * @param {string} uid
     *
     * @param {string} token
     *
     * @param {string} redirect
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    confirm(uid, token, redirect, customHeaders) {
        let _method = 'GET'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/Users/confirm'
        let _routeParams = {}
        let _postBody = {}
        let _urlParams = {}

        if (typeof uid !== 'undefined' && uid !== null) _urlParams.uid = uid
        if (typeof token !== 'undefined' && token !== null)
            _urlParams.token = token
        if (typeof redirect !== 'undefined' && redirect !== null)
            _urlParams.redirect = redirect
        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * Reset password for a user with email.
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    resetPassword(options, customHeaders) {
        let _method = 'POST'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/Users/reset'
        let _routeParams = {}
        let _postBody = {
            options: options,
        }
        let _urlParams = {}

        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * Change a user's password.
     *
     * @param {object} data Request data.
     *
     *  - `oldPassword` – `{string}` -
     *
     *  - `newPassword` – `{string}` -
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    changePassword(oldPassword, newPassword, customHeaders) {
        let _method = 'POST'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/Users/change-password'
        let _routeParams = {}
        let _postBody = {
            data: {
                oldPassword: oldPassword,
                newPassword: newPassword,
            },
        }
        let _urlParams = {}

        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * Reset user's password via a password-reset token.
     *
     * @param {object} data Request data.
     *
     *  - `newPassword` – `{string}` -
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    setPassword(newPassword, customHeaders) {
        let _method = 'POST'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/Users/reset-password'
        let _routeParams = {}
        let _postBody = {
            data: {
                newPassword: newPassword,
            },
        }
        let _urlParams = {}

        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * Cria uma nova instância no accessTokens deste modelo.
     *
     * @param {any} id User id
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `User` object.)
     * </em>
     */
    createManyAccessTokens(id, data, customHeaders) {
        let _method = 'POST'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/Users/:id/accessTokens'
        let _routeParams = {
            id: id,
        }
        let _postBody = {
            data: data,
        }
        let _urlParams = {}

        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * @ngdoc method
     * @name sdk.User#getCurrent
     * @methodOf sdk.User
     *
     * @description
     *
     * Get data of the currently logged user. Fail with HTTP result 401
     * when there is no user logged in.
     *
     * @returns object An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     */
    getCurrent(filter = {}) {
        let _method = 'GET'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/Users' +
            '/:id'
        let id = this.auth.getCurrentUserId()
        if (id == null) id = '__anonymous__'
        let _routeParams = { id: id }
        let _urlParams = {}
        let _postBody = {}
        if (filter) _urlParams.filter = filter
        return this.request(_method, _url, _routeParams, _urlParams, _postBody)
    }

    /**
     * Get data of the currently logged user that was returned by the last
     * call to {@link sdk.User#login} or
     * {@link sdk.User#getCurrent}. Return null when there
     * is no user logged in or the data of the current user were not fetched
     * yet.
     *
     * @returns object An Account instance.
     */
    getCachedCurrent() {
        return this.auth.getCurrentUserData()
    }

    /**
     * Get data of the currently logged access tokern that was returned by the last
     * call to {@link sdk.User#login}
     *
     * @returns object An AccessToken instance.
     */
    getCurrentToken() {
        return this.auth.getToken()
    }

    /**
     * @name sdk.User#isAuthenticated
     *
     * @returns {boolean} True if the current user is authenticated (logged in).
     */
    isAuthenticated() {
        return !(
            this.getCurrentId() === '' ||
            this.getCurrentId() == null ||
            this.getCurrentId() == 'null'
        )
    }

    /**
     * @name sdk.User#getCurrentId
     *
     * @returns object Id of the currently logged-in user or null.
     */
    getCurrentId() {
        return this.auth.getCurrentUserId()
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `User`.
     */
    getModelName() {
        return 'User'
    }
}
