export const alineas = [
    {
        id: 'panel1',
        title: 'III - Situações Relacionadas com Dados cadastrais de Clientes:',
        itens: [
            {
                id: '3a',
                description:
                    'a) resistência ao fornecimento de informações necessárias para o início de relacionamento ou para a atualização cadastral, oferecimento de informação falsa ou prestação de informação de difícil ou onerosa verificação;',
            },
            {
                id: '3b',
                description:
                    'b) abertura, movimentação de contas ou realização de operações por detentor de procuração ou de qualquer outro tipo de mandato; Carta Circular no 3.542, de 12 de março de 2012.',
            },
            {
                id: '3c',
                description:
                    'c) apresentação de irregularidades relacionadas aos procedimentos de identificação e registro das operações exigidos pela regulamentação vigente, seguidas ou não do encerramento do relacionamento comercial;',
            },
            {
                id: '3d',
                description:
                    'd) cadastramento de várias contas em uma mesma data, ou em curto período, com depósitos de valores idênticos ou aproximados, ou com outros elementos em comum, tais como origem dos recursos, titulares, procuradores, sócios, endereço, número de telefone, etc;',
            },
            {
                id: '3e',
                description:
                    'e) realização de operações em que não seja possível identificar o beneficiário final, observados os procedimentos definidos na regulamentação vigente;',
            },
            {
                id: '3f',
                description:
                    'f) informação de mesmo endereço comercial por diferentes pessoas jurídicas ou organizações, sem justificativa razoável para tal ocorrência;',
            },
            {
                id: '3g',
                description:
                    'g) representação de diferentes pessoas jurídicas ou organizações pelos mesmos procuradores ou representantes legais, sem justificativa razoável para tal ocorrência;',
            },
            {
                id: '3h',
                description:
                    'h) informação de mesmo endereço residencial ou comercial por pessoas naturais, sem demonstração da existência de relação familiar ou comercial; e',
            },
            {
                id: '3i',
                description:
                    'i) incompatibilidade da atividade econômica ou faturamento informados com o padrão apresentado por clientes com o mesmo perfil;',
            },
        ],
    },
    {
        id: 'panel2',
        title: 'IV - Situações Relacionadas com a Movimentação de Contas:',
        itens: [
            {
                id: '4a',
                description:
                    'a) movimentação de recursos incompatível com o patrimônio, a atividade econômica ou a ocupação profissional e a capacidade financeira do cliente;',
            },
            {
                id: '4b',
                description:
                    'b) transferências de valores arredondados na unidade de milhar ou que estejam um pouco abaixo do limite para notificação de operações;',
            },
            {
                id: '4c',
                description:
                    'c) movimentação de recursos de alto valor, de forma contumaz, em benefício de terceiros',
            },
            {
                id: '4d',
                description:
                    'd) manutenção de numerosas contas destinadas ao acolhimento de depósitos em nome de um mesmo cliente, cujos valores, somados, resultem em quantia significativa;',
            },
            {
                id: '4e',
                description:
                    'e) movimentação de quantia significativa por meio de conta até então pouco movimentada ou de conta que acolha depósito inusitado;',
            },
            {
                id: '4f',
                description:
                    'f) ausência repentina de movimentação financeira em conta que anteriormente apresentava grande movimentação;',
            },
            {
                id: '4g',
                description:
                    'g) utilização de cofres de aluguel de forma atípica em relação ao perfil do cliente;',
            },
            {
                id: '4h',
                description:
                    'h) dispensa da faculdade de utilização de prerrogativas como recebimento de crédito, de juros remuneratórios para grandes saldos ou, ainda, de outros serviços bancários especiais que, em circunstâncias normais, sejam valiosas para qualquer cliente; Carta Circular no 3.542, de 12 de março de 2012.',
            },
            {
                id: '4i',
                description:
                    'i) mudança repentina e injustificada na forma de movimentação de recursos ou nos tipos de transação utilizados;',
            },
            {
                id: '4j',
                description:
                    'j) solicitação de não observância ou atuação no sentido de induzir funcionários da instituição a não seguirem os procedimentos regulamentares ou formais para a realização de uma operação;',
            },
            {
                id: '4k',
                description:
                    'k) recebimento de recursos com imediata compra de instrumentos para a realização de pagamentos ou de transferências a terceiros, sem justificativa;',
            },
            {
                id: '4l',
                description:
                    'l) realização de operações que, por sua habitualidade, valor e forma, configurem artifício para burla da identificação da origem, do destino, dos responsáveis ou dos beneficiários finais;',
            },
            {
                id: '4m',
                description:
                    'm) existência de contas que apresentem créditos e débitos com a utilização de instrumentos de transferência de recursos não característicos para a ocupação ou o ramo de atividade desenvolvida pelo cliente;',
            },
            {
                id: '4n',
                description:
                    'n) recebimento de depósitos provenientes de diversas origens, sem fundamentação econômico-financeira, especialmente provenientes de regiões distantes do local de atuação da pessoa jurídica ou distantes do domicílio da pessoa natural;',
            },
            {
                id: '4o',
                description:
                    'o) pagamentos habituais a fornecedores ou beneficiários que não apresentem ligação com a atividade ou ramo de negócio da pessoa jurídica;',
            },
            {
                id: '4p',
                description:
                    'p) pagamentos ou transferências por pessoa jurídica para fornecedor distante de seu local de atuação, sem fundamentação econômico-financeira;',
            },
            {
                id: '4q',
                description:
                    'q) realização de depósitos de cheques endossados totalizando valores significativos;',
            },
            {
                id: '4r',
                description:
                    'r) existência de conta de depósitos à vista de organizações sem fins lucrativos cujos saldos ou movimentações financeiras não apresentem fundamentação econômica ou legal ou nas quais pareça não haver vinculação entre a atividade declarada da organização e as outras partes envolvidas nas transações;',
            },
            {
                id: '4s',
                description:
                    's) movimentação habitual de recursos financeiros de ou para pessoas politicamente expostas ou pessoas de relacionamento próximo, não justificada por eventos econômicos;',
            },
            {
                id: '4t',
                description:
                    't) existência de contas em nome de menores ou incapazes, cujos representantes realizem grande número de operações atípicas; e',
            },
            {
                id: '4u',
                description:
                    'u) transações significativas e incomuns por meio de contas de depósitos de investidores não residentes constituídos sob a forma de trust;',
            },
        ],
    },
    {
        id: 'panel3',
        title: 'VII - Situações Relacionadas com Operações de Crédito no País:',
        itens: [
            {
                id: '7a',
                description:
                    'a) realização de operações de crédito no País liquidadas com recursos aparentemente incompatíveis com a situação econômico-financeira do cliente;',
            },
            {
                id: '7b',
                description:
                    'b) solicitação de concessão de crédito no País incompatível com a atividade econômica ou com a capacidade financeira do cliente;',
            },
            {
                id: '7c',
                description:
                    'c) realização de operação de crédito no País seguida de remessa de recursos ao exterior, sem fundamento econômico ou legal, e sem relacionamento com a operação de crédito;',
            },
            {
                id: '7d',
                description:
                    'd) realização de operações de crédito no País, simultâneas ou consecutivas, liquidadas antecipadamente ou em prazo muito curto;',
            },
            {
                id: '7e',
                description:
                    'e) liquidação de operações de crédito no País por terceiros, sem justificativa aparente;',
            },
            {
                id: '7f',
                description:
                    'f) concessão de garantias de operações de crédito no País por terceiros não relacionados ao tomador; por cliente sem tradição de realização de operações no exterior; e',
            },
            {
                id: '7g',
                description:
                    'g) realização de operação de crédito no País com oferecimento de garantia no exterior; Carta Circular no 3.542, de 12 de março de 2012',
            },
            {
                id: '7h',
                description:
                    'h) aquisição de bens ou serviços incompatíveis com o objeto da pessoa jurídica, especialmente quando os recursos forem originados de crédito no País;',
            },
        ],
    },
    {
        id: 'panel4',
        title: 'IX - Situações Relacionadas a Consórcios:',
        itens: [
            {
                id: '9a',
                description:
                    'a) existência de consorciados detentores de elevado número de cotas, incompatível com sua capacidade econômico-financeira ou com o objeto da pessoa jurídica; consorciado;',
            },

            {
                id: '9b',
                description:
                    'b) aumento expressivo do número de cotas pertencentes a um mesmo consorciado;',
            },
            {
                id: '9c',
                description:
                    'c) oferecimento de lances incompatíveis com a capacidade econômico-financeira do',
            },
            {
                id: '9d',
                description:
                    'd) oferecimento de lances muito próximos ao valor do bem;',
            },
            {
                id: '9e',
                description:
                    'e) pagamento antecipado de quantidade expressiva de prestações vincendas, não condizente com a capacidade econômico-financeira do consorciado; vincendas; consórcio ',
            },
            {
                id: '9f',
                description:
                    'f) aquisição de cotas previamente contempladas, seguida de quitação das prestações',
            },
            {
                id: '9g',
                description:
                    'g) utilização de documentos falsificados na adesão ou tentativa de adesão a grupo de;',
            },
        ],
    },
    {
        id: 'panel5',
        title:
            'X - Situações Relacionadas a Pessoas Suspeitas de Envolvimento com Atos Terroristas:',
        itens: [
            {
                id: '10a',
                description:
                    'a) movimentações financeiras envolvendo pessoas relacionadas a atividades terroristas listadas pelo Conselho de Segurança das Nações Unidas;',
            },
            {
                id: '10b',
                description:
                    'b) realização de operações ou prestação de serviços, qualquer que seja o valor, a pessoas que reconhecidamente tenham cometido ou intentado cometer atos terroristas, ou deles participado ou facilitado o seu cometimento; Carta Circular no 3.542, de 12 de março de 2012.',
            },
            {
                id: '10c',
                description:
                    'c) existência de recursos pertencentes ou controlados, direta ou indiretamente, por pessoas que reconhecidamente tenham cometido ou intentado cometer atos terroristas, ou deles participado ou facilitado o seu cometimento; e',
            },
            {
                id: '10d',
                description:
                    'd) movimentações com indícios de financiamento do terrorismo;',
            },
        ],
    },
]
