import React from 'react'
import { Date, Money, PrePagConGte10k } from 'components/InovaTable'

export const consorcio_columns = [
    // CLIENTE
    {
        field: 'descricao_bem',
        name: 'DESCRIÇÃO DO BEM',
        customBodyRender: (value) => {
            try {
                return <span>{value}</span>
            } catch (error) {
                return 0
            }
        },
    },
    {
        field: 'grupo',
        name: 'GRUPO',
        customBodyRender: (value) => {
            try {
                return <span>{value}</span>
            } catch (error) {
                return 0
            }
        },
    },
    {
        field: 'cota',
        name: 'COTA',
        customBodyRender: (value) => {
            try {
                return <span>{value}</span>
            } catch (error) {
                return 0
            }
        },
    },
    {
        field: 'versao',
        name: 'VERSÃO',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <span>{value}</span>
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'data_adesao',
        name: 'DATA ADESÃO',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Date value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'data_entrega_bem',
        name: 'DATA ENTREGA BEM',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Date value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'valor_pago',
        name: 'VALOR PAGO',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Money value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'gte_10k',
        name: 'PRÉ PAG. > 10K',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <PrePagConGte10k value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'perc_parcela_paga',
        name: '% PARCELA PAGA',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <span>{value}</span>
                } catch (error) {
                    return false
                }
            },
        },
    },
    {
        field: 'perc_amortizado_cota',
        name: '% AMORTIZADO COTA',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <span>{value}</span>
                } catch (error) {
                    return false
                }
            },
        },
    },
    {
        field: 'saldo_devedor',
        name: 'SALDO DEVEDOR',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Money value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'data_pagamento',
        name: 'DATA DO PAGAMENTO',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Date value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'cod_mov',
        name: 'COD. MOV.',
        customBodyRender: (value) => {
            try {
                return <span>{value}</span>
            } catch (error) {
                return 0
            }
        },
    },
    {
        field: 'situacao_cota',
        name: 'SITUAÇÃO COTA',
        customBodyRender: (value) => {
            try {
                return <span>{value}</span>
            } catch (error) {
                return 0
            }
        },
    },
    {
        field: 'data_contemplacao',
        name: 'DATA CONTEMPLAÇÃO',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Date value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'tipo_conteplacao',
        name: 'TIPO CONTEMPLAÇÃO',
        customBodyRender: (value) => {
            try {
                return <span>{value}</span>
            } catch (error) {
                return 0
            }
        },
    },
    {
        field: 'favoericdo',
        name: 'FAVORECIDO',
        customBodyRender: (value) => {
            try {
                return <span>{value}</span>
            } catch (error) {
                return 0
            }
        },
    },
    {
        field: 'delta_dias',
        name: 'DIAS DA ADESÃO',
        customBodyRender: (value) => {
            try {
                return (
                    <span>
                        {value === undefined || value === null ? '0' : value}
                    </span>
                )
            } catch (error) {
                return 0
            }
        },
    },
    {
        field: 'delta_mes',
        name: 'MESES DA ADESÃO',
        customBodyRender: (value) => {
            try {
                let meses = value === null ? '' : value
                return meses === '' ? <span>0</span> : <span>{meses}</span>
            } catch (error) {
                return 0
            }
        },
    },
    {
        field: 'valor_creditado',
        name: 'VALOR CREDITADO',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Money value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'data_transferencia',
        name: 'DATA TRANSFERÊNCIA',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    let data = value === null ? '' : value
                    return data === '' ? <span> </span> : <Date value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'product',
        name: 'TIPO',
        customBodyRender: (value) => {
            try {
                return <span>{value}</span>
            } catch (error) {
                return 0
            }
        },
    },
    {
        field: 'pag_rel_renda',
        name: '% PAG. REL. RENDA',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return (
                        <span>{(parseFloat(value) * 100).toFixed(2) + ''}</span>
                    )
                } catch (error) {
                    return false
                }
            },
        },
    },
    {
        field: 'perc_pag_cred',
        name: '% PAG CRED.',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <span>{(parseFloat(value) * 100).toFixed(2)}</span>
                } catch (error) {
                    return false
                }
            },
        },
    },
]
