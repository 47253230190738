//data_pagamento

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/pt-br'

const DataPagamentoFormatter = (props) => {
    const dateToFormat = props.row.data_pagamento
    return (
        <div>
            <Moment locale="pt-br" format="DD[/]MM[/]YYYY">
                { dateToFormat }
            </Moment>
        </div>
    )
}

export const DataPagamentoProvider = (props) => (
    <DataTypeProvider formatterComponent={ DataPagamentoFormatter } { ...props } />
)
