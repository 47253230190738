import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import Services from '../../../../services/Services'

const CpfCnpjFormatter = (props) => {
    const value = Services.maskCpfCnpj(props.row.cpf_f)
    return (
        <div>
            <span>{ value }</span>
        </div>
    )
}

export const CpfCnpjTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={ CpfCnpjFormatter }
        { ...props }
    />
)
