import React from 'react'
import { FuseAnimate } from '@fuse'
import { Typography, withStyles } from '@material-ui/core'

const styles = theme => ({
    root: {},
    icon: {
        color: 'white',
    },
})

const HeaderComponent = ({ classes, name }) => (
    <React.Fragment>
        <div className="flex items-center">
            <FuseAnimate animation="transition.slideLeftIn" delay={ 300 }>
                <Typography className="hidden sm:flex" variant="h4">
                    Importação
                </Typography>
            </FuseAnimate>
        </div>
    </React.Fragment>
)

export default withStyles(styles)(HeaderComponent)
