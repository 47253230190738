//data_contemplacao


import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/pt-br'


const DataContemplacaoFormatter = (props) => {
    const dateToFormat = props.row.data_contemplacao

    return (
        <div>
            <Moment locale="pt-br" format="DD[/]MM[/]YYYY">{ dateToFormat }</Moment>
        </div>
    )
}

export const DataContemplacaoProvider = props => (
    <DataTypeProvider
        formatterComponent={ DataContemplacaoFormatter }
        { ...props }
    />
)
