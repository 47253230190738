import { Map } from 'immutable'

import {
    FAIL_CLIENTS,
    FAIL_SELECT_CLIENT,
    REQUEST_CLIENTS,
    REQUEST_SELECT_CLIENT,
    SUCCESS_CLIENTS,
    SUCCESS_SELECT_CLIENT,
} from './Clients.actions'

let clientsInitial = Map({
    clients: null,
    load: null,
    fail: null,

    clientSelected: null,
    loadClientSelected: false,
    failClientSelected: false,

})

const clientsReducer = (state = clientsInitial, action) => {
    switch (action.type) {

        /* ************************************ GET ALL CLIENTS ************************************ */

        case REQUEST_CLIENTS:
            state = state.set('load', true).set('fail', false)
            break

        case SUCCESS_CLIENTS:
            state = state.set('clients', action.payload.clients).set('load', false)
                .set('fail', false)
            break

        case FAIL_CLIENTS:
            state = state.set('load', false).set('fail', true)
            break


        /* ************************************ SELECT CLIENT ************************************ */

        case REQUEST_SELECT_CLIENT:
            state = state.set('loadClientSelected', true).set('failClientSelected', false)
            break

        case SUCCESS_SELECT_CLIENT:
            state = state.set('clientSelected', action.payload.client).set('loadClientSelected', false)
                .set('failClientSelected', false)
            break

        case FAIL_SELECT_CLIENT:
            state = state.set('loadClientSelected', false).set('failClientSelected', true)
            break

        default:
            break
    }

    return state

}// Fim do reducers

export default clientsReducer
