import 'babel-polyfill'
import 'typeface-muli'
import React from 'react'
import ReactDOM from 'react-dom'
import history from './history'
import './react-table-defaults'
import './styles/index.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import JssProvider from 'react-jss/lib/JssProvider'
import { create } from 'jss'
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles'
import registerServiceWorker from './registerServiceWorker'
import { Router } from 'react-router-dom'
import { routes } from './config/routes'
import { FuseLayout, FuseTheme } from '@fuse'
import MainToolbar from './components/MainToolbar'
import MainNavbarContent from './components/MainNavbarContent'
import MainNavbarHeader from './components/MainNavbarHeader'
import MainFooter from './components/MainFooter'
import jssExtend from 'jss-extend'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { configureStore, persistor } from './redux/store'
import { InternalStorage, LoopBackConfig } from './sdk'

import ReactGA from 'react-ga'

ReactGA.initialize('UA-129265863-2')
ReactGA.pageview(window.location.pathname + window.location.search)

// LoopBackConfig.setBaseURL('http://localhost:30001')
LoopBackConfig.setBaseURL('https://inovafa.api.pock.inovamind.com.br')
LoopBackConfig.setApiVersion('api')
LoopBackConfig.setDebugMode(true)

const storage = new InternalStorage()
storage.set('teste', true)

const jss = create({
    ...jssPreset(),
    plugins: [...jssPreset().plugins, jssExtend()],
})

jss.options.insertionPoint = document.getElementById('jss-insertion-point')
const generateClassName = createGenerateClassName()

ReactDOM.render(
    <JssProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={configureStore}>
            <PersistGate loading={null} persistor={persistor}>
                <Router history={history}>
                    <FuseTheme>
                        <ToastContainer />
                        <FuseLayout
                            routes={routes}
                            toolbar={<MainToolbar />}
                            navbarHeader={<MainNavbarHeader />}
                            navbarContent={<MainNavbarContent />}
                            footer={<MainFooter />}
                        />
                    </FuseTheme>
                </Router>
            </PersistGate>
        </Provider>
    </JssProvider>,
    document.getElementById('root'),
)

registerServiceWorker()

if (module.hot) {
    module.hot.accept()
}
