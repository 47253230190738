// Adiciona botões de comandos á tabela.
//thumb_up_alt

import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import { ThumbDownAlt, ThumbUpAlt } from '@material-ui/icons'
import { DataTypeProvider } from '@devexpress/dx-react-grid'


const approve = (cpf_cnpj) => {
    // alert("Aprovar: " + cpf_cnpj);
}

const disapprove = (cpf_cnpj) => {
    // alert("Desaprovar: " + cpf_cnpj);
}

const ApproveFormatter = (props) => {
    return (
        <React.Fragment>
            <IconButton onClick={ () => approve(props.row.cpf_cnpj) } title="Aprovar">
                <ThumbUpAlt/>
            </IconButton>
            <IconButton onClick={ () => disapprove(props.row.cpf_cnpj) } title="Desaprovar">
                <ThumbDownAlt/>
            </IconButton>
        </React.Fragment>
    )
}

export const ApproveTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={ ApproveFormatter }
        { ...props }
    />
)
