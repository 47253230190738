import * as React from 'react'
import { Button, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import { reduxForm } from 'redux-form'
import axios from 'axios'
// import { createTextMask } from "redux-form-input-masks";
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

const styles = (theme) => ({
    button: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 100,
        marginTop: theme.spacing.unit * 3,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 230,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        width: '90%',
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
})

class FormSchema extends React.Component {
    state = {
        inputs: [],
        cities: [],
        states: [],

        selectState: '',
        selectCity: '',
        selectStatus: '',
        selectType: '',

        cpf_j: '',
        name: '',
        where: {
            cpf_cnpj: {
                id: 'cpf_cnpj',
                value: '',
                type: 'number',
            },
            name: {
                id: 'name',
                like: true,
                value: '',
            },
            preTen: {
                id: 'v_prepag.gte_10k',
                value: false,
            },
            sixmonth: {
                id: 'pre_pag_lte_6.total',
                value: true,
                type: 'query',
                query: {
                    gte: 1,
                },
            },
            highrisk: {
                id: 'highrisk',
                value: false,
            },
            status: {
                id: 'status',
                value: null,
            },
            type: {
                id: 'type',
                value: null,
            },
            'v_prepag.11x_monthly_income': {
                id: 'v_prepag.11x_monthly_income',
                value: false,
            },
            'addr.city': {
                id: 'addr.city',
                value: null,
            },
            'addr.uf': {
                id: 'addr.uf',
                value: null,
            },
            // initialDate: new Date(),
            // finalDate: '',
        },

        checkPrePay: true,
    }

    componentWillMount() {
        axios
            .get('https://br-cidade-estado-nodejs.glitch.me/estados')
            .then((res) => {
                this.setState({ states: res.data })
            })
    }

    componentDidMount() {
        setTimeout(() => this.submit(), 1000)
    }

    // Entrada de dados do cpf/cnpj
    onInputChange = (field, value) => {
        let { where } = this.state
        where[field].value = value
        console.log('where', where)
        this.setState({ where })
    }

    // Seleciona o estado
    onState = (event) => {
        this.setState({ selectState: event.target.value }, () => {
            let { where, selectState } = this.state
            where['addr.uf'].value = selectState
            console.log('where', where)
            this.setState({ where })
            this.getCities()
        })
    }

    // Get Cidades
    getCities = () => {
        // Pegao uf do state.
        const { selectState } = this.state
        axios
            .get(
                `https://br-cidade-estado-nodejs.glitch.me/estados/${selectState}/cidades`,
            )
            .then((res) => {
                this.setState({ cities: res.data })
            })
    }

    // Seleciona a Cidade
    onCity = (event) => {
        this.setState({ selectCity: event.target.value }, () => {
            // this.inpust('addr.city', this.state.selectCity)

            let { where, selectCity } = this.state
            where['addr.city'].value = selectCity
            console.log('where', where)
            this.setState({ where })
        })
    }

    onSelect = (field, value) => {
        this.setState({ [field]: value }, () => {
            let { where } = this.state
            where[field].value = value
            console.log('where', where)
            this.setState({ where })
        })
    }

    // Muda o swich
    handlerSwitch = (name) => {
        const where = this.state.where
        where[name].value = !where[name].value
        this.setState({ where })
    }

    //Select Alertas
    handleChange = (event, name) => {
        console.log(name)
        const where = this.state.where
        where[name].value = event.target.value
        console.log(where)
        this.setState({ where })
    }

    submit = (e) => {
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }
        const { onFilter } = this.props
        let { where } = this.state
        where = Object.keys(where)
            .map((key) => {
                const item = where[key]
                if (item.value) return item
            })
            .filter((i) => i)
        if (onFilter) onFilter(where)
        console.log('aqui')
        return false
    }

    reset = () => {
        this.setState(
            {
                inputs: [],
                cities: [],
                states: [],

                selectState: '',
                selectCity: '',
                selectStatus: '',
                selectType: '',

                cpf_j: '',
                name: '',
                where: {
                    cpf_cnpj: {
                        id: 'cpf_cnpj',
                        value: '',
                        type: 'number',
                    },
                    name: {
                        id: 'name',
                        like: true,
                        value: '',
                    },
                    preTen: {
                        id: 'v_prepag.gte_10k',
                        value: false,
                    },
                    sixmonth: {
                        id: 'pre_pag_lte_6.total',
                        value: true,
                        type: 'query',
                        query: {
                            gte: 1,
                        },
                    },
                    highrisk: {
                        id: 'highrisk',
                        value: false,
                    },
                    status: {
                        id: 'status',
                        value: null,
                    },
                    type: {
                        id: 'type',
                        value: null,
                    },
                    'v_prepag.11x_monthly_income': {
                        id: 'v_prepag.11x_monthly_income',
                        value: false,
                    },
                    'addr.city': {
                        id: 'addr.city',
                        value: null,
                    },
                    'addr.uf': {
                        id: 'addr.uf',
                        value: null,
                    },
                    // initialDate: new Date(),
                    // finalDate: '',
                },

                checkPrePay: true,
            },
            () => this.props.onFilter(this.state.inputs),
        )
    }

    render() {
        const { classes } = this.props
        const {
            where: { cpf_cnpj, name },
        } = this.state
        return (
            <form onSubmit={this.submit}>
                <TextField
                    key="cpf_cnpj"
                    id="cpf_cnpj"
                    label="CPF/CNPJ"
                    placeholder="CPF ou CNPJ"
                    value={cpf_cnpj.value}
                    className={classes.textField}
                    onChange={(e) =>
                        this.onInputChange('cpf_cnpj', e.target.value)
                    }
                />

                <TextField
                    key="name"
                    id="name"
                    label="Nome"
                    placeholder="Nome"
                    value={name.value}
                    className={classes.textField}
                    onChange={(e) => this.onInputChange('name', e.target.value)}
                />

                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="states-simple">Estados</InputLabel>
                    <Select
                        value={this.state.selectState}
                        onChange={this.onState}
                    >
                        <MenuItem value="">
                            <em>Todos</em>
                        </MenuItem>
                        {this.state.states.map((item) => {
                            return (
                                <MenuItem key={item.estado} value={item.id}>
                                    {item.estado}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>

                {this.state.cities.length > 0 ? (
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="cities-simple">Cidades</InputLabel>
                        <Select
                            value={this.state.selectCity}
                            onChange={this.onCity}
                        >
                            <MenuItem value="">Todos</MenuItem>
                            {this.state.cities.map((item) => {
                                return (
                                    <MenuItem
                                        key={item.cidade}
                                        value={item.cidade}
                                    >
                                        {item.cidade}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                ) : null}

                <FormControl className={classes.formControl}>
                    <InputLabel>Tipo</InputLabel>
                    <Select
                        value={this.state.where['type'].value || ''}
                        onChange={(e) => this.onSelect('type', e.target.value)}
                    >
                        <MenuItem value="">Todos</MenuItem>
                        <MenuItem value={'F'}>Pessoa Física</MenuItem>
                        <MenuItem value={'J'}>Pessoa Jurídica</MenuItem>
                    </Select>
                </FormControl>

                <FormControl className={classes.formControl}>
                    <FormLabel component="legend">
                        {'Últimos 6 meses'}
                    </FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.where.sixmonth.value}
                                    onChange={() =>
                                        this.handlerSwitch('sixmonth')
                                    }
                                    value={this.state.where.sixmonth.value}
                                    color="primary"
                                />
                            }
                            label={
                                this.state.where.sixmonth.value ? 'Sim' : 'Não'
                            }
                        />
                    </FormGroup>
                </FormControl>

                <FormControl className={classes.formControl}>
                    <FormLabel component="legend">
                        {'Pré Pag. >= 10k'}
                    </FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.where.preTen.value}
                                    onChange={() =>
                                        this.handlerSwitch('preTen')
                                    }
                                    value={this.state.where.preTen.value}
                                    color="primary"
                                />
                            }
                            label={
                                this.state.where.preTen.value ? 'Sim' : 'Não'
                            }
                        />
                    </FormGroup>
                </FormControl>

                <FormControl className={classes.formControl}>
                    <FormLabel component="legend">{'11x a Renda'}</FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={
                                        this.state.where[
                                            'v_prepag.11x_monthly_income'
                                        ].value
                                    }
                                    onChange={() =>
                                        this.handlerSwitch(
                                            'v_prepag.11x_monthly_income',
                                        )
                                    }
                                    value={
                                        this.state.where[
                                            'v_prepag.11x_monthly_income'
                                        ].value
                                    }
                                    color="primary"
                                />
                            }
                            label={
                                this.state.where['v_prepag.11x_monthly_income']
                                    .value
                                    ? 'Sim'
                                    : 'Não'
                            }
                        />
                    </FormGroup>
                </FormControl>

                <FormControl className={classes.formControl}>
                    <FormLabel component="legend">{'Alto Risco'}</FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.where.highrisk.value}
                                    onChange={() =>
                                        this.handlerSwitch('highrisk')
                                    }
                                    value={this.state.where.highrisk.value}
                                    color="primary"
                                />
                            }
                            label={
                                this.state.where.highrisk.value ? 'Sim' : 'Não'
                            }
                        />
                    </FormGroup>
                </FormControl>

                <FormGroup
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        paddingBottom: '1rem',
                    }}
                >
                    <Button
                        variant="contained"
                        className={classes.button}
                        onClick={this.reset}
                    >
                        Limpar
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        type="submit"
                        onClick={this.submit}
                    >
                        Filtrar
                    </Button>
                </FormGroup>
            </form>
        )
    }
}

export default reduxForm({
    form: 'filters',
})(withStyles(styles, { withTheme: true })(FormSchema))
