export class Ativo_con {
    'CPF_CNPJ'
    'Grupo'
    'Cota'
    'CEP'
    'Percentual_Pago'
    'Nome'
    'Tipo'
    'Nacionalidade'
    'Endereco'
    'Bairro'
    'Cidade'
    'UF'
    'Situacao_Cota'
    'Situacao_Grupo'
    'Tipo_Contemplacao'
    'Data_Nascimento'
    'Data_Contemplacao'
    'Data_Venda'
    'Data_pagamento'
    'dtref'
    'data_prod'
    'data_type'
    'id'

    constructor(data) {
        Object.assign(this, data)
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `Ativo_con`.
     */
    static getModelName() {
        return 'Ativo_con'
    }

    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of Ativo_con for dynamic purposes.
     **/
    static factory(data) {
        return new Ativo_con(data)
    }

    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    static getModelDefinition() {
        return {
            name: 'Ativo_con',
            plural: 'Ativo_cons',
            path: 'Ativo_cons',
            properties: {
                'CPF_CNPJ': {
                    name: 'CPF_CNPJ',
                    type: 'any',
                },
                'Grupo': {
                    name: 'Grupo',
                    type: 'number',
                },
                'Cota': {
                    name: 'Cota',
                    type: 'number',
                },
                'CEP': {
                    name: 'CEP',
                    type: 'number',
                },
                'Percentual_Pago': {
                    name: 'Percentual_Pago',
                    type: 'number',
                },
                'Nome': {
                    name: 'Nome',
                    type: 'string',
                },
                'Tipo': {
                    name: 'Tipo',
                    type: 'string',
                },
                'Nacionalidade': {
                    name: 'Nacionalidade',
                    type: 'string',
                },
                'Endereco': {
                    name: 'Endereco',
                    type: 'string',
                },
                'Bairro': {
                    name: 'Bairro',
                    type: 'string',
                },
                'Cidade': {
                    name: 'Cidade',
                    type: 'string',
                },
                'UF': {
                    name: 'UF',
                    type: 'string',
                },
                'Situacao_Cota': {
                    name: 'Situacao_Cota',
                    type: 'string',
                },
                'Situacao_Grupo': {
                    name: 'Situacao_Grupo',
                    type: 'string',
                },
                'Tipo_Contemplacao': {
                    name: 'Tipo_Contemplacao',
                    type: 'string',
                },
                'Data_Nascimento': {
                    name: 'Data_Nascimento',
                    type: 'number',
                },
                'Data_Contemplacao': {
                    name: 'Data_Contemplacao',
                    type: 'number',
                },
                'Data_Venda': {
                    name: 'Data_Venda',
                    type: 'number',
                },
                'Data_pagamento': {
                    name: 'Data_pagamento',
                    type: 'number',
                },
                'dtref': {
                    name: 'dtref',
                    type: 'Date',
                },
                'data_prod': {
                    name: 'data_prod',
                    type: 'string',
                },
                'data_type': {
                    name: 'data_type',
                    type: 'string',
                },
                'id': {
                    name: 'id',
                    type: 'any',
                },
            },
            relations: {},
        }
    }
}
