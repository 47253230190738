import React, { Component } from 'react'
import UploadButton from '../../components/UploadComponent'
import { Card, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

// import { Container } from './styles';
const styles = theme => ({
    card: {
        margin: theme.spacing.unit,
        width: '50%',
        padding: theme.spacing.unit * 3,
    },
})


class ImportComponent extends Component {
    render() {
        const { classes } = this.props
        return <div className="flex flex-row">
            <Card className={ classes.card }>
                <Typography className="hidden sm:flex" variant="h6">
                    Importar Planilha 1
                </Typography>
                <UploadButton title="Planilha 1" color="primary"/>
            </Card>
            <Card className={ classes.card }>
                <Typography className="hidden sm:flex" variant="h6">
                    Importar Planilha 2
                </Typography>
                <UploadButton title="Planilha 2" color="primary"/>
            </Card>
        </div>
    }
}

export default withStyles(styles)(ImportComponent)
