import { combineEpics } from 'redux-observable'

import { clientSelectEpics, clientsEpics } from './clients/Clients.epics'
import { authsEpics } from './auths/Auths.epics'

const epics = [clientsEpics, clientSelectEpics, authsEpics]

const epicsRoots = combineEpics(...epics)

export default epicsRoots
