import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { Button, Paper, TextField } from '@material-ui/core'
import { isAbsolute } from 'upath'
import classNames from 'classnames'

const styles = theme => ({
    layoutRoot: {},
    paper: {
        padding: '20px',
        marginBottom: '30px',
    },
    input: {
        margin: '20px',
        width: '300px',
        maxWidth: '100%',
    },
    button: {
        marginTop: '30px',
    },
    back: {
        marginTop: '30px',
        color: 'black',
    },
})

// import { Container } from './styles';
class ProfileContent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            form: {
                name: '',
                email: '',
                oldPassword: '',
                newPassword: '',
                phone: '',
            },
        }
    }

    handleChange = fieldname => event => {
        const form = {
            ...this.state.form,
        }
        form[fieldname] = event.target.value
        this.setState({ form })
    }

    handleSubmit = () => {
        console.log(this.state.form)
    }

    render() {
        const { classes } = this.props
        const { name, email, phone, oldPassword, newPassword } = this.state.form
        return (
            <React.Fragment>
                <main>
                    <Paper className={ classNames(classes.paper, 'flex flex-col') }>
                        {/* <Avatar>
              <Person />
            </Avatar> */ }
                        <form
                            className="flex flex-col items-center justify-center"
                            noValidate
                            autoComplete="off"
                        >
                            <div className="flex flex-1">
                                <TextField
                                    className={ classes.input }
                                    id="name"
                                    label="Nome"
                                    value={ this.state.name }
                                    value={ name }
                                    onChange={ this.handleChange('name') }
                                />
                                <TextField
                                    className={ classes.input }
                                    id="email"
                                    label="Email"
                                    value={ email }
                                    onChange={ this.handleChange('email') }
                                />
                                <TextField
                                    className={ classes.input }
                                    id="phone"
                                    label="Telefone"
                                    value={ phone }
                                    onChange={ this.handleChange('phone') }
                                />
                            </div>
                            <div>
                                <TextField
                                    className={ classes.input }
                                    label="Senha Atual"
                                    type="password"
                                    value={ oldPassword }
                                    onChange={ this.handleChange('oldPassword') }
                                />
                                <TextField
                                    className={ classes.input }
                                    label="Nova Senha"
                                    type="password"
                                    value={ newPassword }
                                    onChange={ this.handleChange('newPassword') }
                                />
                            </div>
                            <Button
                                className={ classes.button }
                                variant="contained"
                                size="large"
                                color="primary"
                                onClick={ this.handleSubmit }
                            >
                                Atualizar Perfil
                            </Button>
                        </form>
                    </Paper>
                    <Link style={ { color: '#424242' } } to="/dashboard">
                        Voltar a Dashboard
                    </Link>
                </main>
            </React.Fragment>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ProfileContent)
