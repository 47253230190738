export default (theme) => ({
    root: {},
    containerHistorico: {
        borderLeft: '2px solid black',
        paddingLeft: 0,
        listStyle: 'none',
        padding: '25px 0px',
    },
    event: {
        listStyle: 'none',
        paddingBottom: '20px',
    },
    dateEvent: {
        padding: '10px 15px',
        backgroundColor: 'black',
        color: 'white',
        position: 'relative',
        marginBottom: '10px',
    },
    marker: {
        left: '-54px',
        position: 'absolute',
        fontSize: '50px',
        top: '-19px',
        color: 'black',
    },
    titleEvent: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    subtitleEvent: {
        margin: '15px 0px',
    },
    titles: {
        margin: '25px 0',
        display: 'flex',
        justifyContent: 'center',
    },
})
