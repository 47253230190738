import React, { Component } from 'react'
import { FusePageSimple } from '@fuse'
import { connect } from 'react-redux'
import { isAbsolute } from 'upath'
import EDDPage from 'components/EDD/EDD'
import { ClientApi, DecisionApi } from '../../sdk'
import { historico_columns } from '../clientDetails/datatable/historico_columns'
import * as _ from 'lodash'
import { alineas } from '../clientDetails/alineas'
import {
    consorcio_historico_columns,
    varejo_historico_columns,
} from '../../components/EDD/datatable/varejo_historico_columns'

class EDD extends Component {
    state = {}

    componentWillMount() {
        const token = this.props.authsReducer.get('token')
        if (token === null) {
            this.props.history.push({
                pathname: '/',
            })
        }
    }

    componentDidMount() {
        this.onAlienas()
    }

    getClient = async (client) => {
        try {
            const data = await new ClientApi()
                .findOne({ where: { cpf_cnpj: client } })
                .toPromise()
            console.log('------------CLIENTE DATA', data)
            if (data) {
                this.setState({ client: data })
            }
        } catch (e) {
            console.log('error', e)
        }
    }

    onAlienas = async () => {
        try {
            const data = await new DecisionApi()
                .findOne({
                    where: { id: this.props.match.params.id },
                    order: 'id DESC',
                    include: ['analista', 'gerente', 'supergerente'],
                })
                .toPromise()

            console.log('------------data', data)
            if (data) {
                this.getClient(data.cpf_cnpj)
                let viewConsorcios = null
                let viewFinanciamentos = null
                if (data.consorcios) {
                    viewConsorcios = data.consorcios.map((row) =>
                        consorcio_historico_columns.map((col) =>
                            !row[col.field] ? null : row[col.field],
                        ),
                    )
                }
                if (data.financiamentos) {
                    viewFinanciamentos = data.financiamentos.map((row) =>
                        varejo_historico_columns.map((col) =>
                            !row[col.field] ? null : row[col.field],
                        ),
                    )
                }
                this.setState({
                    decision: {
                        ...this.state.decision,
                        ...data,
                    },
                    viewConsorcios,
                    viewFinanciamentos,
                    alineas: data.alineas,
                })
                this.alineasView(data.alineas)
            }
        } catch (e) {
            console.log('erorr', e)
        }
    }

    alineasView = (selected) => {
        if (!selected) return false
        let result = []
        alineas.map((pai) => {
            let nPai = { ...pai }
            nPai.itens = []
            pai.itens.map((i) =>
                _.some(selected, { id: i.id }) ? nPai.itens.push(i) : null,
            )
            result.push(nPai)
        })

        result = result.filter((i) => i.itens.length)
        this.setState({ alineas: result })
    }

    render() {
        const { classes } = this.props
        const {
            client,
            decision,
            viewConsorcios,
            viewFinanciamentos,
            alineas,
        } = this.state

        console.log('------STATE', this.state)

        return (
            <FusePageSimple
                // content={ <h1>Ola</h1> }
                content={
                    client && decision ? (
                        <EDDPage
                            client={client}
                            decision={decision}
                            alineas={alineas}
                            consorcios={viewConsorcios}
                            financiamentos={viewFinanciamentos}
                        />
                    ) : null
                }
            />
        )
    }
}

const mapStateToProps = (state) => ({
    authsReducer: state.authsReducer,
})

export default connect(
    mapStateToProps,
    null,
)(EDD)
