import React from 'react'
import { Date, Money, PrePagConGte10k } from 'components/InovaTable'

export const historico_columns = [
    // CLIENTE
    {
        field: 'grupo',
        name: 'GRUPO',
        customBodyRender: (value) => {
            try {
                return <span>{value}</span>
            } catch (error) {
                return 0
            }
        },
    },
    {
        field: 'cota',
        name: 'COTA',
        customBodyRender: (value) => {
            try {
                return <span>{value}</span>
            } catch (error) {
                return 0
            }
        },
    },
    {
        field: 'data_adesao',
        name: 'DATA ADESÃO',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Date value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'data_contemplacao',
        name: 'DATA CONTEMPLAÇÃO',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Date value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'data_entrega_bem',
        name: 'RETIRADA DO PRÊMIO',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Date value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
    {
        field: 'valor_pago',
        name: 'VALOR PAGO',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Money value={value} />
                } catch (error) {
                    return 0
                }
            },
        },
    },
]
