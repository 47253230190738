//data_adesao

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/pt-br'

const DataContratoFormatter = (props) => {
    // console.log(props.row)
    const dateToFormat =
        props.row.data_contrato === null ||
        props.row.data_contrato === undefined
            ? ''
            : props.row.data_contrato
    return dateToFormat === '' ? (
        <span>{ dateToFormat }</span>
    ) : (
        <div>
            <Moment locale="pt-br" format="DD[/]MM[/]YYYY">
                { dateToFormat }
            </Moment>
        </div>
    )
}

export const DataContratoProvider = (props) => (
    <DataTypeProvider formatterComponent={ DataContratoFormatter } { ...props } />
)
