import * as authsActions from './Auths.actions'
import { toast } from 'react-toastify'
import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { UserApi } from 'sdk'

import TokenService from './../../services/TokenService'

/* ************************************ LOGIN ************************************ */

export const authsEpics = (action$, store) => {
    return action$.pipe(
        ofType(authsActions.LOGIN),
        mergeMap((action) => {
            const {
                payload: { email, password },
            } = action
            console.log(email, password)

            const userApi = new UserApi()

            console.log(userApi)

            return userApi
                .login({ email, password })
                .toPromise()
                .then((data) => {
                    if (data) {
                        toast.success('Bem vindo de volta', {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    }
                    console.log('data', data)
                    const tokenService = new TokenService()
                    tokenService.setToken(data.id)
                    return authsActions.successLogin({
                        ...data.user,
                        token: data.id,
                    })
                })
                .catch((err) => {
                    console.log('deu erro', err)
                    toast.error('Email ou senha incorretos!', {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                    return authsActions.failLogin()
                })
        }),
    )
}
