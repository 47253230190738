//data_adesao

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/pt-br'

const DataInclusaoFormatter = (props) => {
    const dateToFormat = props.row.createdAt
    return (
        <div>
            <Moment locale="pt-br" format="DD[/]MM[/]YYYY[ - ]HH:mm">
                { dateToFormat }
            </Moment>
        </div>
    )
}

export const DataInclusaoProvider = (props) => (
    <DataTypeProvider formatterComponent={ DataInclusaoFormatter } { ...props } />
)
