import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    input: {
        display: 'none',
    },
})

function UploadButton(props) {
    const { classes, title, color } = props
    return (
        <div>
            <input
                accept="image/*"
                className={ classes.input }
                id="flat-button-file"
                multiple
                type="file"
            />
            <label htmlFor="flat-button-file">
                <Button
                    component="span"
                    className={ classes.button }
                    color={ color }
                    variant="contained"
                >
                    { title }
                </Button>
            </label>
        </div>
    )
}

UploadButton.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UploadButton)
