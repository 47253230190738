//data_entrega_bem

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/pt-br'

const DataEntregaBemFormatter = (props) => {
    const dateToFormat = props.row.data_entrega_bem
    return (
        <div>
            <Moment locale="pt-br" format="DD[/]MM[/]YYYY">
                { dateToFormat }
            </Moment>
        </div>
    )
}

export const DataEntregaBemProvider = (props) => (
    <DataTypeProvider formatterComponent={ DataEntregaBemFormatter } { ...props } />
)
