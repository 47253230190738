import { Country } from '../index'


export class City {
    'image'
    'name'
    'stateId'
    'countryId'
    'active'
    'id'
    'cityId'
    country
    state

    constructor(data) {
        Object.assign(this, data)
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `City`.
     */
    static getModelName() {
        return 'City'
    }

    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of City for dynamic purposes.
     **/
    static factory(data) {
        return new City(data)
    }

    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    static getModelDefinition() {
        return {
            name: 'City',
            plural: 'Cities',
            path: 'Cities',
            properties: {
                'image': {
                    name: 'image',
                    type: 'any',
                },
                'name': {
                    name: 'name',
                    type: 'string',
                },
                'stateId': {
                    name: 'stateId',
                    type: 'any',
                },
                'countryId': {
                    name: 'countryId',
                    type: 'any',
                },
                'active': {
                    name: 'active',
                    type: 'boolean',
                    default: true,
                },
                'id': {
                    name: 'id',
                    type: 'any',
                },
                'cityId': {
                    name: 'cityId',
                    type: 'any',
                },
            },
            relations: {
                country: {
                    name: 'country',
                    type: 'Country',
                    model: 'Country',
                },
                state: {
                    name: 'state',
                    type: 'State',
                    model: 'State',
                },
            },
        }
    }
}
