export const REQUEST_CLIENTS = 'REQUEST_CLIENTS'
export const SUCCESS_CLIENTS = 'SUCCESS_CLIENTS'
export const FAIL_CLIENTS = 'FAIL_CLIENTS'


/* ************************************ SELECT CLIENT ************************************ */
// Chama api para selecionar um cliente através do id.

export const REQUEST_SELECT_CLIENT = 'SELECT_CLIENT'
export const SUCCESS_SELECT_CLIENT = 'SUCCESS_SELECT_CLIENT'
export const FAIL_SELECT_CLIENT = 'FAIL_SELECT_CLIENT'


/* ************************************ GET ALL CLIENTS ************************************ */

export const requestClients = () => ({
    type: REQUEST_CLIENTS,
})
export const successClients = (clients) => ({
    type: SUCCESS_CLIENTS,
    payload: {
        clients: clients,
    },
})
export const failClients = () => ({
    type: FAIL_CLIENTS,
})
/* ************************************ SELECT CLIENT ************************************ */

export const requestSelectClient = (id) => {
    return {
        type: REQUEST_SELECT_CLIENT,
    }
}

export const successSelectClient = (client) => {
    return {
        type: SUCCESS_SELECT_CLIENT,
        payload: {
            client: client,
        },
    }
}

export const failSelectClient = () => {
    return {
        type: FAIL_SELECT_CLIENT,
    }
}
