/**
 * @module SDKModule
 * @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
 * @license MIT 2016 Jonathan Casarrubias
 * @version 2.1.0
 * @description
 * The SDKModule is a generated Software Development Kit automatically built by
 * the LoopBack SDK Builder open source module.
 *
 * The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
 * can import this Software Development Kit as follows:
 *
 *
 * APP Route Module Context
 * ============================================================================
 * import { NgModule }       from '@angular/core';
 * import { BrowserModule }  from '@angular/platform-browser';
 * // App Root
 * import { AppComponent }   from './app.component';
 * // Feature Modules
 * import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
 * // Import Routing
 * import { routing }        from './app.routing';
 * @NgModule({
 *  imports: [
 *    BrowserModule,
 *    routing,
 *    SDK[Browser|Node|Native]Module.forRoot()
 *  ],
 *  declarations: [ AppComponent ],
 *  bootstrap:    [ AppComponent ]
 * })
 * export class AppModule { }
 *
 **/

import React from 'react'
import * as Services from './services'

export class Component extends React.Component {
    models = {}

    constructor(config) {
        super()
        config.services.forEach((service) => {
            if (typeof this.services === 'object') this.services = {}
            this[service] = new Services[service]()
        })
    }
}

/**
 * Have Fun!!!
 * - Jon
 **/
export * from './models/index'
export * from './services/index'
export * from './lb.config'
export * from './storage/storage.swaps'
export { CookieBrowser } from './storage/cookie.browser'
export { StorageBrowser } from './storage/storage.browser'
