export class Trans_pag_terc {
    'tipo'
    'cota'
    'cgc_cpf_cliente'
    'cgc_cpf_favorecido'
    'data_liberacao'
    'versao'
    'consorciado'
    'favorecido'
    'valor'
    'grupo'
    'product'
    'data_type'
    'n_fav_popup'
    'id'

    constructor(data) {
        Object.assign(this, data)
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `Trans_pag_terc`.
     */
    static getModelName() {
        return 'Trans_pag_terc'
    }

    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of Trans_pag_terc for dynamic purposes.
     **/
    static factory(data) {
        return new Trans_pag_terc(data)
    }

    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    static getModelDefinition() {
        return {
            name: 'Trans_pag_terc',
            plural: 'Trans_pag_tercs',
            path: 'Trans_pag_tercs',
            properties: {
                'tipo': {
                    name: 'tipo',
                    type: 'string',
                },
                'cota': {
                    name: 'cota',
                    type: 'number',
                },
                'cgc_cpf_cliente': {
                    name: 'cgc_cpf_cliente',
                    type: 'number',
                },
                'cgc_cpf_favorecido': {
                    name: 'cgc_cpf_favorecido',
                    type: 'number',
                },
                'data_liberacao': {
                    name: 'data_liberacao',
                    type: 'Date',
                },
                'versao': {
                    name: 'versao',
                    type: 'number',
                },
                'consorciado': {
                    name: 'consorciado',
                    type: 'string',
                },
                'favorecido': {
                    name: 'favorecido',
                    type: 'string',
                },
                'valor': {
                    name: 'valor',
                    type: 'number',
                },
                'grupo': {
                    name: 'grupo',
                    type: 'number',
                },
                'product': {
                    name: 'product',
                    type: 'string',
                },
                'data_type': {
                    name: 'data_type',
                    type: 'string',
                },
                'n_fav_popup': {
                    name: 'n_fav_popup',
                    type: 'number',
                },
                'id': {
                    name: 'id',
                    type: 'any',
                },
            },
            relations: {},
        }
    }
}
