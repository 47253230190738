import EDDContent from 'pages/EDD'

export const Config = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: '/edd/:id',
            component: EDDContent,
        },
    ],
}
