//gte_10k

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { FiberManualRecord } from '@material-ui/icons'

const ValorPago10kFormatter = (props) => {
    let value = false
    // console.log(props.row)
    try {
        const entrada =
            props.row.gte_10k === undefined || props.row.gte_10k === null
                ? 0
                : props.row.gte_10k

        value = entrada
    } catch (err) {
        value = false
    }
    // console.log(value)

    return (
        <React.Fragment>
            { value ? (
                <FiberManualRecord style={ { color: 'red' } }/>
            ) : (
                <FiberManualRecord style={ { color: '#ced5da' } }/>
            ) }
        </React.Fragment>
    )
}

export const ValorPago10kProvider = (props) => (
    <DataTypeProvider formatterComponent={ ValorPago10kFormatter } { ...props } />
)
