import React from 'react'
import { ClientApi } from 'sdk'
import { InovaTable } from 'components'
import BreadCrumb from '../../components/BreadCrumb'
import { Equalizer } from '@material-ui/icons'
import { Card } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import SidebarFilters from './SidebarFilters'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FuseAnimate } from '@fuse'
import connect from 'react-redux/es/connect/connect'
import { withRouter } from 'react-router-dom'
import styles from './styles'

import { columns } from './newDatatable/columns'

const itens = [
    {
        title: 'Analytics',
        icon: <Equalizer />,
        route: '/',
    },
    {
        title: 'Dashboard',
        // icon: <Equalizer />,
        route: '/',
    },
]

const mapStateToProps = (state) => ({
    auth: state.authsReducer,
})
const mapDispatchToProps = () => ({})

class DashboardContent extends React.Component {
    state = {
        data: [],
        where: [],
        whereFilter: [],
        tab: 0,
        loading: true,
    }

    dataTable = React.createRef()

    componentDidMount() {
        this.onTab(null, 0)
    }

    onFilter = (where) => {
        console.log('filters', where)
        this.setState({ whereFilter: where })
        const { tab } = this.state
        where = [...where, ...this.checkUser(tab)]
        console.log('nWhere', where)
        this.setState({ where })
    }

    onTab = async (e, tab) => {
        const { whereFilter } = this.state
        await this.setState({
            tab,
            loading: false,
            where: [...whereFilter, ...this.checkUser(tab)],
        })
    }

    checkUser = (tab) => {
        const { auth } = this.props
        const level = auth.get('type')
        console.warn('xxxxxx', tab)
        switch (level) {
            case 'analista':
                switch (tab) {
                    case 0:
                        return [
                            {
                                id: 'step',
                                value: 'analista',
                            },
                            {
                                id: 'pending',
                                value: true,
                            },
                        ]
                        break
                    case 1:
                        return [
                            {
                                id: 'pending',
                                value: true,
                            },
                        ]
                        break
                    case 2:
                        return [
                            {
                                id: 'pending',
                                value: false,
                            },
                        ]
                        break
                }

                break

            case 'gerente':
                switch (tab) {
                    case 0:
                        return [
                            {
                                id: 'step',
                                value: 'gerente',
                            },
                            {
                                id: 'pending',
                                value: true,
                            },
                        ]
                        break
                    case 1:
                        return [
                            {
                                id: 'pending',
                                value: true,
                            },
                            {
                                id: 'status',
                                value: {
                                    neq: '',
                                },
                            },
                        ]
                        break
                    case 2:
                        return [
                            {
                                id: 'pending',
                                value: false,
                            },
                        ]
                        break
                }

                break

            case 'supergerente':
                switch (tab) {
                    case 0:
                        return [
                            {
                                id: 'step',
                                value: 'supergerente',
                            },
                            {
                                id: 'pending',
                                value: true,
                            },
                        ]
                        break
                    case 1:
                        return [
                            {
                                id: 'pending',
                                value: true,
                            },
                            {
                                id: 'status',
                                value: {
                                    neq: '',
                                },
                            },
                        ]
                        break
                    case 2:
                        return [
                            {
                                id: 'pending',
                                value: false,
                            },
                        ]
                        break
                }

                break
        }
    }

    render() {
        const { classes } = this.props
        const { where, tab, loading } = this.state
        return (
            <div>
                <div className={classes.cardSidebar}>
                    <SidebarFilters onFilter={this.onFilter} />
                </div>
                <Card className={classes.cardDashboard}>
                    <BreadCrumb itens={itens} separador="/" />
                    <AppBar position="static">
                        <Tabs
                            value={tab}
                            classes={{
                                root: classes.tabsRoot,
                                indicator: classes.tabsIndicator,
                            }}
                            onChange={this.onTab}
                        >
                            <Tab label="PENDENTE" />
                            {this.props.user === 'supergerente' ? null : (
                                <Tab label="EM ANDAMENTO" />
                            )}
                            <Tab label="FINALIZADO" />
                        </Tabs>
                    </AppBar>
                    {loading ? (
                        <div className={classes.root}>
                            <FuseAnimate
                                animation="transition.fadeIn"
                                delay={200}
                            >
                                <CircularProgress
                                    className={classes.progress}
                                />
                            </FuseAnimate>
                        </div>
                    ) : (
                        <InovaTable
                            ref={this.dataTable}
                            columns={columns}
                            api={ClientApi}
                            title={'Clientes - Acumulado dos últimos 6 meses'}
                            where={where}
                        />
                    )}
                </Card>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(withRouter(DashboardContent)))
