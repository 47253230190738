import { ClientApi, UserApi } from 'sdk'

// Classe que controla as requições da api.
class ApiService {
    api
    model
    // Login do usuário.
    static login = async (email, password) => {
        try {
            const res = await UserApi.login({ email, password })
            console.log('res', res)
            if (res) return res.data
        } catch (e) {
            return Promise.reject(e)
        }
    }

    // Retorna todos os clientes
    find = async () => {
        try {
            new ClientApi().find().subscribe(data => {
                console.log('data', data)
            })
        } catch (error) {
            console.log(error)
            return null
        }
    }

    // Retorna os dados do cliente
    getClient = async id => {
        try {
            const model = this.api.getModel('clients')
            return await model.findById(id)
        } catch (e) {
            console.log('error')
            return e
        }
    }

    /*   static postPDFData = (pdfData, type) => {
        return new Promise((resolve) => {

          const data = new FormData();
          data.append('file', pdfData);
          data.append('type', type);

          // console.log(pdfData, type);

          const config = {
            headers: {
              "Authorization": AUTH,
              // 'Access-Control-Allow-Origin': '*',
              "Content-type": "application/json",
            },
            onUploadProgress: function(progressEvent) {
              let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
              configureStore.dispatch(pdfActions.updateProgressBarUploadPdf(percentCompleted, pdfData.name));
            }
          }
          axios.post(POST_DATA_PDF, data, config).then(res => {
            // console.log(res.data);
            resolve(res.data);
          })
          .catch(erro => {
            console.log(erro);
            resolve(null);
          })
        });
      } */
} // Fim da classe.

export default ApiService
