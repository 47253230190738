import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FusePageSimple } from '@fuse'
import { connect } from 'react-redux'
import ClientesContent from './ClientesContent'

const styles = (theme) => ({
    layoutRoot: {},
    card: {
        minWidth: 275,
    },
})

class Index extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <FusePageSimple
                    classes={ {
                        contentCardWrapper: 'p-16 pr-0 pl-32 md:p-24',
                        leftSidebar: 'w-256',
                        header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
                    } }
                    content={ <ClientesContent/> }
                    sidebarInner={ true }
                    onRef={ (instance) => {
                        this.pageLayout = instance
                    } }
                    innerScroll={ false }
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    authsReducer: state.authsReducer,
})

export default connect(
    mapStateToProps,
    null,
)(withStyles(styles, { withTheme: true })(Index))
