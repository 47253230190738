import React from 'react'
import { Trans_pre_pag_conApi, Trans_pre_pag_rtApi } from 'sdk'
import { Card, withStyles } from '@material-ui/core'
import Widget1 from '../../components/widgets/Widget1'
import { widgets_data } from './widgets_data'
import Widget2 from '../../components/widgets/Widget2'
import moment from 'moment'

const styles = (theme) => ({
    cardGraph: {
        margin: ' 0 0 15px 0px',
    },
    card: {
        boxShadow: 'none',
        backgroundColor: theme.palette.bgtheme,
        paddingBottom: '15px',
    },
    cardSmall: {
        flex: '1',
        margin: '0 5px',
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: '2px 3px 5px rgba(0,0,0,0.2)',
    },
    cardFirst: {
        flex: '1',
        marginRight: '5px',
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: '2px 3px 5px rgba(0,0,0,0.2)',
    },
    cardLast: {
        flex: '1',
        marginLeft: '5px',
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: '2px 3px 5px rgba(0,0,0,0.2)',
    },
    cardDashboard: {},
})

class WidgetContent extends React.Component {
    state = {
        widget1: widgets_data.widget1,
        widget2: widgets_data.widget2,
        widget3: widgets_data.widget3,
        widget4: widgets_data.widget4,
        con: null,
        rt: null,
        total: '',
        months: 7,
    }

    componentDidMount() {
        Promise.all([this.loadCon(), this.loadRt()]).then((data) => {
            const consorcio = data[0]
            const retails = data[1]
            const { widget1, widget2, widget3, widget4 } = this.state

            widget1.labels = retails.map((i) => i.month)
            widget2.labels = retails.map((i) => i.month)
            widget3.labels = retails.map((i) => i.month)
            widget4.labels = retails.map((i) => i.month)

            // Widget1
            // Line Retail
            widget1.datasets[0].data = retails.map((i) => i.total)
            // Line Con
            widget1.datasets[1].data = consorcio.map((i) => i.total)

            // Widget 2 qtd rt
            widget2.datasets[0].data = consorcio.map((i) => i.count)

            // Widget 2 qtd rt
            widget3.datasets[0].data = retails.map((i) => i.count)

            // Widget 4
            widget4.datasets[0].data = retails.map((i, index) =>
                i.count ? i.count + consorcio[index].count : 0,
            )

            this.setState({ widget1, widget2, widget3, widget4 })
        })
    }

    loadRt = () => {
        return new Promise((resolve) => {
            const { gte_10k } = this.props
            new Trans_pre_pag_rtApi()
                .graphic(gte_10k, this.state.months)
                .subscribe(
                    (data) => {
                        if (data) {
                            const result = []
                            data.result.reverse().map((item) => {
                                const value = {
                                    month: moment(item.mes)
                                        .format('MMM')
                                        .toUpperCase(),
                                    count:
                                        item.data && item.data.count
                                            ? item.data.count
                                            : 0,
                                    total:
                                        item.data && item.data.total
                                            ? item.data.total
                                            : 0,
                                }
                                result.push(value)
                            })
                            resolve(result)
                        }
                    },
                    (error) => {
                        console.log('error', error)
                    },
                )
        })
    }

    loadCon = () => {
        return new Promise((resolve) => {
            const { gte_10k } = this.props
            new Trans_pre_pag_conApi()
                .graphic(gte_10k, this.state.months)
                .subscribe(
                    (data) => {
                        if (data) {
                            const result = []
                            data.result.reverse().map((item) => {
                                const value = {
                                    month: moment(item.mes)
                                        .format('MMM')
                                        .toUpperCase(),
                                    count:
                                        item.data && item.data.count
                                            ? item.data.count
                                            : 0,
                                    total:
                                        item.data && item.data.total
                                            ? item.data.total
                                            : 0,
                                }
                                result.push(value)
                            })
                            resolve(result)
                        }
                    },
                    (error) => {
                        console.log('error', error)
                    },
                )
        })
    }

    render() {
        const { classes } = this.props
        const { widget1, widget2, widget3, widget4 } = this.state
        return (
            <React.Fragment>
                <Card className={classes.cardGraph}>
                    <Widget1 data={widget1} />
                </Card>
                <Card className={classes.card}>
                    <div className="flex">
                        <Card className={classes.cardFirst}>
                            <Widget2
                                data={widget4}
                                title="Total de Pré Pags."
                            />
                        </Card>
                        <Card className={classes.cardSmall}>
                            <Widget2
                                data={widget2}
                                title="Quantidade de Pré Pags. de Consórcio"
                            />
                        </Card>
                        <Card className={classes.cardLast}>
                            <Widget2
                                data={widget3}
                                title="Quantidade de Pré Pags. de Financiamento"
                            />
                        </Card>
                    </div>
                </Card>
            </React.Fragment>
        )
    }
}

WidgetContent.defaultProps = {
    gte_10k: true,
}

// WidgetContent.propTypes = {
//     gte_10k: PropTypes.boolean.isRequire,
// }

export default withStyles(styles)(WidgetContent)
