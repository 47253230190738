/* tslint:disable */
import { BaseLoopBackApi } from '../core/base.service'
import { LoopBackConfig } from '../../lb.config'
import { LoopBackFilter } from '../../models/BaseModels'
import { Decision } from '../../models/Decision'

/**
 * Api services for the `Decision` model.
 */

export class DecisionApi extends BaseLoopBackApi {
    constructor() {
        super()
    }

    /**
     * Busca relação analista de belongsTo.
     *
     * @param {any} id decision id
     *
     * @param {boolean} refresh
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Decision` object.)
     * </em>
     */
    getAnalista(id, refresh, customHeaders) {
        let _method = 'GET'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/decisions/:id/analista'
        let _routeParams = {
            id: id,
        }
        let _postBody = {}
        let _urlParams = {}

        if (typeof refresh !== 'undefined' && refresh !== null)
            _urlParams.refresh = refresh
        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * Busca relação gerente de belongsTo.
     *
     * @param {any} id decision id
     *
     * @param {boolean} refresh
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Decision` object.)
     * </em>
     */
    getGerente(id, refresh, customHeaders) {
        let _method = 'GET'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/decisions/:id/gerente'
        let _routeParams = {
            id: id,
        }
        let _postBody = {}
        let _urlParams = {}

        if (typeof refresh !== 'undefined' && refresh !== null)
            _urlParams.refresh = refresh
        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * Busca relação supergerente de belongsTo.
     *
     * @param {any} id decision id
     *
     * @param {boolean} refresh
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Decision` object.)
     * </em>
     */
    getSupergerente(id, refresh, customHeaders) {
        let _method = 'GET'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/decisions/:id/supergerente'
        let _routeParams = {
            id: id,
        }
        let _postBody = {}
        let _urlParams = {}

        if (typeof refresh !== 'undefined' && refresh !== null)
            _urlParams.refresh = refresh
        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     *
     * @param {object} data Request data.
     *
     *  - `data` – `{object}` - Model instance data
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Decision` object.)
     * </em>
     */
    patchOrCreate(data, customHeaders) {
        let _method = 'PATCH'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/decisions'
        let _routeParams = {}
        let _postBody = {
            data: data,
        }
        let _urlParams = {}

        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     *
     * @param {any} id decision id
     *
     * @param {object} data Request data.
     *
     *  - `data` – `{object}` - An object of model property name/value pairs
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Decision` object.)
     * </em>
     */
    patchAttributes(id, data, customHeaders) {
        let _method = 'PATCH'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/decisions/:id'
        let _routeParams = {
            id: id,
        }
        let _postBody = {
            data: data,
        }
        let _urlParams = {}

        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Decision` object.)
     * </em>
     */
    paginate(query, customHeaders) {
        let _method = 'POST'
        let _url =
            LoopBackConfig.getPath() +
            '/' +
            LoopBackConfig.getApiVersion() +
            '/decisions/paginate'
        let _routeParams = {}
        let _postBody = {
            query: query,
        }
        let _urlParams = {}

        let result = this.request(
            _method,
            _url,
            _routeParams,
            _urlParams,
            _postBody,
            null,
            customHeaders,
        )
        return result
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `Decision`.
     */
    getModelName() {
        return 'Decision'
    }
}
