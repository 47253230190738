import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import styles from './styles'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/pt-br'
import { DecisionApi } from 'sdk'
import { historico_columns } from 'pages/clientDetails/datatable/historico_columns'
import { alineas } from 'pages/clientDetails/alineas'
import * as _ from 'lodash'
import Parser from 'html-react-parser'
import { Typography } from '@material-ui/core'
import { InovaTable } from '../../components/InovaTable'
import AlineasViewer from '../../components/AlineasViewer'

class InovaTimelineComponent extends Component {
    state = {
        filter: [
            {
                id: 'cpf_cnpj',
                value: this.props.cpf_cnpj,
            },
        ],
        total: {
            con: 0,
            rt: 0,
            historico: 0,
        },

        finSelected: [],
        conSelected: [],
        alineas: [],
        historico_data: false,
        decision: [],

        alineasView: null,

        open: false,
        data: [],
    }

    componentDidMount() {
        this.onDecision()
    }

    onDecision = async () => {
        try {
            const data = await new DecisionApi()
                .find({
                    where: { cpf_cnpj: this.props.cpf_cnpj },
                    order: 'id DESC',
                    include: ['analista', 'gerente', 'supergerente'],
                })
                .toPromise()
            console.log('=============> DATA', data)
            if (data) {
                let viewConsorcios = null
                let viewFinanciamentos = null
                data.map((decision) => {
                    if (decision.consorcios) {
                        viewConsorcios = decision.consorcios.map((row) =>
                            historico_columns.map((col) =>
                                !row[col.field] ? null : row[col.field],
                            ),
                        )
                    }
                })

                console.log('consorcios -------', viewConsorcios)

                data.map((decision) => {
                    if (data[0].financiamentos) {
                        viewFinanciamentos = data[0].financiamentos.map((row) =>
                            historico_columns.map((col) =>
                                !row[col.field] ? null : row[col.field],
                            ),
                        )
                    }
                })

                console.log('financiamentos -------', viewFinanciamentos)

                await data.map((decision) => {
                    this.alineasView(decision.alineas)
                })
                await this.setState({
                    decision: data,
                    viewConsorcios,
                    viewFinanciamentos,
                })

                console.log('---decisionTimeline', this.state.decision)
            }
        } catch (e) {
            console.log('erro', e)
        }
    }

    alineasView = (selected) => {
        if (!selected) return false
        let result = []
        alineas.map((pai) => {
            let nPai = { ...pai }
            nPai.itens = []
            pai.itens.map((i) =>
                _.some(selected, { id: i.id }) ? nPai.itens.push(i) : null,
            )
            result.push(nPai)
        })

        result = result.filter((i) => i.itens.length)
        return this.setState({ alineas: result })
    }

    renderTitleAnalista = (decision) => {
        if (decision) {
            if (decision.analista) return decision.analista.firstName
            return 'Analista'
        }
    }
    renderEmailAnalista = (decision) => {
        if (decision) {
            if (decision.analista) return decision.analista.email
            return null
        }
    }
    renderTitleGerente = (decision) => {
        if (decision) {
            if (decision.gerente) return decision.gerente.firstName
            return 'Gerente'
        }
    }
    renderEmailGerente = (decision) => {
        if (decision) {
            if (decision.gerente) return decision.gerente.email
            return null
        }
    }
    renderTitleSuper = (decision) => {
        if (decision) {
            if (decision.supergerente) return decision.supergerente.firstName
            return 'Supergerente'
        }
    }
    renderEmailSuper = (decision) => {
        if (decision) {
            if (decision.supergerente) return decision.supergerente.email
            return null
        }
    }

    renderEvent = (decision, index) => {
        const { classes, color } = this.props
        const { viewConsorcios, viewFinanciamentos } = this.state
        return (
            <li className={classes.eventItem} key={index}>
                <ul className={classes.event}>
                    <li
                        className={classes.dateEvent}
                        style={{ backgroundColor: this.renderColors(decision) }}
                    >
                        <span
                            className={classes.marker}
                            style={{ color: this.renderColors(decision) }}
                        >
                            •
                        </span>
                        <Moment
                            locale="pt-br"
                            format="DD[/]MM[/]YYYY[ às ]HH:mm"
                        >
                            {decision.analistaDate}
                        </Moment>
                    </li>
                    <li className={classes.titleEvent}>
                        <span>{this.renderTitleAnalista(decision)} - </span>
                        {this.renderEmailAnalista(decision)}
                    </li>
                    <li className={classes.subtitleEvent}>
                        <h4>
                            <strong>
                                {decision.status === 'black'
                                    ? 'Informações KYC'
                                    : 'Parecer:'}
                            </strong>
                        </h4>
                    </li>
                    <li>
                        {
                            <React.Fragment>
                                {Parser(decision.parecer_analista)}
                                {decision.status === 'black' ? (
                                    <React.Fragment>
                                        {viewConsorcios.length ? (
                                            <React.Fragment>
                                                <Typography
                                                    className={classes.titles}
                                                    variant="h6"
                                                >
                                                    Consórcios
                                                </Typography>
                                                <InovaTable
                                                    columns={historico_columns}
                                                    data={viewConsorcios}
                                                    serverSide={false}
                                                    pagination={false}
                                                    sort={false}
                                                    print={false}
                                                    viewColumns={false}
                                                    loading={false}
                                                />
                                            </React.Fragment>
                                        ) : null}

                                        {viewFinanciamentos.length ? (
                                            <React.Fragment>
                                                <Typography
                                                    className={classes.titles}
                                                    variant="h6"
                                                >
                                                    Varejo
                                                </Typography>
                                                <InovaTable
                                                    columns={historico_columns}
                                                    data={viewFinanciamentos}
                                                    serverSide={false}
                                                    pagination={false}
                                                    sort={false}
                                                    print={false}
                                                    viewColumns={false}
                                                    loading={false}
                                                />
                                            </React.Fragment>
                                        ) : null}

                                        <h4
                                            style={{
                                                marginTop: 15,
                                            }}
                                        >
                                            Alíneas Selecionadas
                                        </h4>
                                        <AlineasViewer
                                            alineas={this.state.alineas}
                                        />
                                    </React.Fragment>
                                ) : null}
                            </React.Fragment>
                        }
                    </li>
                </ul>
                {decision.gerenteId ? (
                    <ul className={classes.event}>
                        <li
                            className={classes.dateEvent}
                            style={{
                                backgroundColor: this.renderColors(decision),
                            }}
                        >
                            <span
                                className={classes.marker}
                                style={{ color: this.renderColors(decision) }}
                            >
                                •
                            </span>
                            <Moment
                                locale="pt-br"
                                format="DD[/]MM[/]YYYY[ às ]HH:mm"
                            >
                                {decision.gerenteDate}
                            </Moment>
                        </li>
                        <li className={classes.titleEvent}>
                            <span>{this.renderTitleGerente(decision)} - </span>
                            {this.renderEmailGerente(decision)}
                        </li>
                        <li className={classes.subtitleEvent}>
                            <h4>
                                <strong>
                                    {decision.parecer_gerente
                                        ? 'Parecer:'
                                        : null}
                                </strong>
                            </h4>
                        </li>
                        <li>{Parser(decision.parecer_gerente)}</li>
                    </ul>
                ) : null}

                {decision.supergerenteId ? (
                    <ul className={classes.event}>
                        <li
                            className={classes.dateEvent}
                            style={{
                                backgroundColor: this.renderColors(decision),
                            }}
                        >
                            <span
                                className={classes.marker}
                                style={{ color: this.renderColors(decision) }}
                            >
                                •
                            </span>
                            <Moment
                                locale="pt-br"
                                format="DD[/]MM[/]YYYY[ às ]HH:mm"
                            >
                                {decision.supergerenteDate}
                            </Moment>
                        </li>
                        <li className={classes.titleEvent}>
                            <span>{this.renderTitleSuper(decision)} - </span>
                            {this.renderEmailSuper(decision)}
                        </li>
                        <li className={classes.subtitleEvent}>
                            <h4>
                                <strong>
                                    {decision.parecer_supergerente
                                        ? 'Parecer:'
                                        : null}
                                </strong>
                            </h4>
                        </li>
                        <li>{Parser(decision.parecer_supergerente)}</li>
                    </ul>
                ) : null}
            </li>
        )
    }

    renderColors = (decision) => {
        if (decision.status === 'black') {
            return 'black'
        } else if (decision.status === 'gray') {
            return 'grey'
        }
        return '#a8f7a3'
    }

    render() {
        const { classes, color } = this.props
        const { decision } = this.state
        console.log('STATEEEEEEE', this.state)

        return (
            <ul
                className={classes.containerHistorico}
                style={{ borderColor: color }}
            >
                {decision.map((item, index) => this.renderEvent(item, index))}
            </ul>
        )
    }
}

export const Timeline = withStyles(styles, { withTheme: true })(
    InovaTimelineComponent,
)
