//row.v_gray_list

/* vazio/null -pendente - cor cinza claro
gray - cinza
black - preto
blackd - amarelo
blackg - laranja */

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { FiberManualRecord } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'

const StatusClientFormatter = (props) => {
    let value = 'pending'
    try {
        value = props.row.status
        if (value === undefined || value === null || value === '') {
            value = 'pending'
        }
    } catch (err) {
        value = 'pending'
    }

    let color = ''
    let tooltip = ''

    if (value === 'pending') {
        color = '#ced5da'
        tooltip = 'Pendente'
    } else if (value === 'ok') {
        color = '#a8f7a3'
        tooltip = 'Prosseguir'
    } else if (value === 'gray') {
        color = 'grey'
        tooltip = 'Gray List'
    } else if (value === 'black') {
        color = 'black'
        tooltip = 'Black List'
    } else if (value === 'blackd') {
        color = 'yellow'
        tooltip = 'Gerência'
    } else if (value === 'blackg') {
        color = 'orange'
        tooltip = 'Superintendência'
    }

    return (
        <React.Fragment>
            <Tooltip title={ tooltip }>
                <FiberManualRecord style={ { color: color } }/>
            </Tooltip>
            {/* <span>{props.row.status}</span> */ }
        </React.Fragment>
    )
}

export const StatusClientProvider = (props) => (
    <DataTypeProvider formatterComponent={ StatusClientFormatter } { ...props } />
)
