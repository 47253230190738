import React from 'react'
import { FuseAnimate } from '@fuse'
import { Button, Icon, Typography, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'

const styles = (theme) => ({
    root: {},
    icon: {
        color: 'white',
    },
    link: {
        marginRight: '30px',
        '&:hover': {
            textDecoration: 'none !important',
        },
    },
    backButton: { backgroundColor: 'white' },
    arrowIcon: {
        marginRight: '10px',
    },
})

const HeaderComponent = ({ classes, name }) => (
    <React.Fragment>
        <div className="flex items-center" style={{ margin: '0 20px' }}>
            <Link to="/clientes" className={classes.link}>
                <Button variant="contained" className={classes.backButton}>
                    <ArrowBack className={classes.arrowIcon} />
                    Voltar
                </Button>
            </Link>
            <FuseAnimate animation="transition.expandIn" delay={300}>
                <Icon
                    className={classNames(
                        classes.icon,
                        'text-32 mr-0 sm:mr-12',
                    )}
                >
                    person
                </Icon>
            </FuseAnimate>
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                <Typography className="hidden sm:flex" variant="h4">
                    {name}
                </Typography>
            </FuseAnimate>
        </div>
    </React.Fragment>
)

export default withStyles(styles)(HeaderComponent)
