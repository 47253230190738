import React, { Component } from 'react'
import { FuseAnimate, FuseThemes } from '@fuse'
import { MuiThemeProvider, Typography } from '@material-ui/core'
import { Bar } from 'react-chartjs-2'
import { withStyles } from '@material-ui/core/styles/index'
import _ from '@lodash'

require('velocity-animate')
require('velocity-animate/velocity.ui')

const styles = (theme) => ({
    root: {},
})

class Widget1 extends Component {
    render() {
        const { classes, data: dataRaw, theme } = this.props
        const data = _.merge({}, dataRaw)
        const dataWithColors = data.datasets.map((obj, index) => ({
            ...obj,
            borderColor: '#424242',
            backgroundColor:
                index === 0 ? '#969494' : theme.palette.secondary.contrastText,
            pointBackgroundColor: '#424242',
            pointHoverBackgroundColor: '#424242',
            pointBorderColor: theme.palette.secondary.contrastText,
            pointHoverBorderColor: theme.palette.secondary.contrastText,
        }))

        return (
            <MuiThemeProvider theme={FuseThemes.mainThemeDark}>
                <div
                    style={{ backgroundColor: theme.palette.primary.main }}
                    className={classes.root}
                >
                    <div className="relative p-16 sm:p-24 flex flex-row justify-between items-center">
                        <FuseAnimate delay={100}>
                            <div className="flex-col">
                                <Typography className="h2">
                                    Total Financeiro de Pré Pagamentos dos
                                    Últimos 6 Meses
                                </Typography>
                            </div>
                        </FuseAnimate>
                    </div>
                    <div className="relative h-200 sm:h-256 pb-16">
                        <Bar
                            data={{
                                labels: data.labels,
                                datasets: dataWithColors,
                            }}
                            options={data.options}
                        />
                    </div>
                </div>
            </MuiThemeProvider>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Widget1)
