import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import Parser from 'html-react-parser'
import Modal from 'react-responsive-modal'
import { Button } from '@material-ui/core'

class ParecerGerente extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
        }
    }

    onOpenModal = () => {
        this.setState({ open: true })
    }

    onCloseModal = () => {
        this.setState({ open: false })
    }

    render() {
        const { open } = this.state
        return (
            <React.Fragment>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={ this.onOpenModal }
                >
                    Ver parecer
                </Button>
                <Modal open={ open } onClose={ this.onCloseModal }>
                    <div
                        style={ {
                            padding: '30px',
                            minWidth: '200px',
                            minHeight: '200px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        } }
                    >
                        { Parser(this.props.row.parecer_gerente) }
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

export const ParecerGerenteProvider = (props) => (
    <DataTypeProvider formatterComponent={ ParecerGerente } { ...props } />
)
