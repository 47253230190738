export default (theme) => ({
    breadcrumb: {
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        padding: '10px',
        margin: '10px',
    },
    breadcrumb_item: {
        fontSize: '14px',
        color: theme.palette.primary.main,
    },
    icon: {
        position: 'relative',
        top: '5px',
        marginRight: '5px',
        color: theme.palette.primary.main,
    },
    lastIcon: {
        position: 'relative',
        top: '5px',
        marginRight: '5px',
        color: '#333',
    },
    title: {
        color: theme.palette.primary.main,
    },
    lastTitle: {
        color: '#333',
    },
    separador: {
        margin: '0 15px',
        color: theme.palette.primary.main,
    },
})
