import React from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles/index'
import styles from './styles'

const Index = ({ classes, itens, separador, desc }) => (
    <div className={ classes.breadcrumb }>
        <div style={ { flex: 3 } }>
            { itens.map((item, index) => {
                const lastTitle =
                    index + 1 === itens.length
                        ? classes.lastTitle
                        : classes.title
                const lastIcon =
                    index + 1 === itens.length ? classes.lastIcon : classes.icon
                return (
                    <Link
                        key={ item.title }
                        className={ classes.breadcrumb_item }
                        to={ item.route }
                    >
                        <span className={ lastIcon }>{ item.icon }</span>
                        <span className={ lastTitle }>{ item.title }</span>
                        { index + 1 !== itens.length ? (
                            <span className={ classes.separador }>
                                { separador }
                            </span>
                        ) : null }
                    </Link>
                )
            }) }
        </div>
        <div
            style={ {
                flex: 1,
                textAlign: 'right',
                position: 'relative',
                top: '5px',
            } }
        >
            { desc ? <span>{ desc }</span> : null }
        </div>
    </div>
)

export default withStyles(styles)(Index)
