import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Parser from 'html-react-parser'
import AlineasViewer from '../AlineasViewer.js'
import { InovaTable } from 'components'
import {
    consorcio_historico_columns,
    varejo_historico_columns,
} from './datatable/varejo_historico_columns'
import moment from 'moment'

const styles = (theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '40px 30px',
        backgroundColor: 'white',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 30,
    },
    section: {
        marginBottom: '20px',
        border: '1px solid grey',
        width: '100%',
    },
    sectionTitle: {
        textAlign: 'center',
        backgroundColor: 'grey',
        color: 'white',
        fontWeight: 'bold',
        padding: '5px',
        '-webkit-print-color-adjust': 'exact',
    },
    '@media print': {
        sectionTitle: {
            textAlign: 'center',
            backgroundColor: 'grey',
            color: 'white',
            fontWeight: 'bold',
            padding: '5px',
            '-webkit-print-color-adjust': 'exact',
        },
    },
    info: {
        display: 'flex',
        padding: 20,
        width: '100%',
    },
    infoTitles: {
        // border: '1px solid grey',
        paddingLeft: 0,
        backgroundColor: 'white',
        flexGrow: 1,
        minWidth: '100px',
    },
    infoData: {
        // border: '1px solid grey',
        paddingLeft: 0,
        backgroundColor: 'white',
        flexGrow: 3,
    },
    infoAlineas: {
        padding: 20,
    },
    infoLine: {
        listStyle: 'none',
        padding: '10px',
        border: '1px solid grey',
        borderLeft: '1px solid grey',
    },
    detalhamento: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    paragraph: {
        margin: '5px 0px',
    },
    h5: {
        textAlign: 'center',
        margin: '10px auto',
        fontSize: 20,
    },
    title: {
        textAlign: 'center',
        margin: '10px auto',
        fontSize: 15,
    },
    userReport: {
        marginTop: '20px',
    },
    root: {
        overflowX: 'auto',
    },
    table: {
        border: 'none',
    },
})

const EDDPage = ({
    client,
    decision,
    classes,
    alineas,
    consorcios,
    financiamentos,
}) => (
    <React.Fragment>
        <div className={classes.container}>
            <header className={classes.header}>
                <h2>EDD - RELATÓRIO DE ANÁLISE</h2>
                <img src="assets/images/logos/inova.svg" alt="Logo Inova" />
            </header>
            <div className={classes.section}>
                <h4 className={classes.sectionTitle}>INFORMAÇÕES CADASTRAIS</h4>
                <div className={classes.info} style={{ padding: 0 }}>
                    <ul className={classes.infoTitles}>
                        <li className={classes.infoLine}>
                            <strong>Nome</strong>
                        </li>
                        <li className={classes.infoLine}>
                            <strong>CPF</strong>
                        </li>

                        {client.birthday && (
                            <li className={classes.infoLine}>
                                <strong>Data de nascimento</strong>
                            </li>
                        )}
                        {client.marital_status && (
                            <li className={classes.infoLine}>
                                <strong>Estado Civil</strong>
                            </li>
                        )}
                        {client.profession_code && (
                            <li className={classes.infoLine}>
                                <strong>Profissão</strong>
                            </li>
                        )}
                        <li className={classes.infoLine}>
                            <strong>Renda</strong>
                        </li>
                        <li className={classes.infoLine}>
                            <strong>Endereço</strong>
                        </li>
                    </ul>
                    <ul className={classes.infoData}>
                        <li className={classes.infoLine}>{client.name}</li>
                        <li className={classes.infoLine}>
                            {client.cpf_f.replace(
                                /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                                '$1.$2.$3-$4',
                            )}
                        </li>
                        {client.birthday && (
                            <li className={classes.infoLine}>
                                client.birthday
                            </li>
                        )}
                        {client.marital_status && (
                            <li className={classes.infoLine}>marital_status</li>
                        )}
                        {client.profession_code && (
                            <li className={classes.infoLine}>
                                {client.profession_code}
                            </li>
                        )}
                        <li className={classes.infoLine}>
                            {client.monthly_income.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                            })}
                        </li>
                        <li className={classes.infoLine}>
                            {client.addr.street}, {client.addr.neighbor},{' '}
                            {client.addr.city} - {client.addr.uf}
                        </li>
                    </ul>
                </div>
            </div>
            <div className={classes.section} style={{ width: '100%' }}>
                <h4 className={classes.sectionTitle}>
                    DETALHAMENTO DA ATIPICIDADE E PARECER DO ANALISTA
                </h4>
                <div className={classes.info}>
                    <div className={classes.detalhamento}>
                        <p className={classes.paragraph}>
                            Cliente identificada durante o monitoramento de
                            operações de Pré Pagamentos por ter apresentado
                            comportamento atípico em:
                            {decision.consorcios.length > 0 && (
                                <span
                                    style={{
                                        margin: '0 5px',
                                        color: 'red',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {decision.consorcios.length} cotas de
                                    consórcio
                                </span>
                            )}
                            {decision.financiamentos.length > 0 && (
                                <span
                                    style={{
                                        margin: '0 5px',
                                        color: 'red',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    e {decision.financiamentos.length} de
                                    financiamento
                                </span>
                            )}
                            , nas quais se observou as seguintes características
                            comuns:
                        </p>
                        <h5 className={classes.h5}>DETALHAMENTO DAS COTAS</h5>
                        <div style={{ overflowX: 'scroll' }}>
                            {decision.consorcios.length ? (
                                <React.Fragment>
                                    <h4 className={classes.title}>
                                        Consórcios
                                    </h4>
                                    <InovaTable
                                        columns={consorcio_historico_columns}
                                        data={consorcios}
                                        serverSide={false}
                                        pagination={false}
                                        sort={false}
                                        print={false}
                                        viewColumns={false}
                                        loading={false}
                                    />
                                </React.Fragment>
                            ) : null}

                            {decision.financiamentos.length ? (
                                <React.Fragment>
                                    <h4 className={classes.title}>Varejo</h4>
                                    <InovaTable
                                        columns={varejo_historico_columns}
                                        data={financiamentos}
                                        serverSide={false}
                                        pagination={false}
                                        sort={false}
                                        print={false}
                                        viewColumns={false}
                                        loading={false}
                                        className={classes.table}
                                    />
                                </React.Fragment>
                            ) : null}
                        </div>
                        <h5 className={classes.h5}>INFORMAÇÕES DE KYC:</h5>
                        {Parser(decision.parecer_analista)}

                        <p className={classes.userReport}>
                            {decision.analista && (
                                <span>{decision.analista.firstName} </span>
                            )}
                            -{' '}
                            {moment(decision.analistaDate).format(
                                'DD/MM/YYYY - H:m',
                            )}
                        </p>
                    </div>
                </div>
            </div>
            <div className={classes.section} type="text">
                <h4 className={classes.sectionTitle}>ALINEAS COAF</h4>
                <div className={classes.infoAlineas}>
                    <AlineasViewer alineas={alineas} />
                </div>
            </div>

            <div className={classes.section} type="text">
                <h4 className={classes.sectionTitle}>PARECER DO GERENTE</h4>
                <div className={classes.info}>
                    <div className={classes.detalhamento}>
                        {Parser(decision.parecer_gerente)}
                        <p className={classes.userReport}>
                            {decision.gerente ? (
                                <span>{decision.gerente.firstName}</span>
                            ) : (
                                <span>Nome do gerente </span>
                            )}
                            -{' '}
                            {moment(decision.gerenteDate).format(
                                'DD/MM/YYYY - H:m',
                            )}
                        </p>
                    </div>
                </div>
            </div>
            <div className={classes.section} type="text">
                <h4 className={classes.sectionTitle}>
                    SUPERINTÊNDENCIA - APROVAÇÃO FINAL
                </h4>
                <div className={classes.info}>
                    <div className={classes.detalhamento}>
                        {Parser(decision.parecer_supergerente)}
                        <p className={classes.userReport}>
                            {decision.supergerente ? (
                                <span>{decision.supergerente.firstName}</span>
                            ) : (
                                <span>Nome do superintedente </span>
                            )}
                            -{' '}
                            {moment(decision.supergerenteDate).format(
                                'DD/MM/YYYY - H:m',
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
)

export default withStyles(styles)(EDDPage)
