import React from 'react'
import {
    CPF_CNPJ,
    HighRisk,
    Money,
    MonthlyIncome11x,
    PrePagConGte10k,
    PrePagRetGte10k,
    Status,
    Type,
} from 'components/InovaTable'

export const columns = [
    {
        name: 'CPF',
        field: 'cpf_cnpj',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <CPF_CNPJ value={value} />
                } catch (error) {
                    return false
                }
            },
        },
    },
    {
        name: 'Nome',
        field: 'name',
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'Tipo',
        field: 'type',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Type value={value} />
                } catch (error) {
                    return false
                }
            },
        },
    },
    {
        name: 'Status de Alertas',
        field: 'status',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Status value={value} />
                } catch (error) {
                    return false
                }
            },
        },
    },
    {
        field: 'highrisk',
        name: 'Alto Risco',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <HighRisk value={value} />
                } catch (error) {
                    return false
                }
            },
        },
    },
    {
        field: 'monthly_income',
        name: 'Renda',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Money value={value} />
                } catch (error) {
                    return false
                }
            },
        },
    },
    {
        field: 'monthly_income_final',
        name: 'Renda Presumida',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    return <Money value={value} />
                } catch (error) {
                    return false
                }
            },
        },
    },
    // // ALERTAS CLIENTE
    {
        field: 'v_prepag',
        name: 'Total Pré Pags.',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                try {
                    const prepag = value.total
                    return <Money value={prepag} />
                } catch (err) {
                    return false
                }
            },
        },
    },
    {
        field: 'n_pre_pag',
        name: 'Qtd Pré Pags.',
        options: {
            customBodyRender: (value) => {
                try {
                    const prepag = value.total
                    return <span>{prepag}</span>
                } catch (error) {
                    return false
                }
            },
        },
    },
    // {
    //     field: 'n_pre_pag.total/renda',
    //     name: '% Pré Pag / Renda',
    //     options: {
    //         customBodyRender: (value) => {
    //             try {
    //                 const total =
    //                     (parseFloat(value.v_prepag.total) /
    //                         parseFloat(value.monthly_income)) *
    //                     100
    //                 return total
    //             } catch (err) {
    //                 return false
    //             }
    //         },
    //     },
    // },
    {
        field: 'v_prepag',
        name: '11x Renda',
        options: {
            sort: false,
            customBodyRender: (value) => {
                try {
                    const prepag = value['11x_monthly_income']
                    return <MonthlyIncome11x value={prepag} />
                } catch (error) {
                    return false
                }
            },
        },
    },
    // // ALERTAS PRÉ PAG CONSORCIO
    {
        field: 'v_prepag',
        name: 'Pré Pags. Consórcio',
        options: {
            sort: false,
            customBodyRender: (value) => {
                try {
                    const prepag = value['consorcio']
                    return <Money value={prepag} />
                } catch (error) {
                    return false
                }
            },
        },
    },
    {
        field: 'n_pre_pag',
        name: 'Qtd. Cotas Consórcio',
        options: {
            sort: false,
            customBodyRender: (value) => {
                try {
                    const n = value['consorcio']
                    return <span>{n}</span>
                } catch (error) {
                    return false
                }
            },
        },
    },
    {
        field: 'gte_10k',
        name: 'Pré Pags. Consórcio >= 10k',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    const prepag = value.consorcio
                    return <PrePagConGte10k value={prepag} />
                } catch (error) {
                    return false
                }
            },
        },
    },
    // {
    //     field: 'n_pre_pag',
    //     name: 'Carta Cré/Pré Pag  Con',
    //     options: {
    //         customBodyRender: (value) => {
    //             try {
    //                 return value
    //             } catch (err) {
    //                 return false
    //             }
    //         },
    //     },
    // },
    // // ALERTAS FINANCIAMENTO
    {
        field: 'v_prepag',
        name: 'Pré Pags. Varejo',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    const prepag = value.retail
                    return <Money value={prepag} />
                } catch (error) {
                    return false
                }
            },
        },
    },
    {
        field: 'n_pre_pag',
        name: 'Qtd Pré Pags. Varejo',
        options: {
            sort: true,
            customBodyRender: (value) => {
                try {
                    const n = value['retail']
                    return <span>{n}</span>
                } catch (error) {
                    return false
                }
            },
        },
    },
    {
        field: 'gte_10k',
        name: 'Pré Pags. >= 10k Varejo',
        options: {
            sort: false,
            customBodyRender: (value) => {
                try {
                    const prepag = value.retail
                    return <PrePagRetGte10k value={prepag} />
                } catch (error) {
                    return false
                }
            },
        },
    },
]
