import React from 'react'
import { Card, Typography } from '@material-ui/core'
import { Bar } from 'react-chartjs-2'
import { withStyles } from '@material-ui/core/styles/index'
import classNames from 'classnames'

const styles = (theme) => ({
    root: {
        border: '1px solid ' + theme.palette.divider,
        boxShadow: 'none',
        borderRadius: 8,
    },
})

class Widget2 extends React.Component {
    total = (data) => {
        data =
            data && data.datasets && data.datasets[0].data.length
                ? data.datasets[0].data.reduce((a, b) => a + b)
                : 0
        return data.toLocaleString()
    }

    render() {
        const { classes, data, theme, title } = this.props
        const dataWithColors = data.datasets.map((obj) => ({
            ...obj,
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
        }))
        return (
            <Card className={classNames(classes.root, 'w-full')} elevation={1}>
                <div className="p-16 pb-0 flex flex-row flex-wrap items-end">
                    <div className="pr-16">
                        <Typography className="h4" color="textSecondary">
                            {title}
                        </Typography>
                        <Typography className="text-56 font-300 leading-none mt-8">
                            {this.total(data)}
                        </Typography>
                    </div>
                </div>

                <div className="h-96 w-100-p">
                    <Bar
                        data={{
                            labels: data.labels,
                            datasets: dataWithColors,
                        }}
                        options={{
                            maintainAspectRatio: false,
                        }}
                        legend={null}
                    />
                </div>
            </Card>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Widget2)
