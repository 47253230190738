/* tslint:disable */
import { BaseLoopBackApi } from '../core/base.service'
import { LoopBackConfig } from '../../lb.config'
import { LoopBackFilter } from '../../models/BaseModels'
import { Client } from '../../models/Client'


/**
 * Api services for the `Client` model.
 */

export class ClientApi extends BaseLoopBackApi {

    constructor() {

        super()

    }

    /**
     * Localize um item relacionado por ID para ativoCons.
     *
     * @param {any} id client id
     *
     * @param {any} fk Chave estrangeira para ativoCons
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Client` object.)
     * </em>
     */
    findByIdAtivoCons(id, fk, customHeaders) {

        let _method = 'GET'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/clients/:id/ativoCons/:fk'
        let _routeParams = {
            id: id,
            fk: fk,
        }
        let _postBody = {}
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Excluir um item relacionado por ID para ativoCons.
     *
     * @param {any} id client id
     *
     * @param {any} fk Chave estrangeira para ativoCons
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    destroyByIdAtivoCons(id, fk, customHeaders) {

        let _method = 'DELETE'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/clients/:id/ativoCons/:fk'
        let _routeParams = {
            id: id,
            fk: fk,
        }
        let _postBody = {}
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Atualizar um item relacionado por ID para ativoCons.
     *
     * @param {any} id client id
     *
     * @param {any} fk Chave estrangeira para ativoCons
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Client` object.)
     * </em>
     */
    updateByIdAtivoCons(id, fk, data, customHeaders) {

        let _method = 'PUT'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/clients/:id/ativoCons/:fk'
        let _routeParams = {
            id: id,
            fk: fk,
        }
        let _postBody = {
            data: data,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * ativoCons consultas de client.
     *
     * @param {any} id client id
     *
     * @param {object} filter
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Client` object.)
     * </em>
     */
    getAtivoCons(id, filter, customHeaders) {

        let _method = 'GET'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/clients/:id/ativoCons'
        let _routeParams = {
            id: id,
        }
        let _postBody = {}
        let _urlParams = {}


        if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Cria uma nova instância no ativoCons deste modelo.
     *
     * @param {any} id client id
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Client` object.)
     * </em>
     */
    createAtivoCons(id, data, customHeaders) {

        let _method = 'POST'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/clients/:id/ativoCons'
        let _routeParams = {
            id: id,
        }
        let _postBody = {
            data: data,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Exclui todos os ativoCons deste modelo.
     *
     * @param {any} id client id
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    deleteAtivoCons(id, customHeaders) {

        let _method = 'DELETE'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/clients/:id/ativoCons'
        let _routeParams = {
            id: id,
        }
        let _postBody = {}
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * ativoCons contagens de client.
     *
     * @param {any} id client id
     *
     * @param {object} where Criteria to match model instances
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * Data properties:
     *
     *  - `count` – `{number}` -
     */
    countAtivoCons(id, where, customHeaders) {

        let _method = 'GET'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/clients/:id/ativoCons/count'
        let _routeParams = {
            id: id,
        }
        let _postBody = {}
        let _urlParams = {}


        if (typeof where !== 'undefined' && where !== null) _urlParams.where = where
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     *
     * @param {object} data Request data.
     *
     *  - `data` – `{object}` - Model instance data
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Client` object.)
     * </em>
     */
    patchOrCreate(data, customHeaders) {

        let _method = 'PATCH'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/clients'
        let _routeParams = {}
        let _postBody = {
            data: data,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     *
     * @param {any} id client id
     *
     * @param {object} data Request data.
     *
     *  - `data` – `{object}` - An object of model property name/value pairs
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Client` object.)
     * </em>
     */
    patchAttributes(id, data, customHeaders) {

        let _method = 'PATCH'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/clients/:id'
        let _routeParams = {
            id: id,
        }
        let _postBody = {
            data: data,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Client` object.)
     * </em>
     */
    paginate(query, customHeaders) {

        let _method = 'POST'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/clients/paginate'
        let _routeParams = {}
        let _postBody = {
            query: query,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Cria uma nova instância no ativoCons deste modelo.
     *
     * @param {any} id client id
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Client` object.)
     * </em>
     */
    createManyAtivoCons(id, data, customHeaders) {

        let _method = 'POST'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/clients/:id/ativoCons'
        let _routeParams = {
            id: id,
        }
        let _postBody = {
            data: data,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }


    /**
     * The name of the model represented by this $resource,
     * i.e. `Client`.
     */
    getModelName() {
        return 'Client'
    }
}

