import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FusePageCarded } from '@fuse'
import HeaderComponent from './HeaderComponent'
import DetailsComponent from './DetailsComponent'
import { ClientApi } from 'sdk'
import { connect } from 'react-redux'

const styles = (theme) => ({
    layoutRoot: {},
})

class Index extends React.Component {
    state = {
        client: null,
    }

    componentWillMount() {
        const token = this.props.authsReducer.get('token')
        if (token === null) {
            this.props.history.push({
                pathname: '/',
            })
        }
    }

    componentDidMount() {
        const api = new ClientApi()
        const search = parseInt(this.props.match.params.cpfCnpj)
        api.findOne({ where: { cpf_cnpj: search } }).subscribe(
            (data) => {
                this.setState({ client: data })
            },
            (error) => {
                this.setState({ client: null })
            },
        )
    }

    render() {
        const { classes } = this.props
        const { client } = this.state
        const type = this.props.authsReducer.get('type')
        const user = this.props.authsReducer.get('id')
        return client ? (
            <FusePageCarded
                classes={{
                    root: classes.layoutRoot,
                }}
                header={<HeaderComponent name={client.name} />}
                content={
                    <DetailsComponent client={client} type={type} user={user} />
                }
            />
        ) : null
    }
}

const mapStateToProps = (state) => ({
    authsReducer: state.authsReducer,
})

export default connect(
    mapStateToProps,
    null,
)(withStyles(styles, { withTheme: true })(Index))
