//valor_credito

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import Services from '../../../../services/Services'

const ValueCreditFormatter = (props) => {
    const value = Services.maskMoney(props.row.valor_credito)
    return (
        <div>
            <span>{ value }</span>
        </div>
    )
}

export const ValueCreditProvider = props => (
    <DataTypeProvider
        formatterComponent={ ValueCreditFormatter }
        { ...props }
    />
)
