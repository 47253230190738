export const widgets_data = {
    widget1: {
        chartType: 'bar',
        datasets: [
            {
                label: 'Financiamentos',
                data: [],
                fill: 'start',
            },
            {
                label: 'Consórcios',
                data: [],
                fill: 'start',
            },
        ],
        labels: [],
        options: {
            tooltips: {
                enabled: true,
                mode: 'single',
                callbacks: {
                    label: (tooltipItem, data) => {
                        const label = data.labels[tooltipItem.index]
                        const datasetLabel =
                            data.datasets[tooltipItem.datasetIndex].data[
                                tooltipItem.index
                            ]
                        return `${label}: ${datasetLabel.toLocaleString(
                            'pt-BR',
                            { style: 'currency', currency: 'BRL' },
                        )}`
                    },
                },
            },
            spanGaps: false,
            legend: {
                display: true,
            },
            maintainAspectRatio: false,
            layout: {
                padding: {
                    top: 10,
                    left: 10,
                    right: 10,
                },
            },
            elements: {
                point: {
                    radius: 4,
                    borderWidth: 2,
                    hoverRadius: 4,
                    hoverBorderWidth: 2,
                },
                line: {
                    tension: 0,
                },
            },
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                            drawBorder: false,
                            tickMarkLength: 18,
                        },
                        ticks: {
                            fontColor: '#ffffff',
                        },
                    },
                ],
                yAxes: [
                    {
                        display: true,
                        ticks: {
                            beginAtZero: true,
                            callback: (value) => {
                                if (parseInt(value) >= 1000) {
                                    return `R$ ${value
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
                                } else {
                                    return `R$ ${value}`
                                }
                            },
                        },
                    },
                ],
            },
            plugins: {
                filler: {
                    propagate: true,
                },
                xLabelsOnTop: {
                    active: true,
                },
            },
        },
    },
    widget2: {
        conversion: {
            value: 0,
            ofTarget: 13,
        },
        chartType: 'bar',
        datasets: [
            {
                label: 'MÊS',
                data: [],
            },
        ],
        labels: [],
        options: {
            spanGaps: false,
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
            layout: {
                padding: {
                    top: 24,
                    left: 16,
                    right: 16,
                    bottom: 16,
                },
            },
            scales: {
                xAxes: [
                    {
                        display: false,
                    },
                ],
                yAxes: [
                    {
                        display: false,
                        // ticks: {
                        //   min: 100,
                        //   max: 500
                        // }
                    },
                ],
            },
        },
    },
    widget3: {
        conversion: {
            value: 0,
            ofTarget: 13,
        },
        chartType: 'bar',
        datasets: [
            {
                label: 'MÊS',
                data: [],
            },
        ],
        labels: [],
        options: {
            spanGaps: false,
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
            layout: {
                padding: {
                    top: 24,
                    left: 16,
                    right: 16,
                    bottom: 16,
                },
            },
            scales: {
                xAxes: [
                    {
                        display: false,
                    },
                ],
                yAxes: [
                    {
                        display: true,
                        // ticks: {
                        //   min: 100,
                        //   max: 500
                        // }
                    },
                ],
            },
        },
    },

    widget4: {
        conversion: {
            value: 0,
            ofTarget: 13,
        },
        chartType: 'bar',
        datasets: [
            {
                label: 'MÊS',
                data: [],
            },
        ],
        labels: [],
        options: {
            spanGaps: false,
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
            layout: {
                padding: {
                    top: 24,
                    left: 16,
                    right: 16,
                    bottom: 16,
                },
            },
            scales: {
                xAxes: [
                    {
                        display: false,
                    },
                ],
                yAxes: [
                    {
                        display: true,
                        // ticks: {
                        //   min: 100,
                        //   max: 500
                        // }
                    },
                ],
            },
        },
    },
    widget5: {
        chartType: 'line',
        datasets: {
            yesterday: [
                {
                    label: 'Visitors',
                    data: [
                        190,
                        300,
                        340,
                        220,
                        290,
                        390,
                        250,
                        380,
                        410,
                        380,
                        320,
                        290,
                    ],
                    fill: 'start',
                },
                {
                    label: 'Page views',
                    data: [
                        2200,
                        2900,
                        3900,
                        2500,
                        3800,
                        3200,
                        2900,
                        1900,
                        3000,
                        3400,
                        4100,
                        3800,
                    ],
                    fill: 'start',
                },
            ],
            today: [
                {
                    label: 'Visitors',
                    data: [
                        410,
                        380,
                        320,
                        290,
                        190,
                        390,
                        250,
                        380,
                        300,
                        340,
                        220,
                        290,
                    ],
                    fill: 'start',
                },
                {
                    label: 'Page Views',
                    data: [
                        3000,
                        3400,
                        4100,
                        3800,
                        2200,
                        3200,
                        2900,
                        1900,
                        2900,
                        3900,
                        2500,
                        3800,
                    ],
                    fill: 'start',
                },
            ],
        },
        labels: [
            '12am',
            '2am',
            '4am',
            '6am',
            '8am',
            '10am',
            '12pm',
            '2pm',
            '4pm',
            '6pm',
            '8pm',
            '10pm',
        ],
        options: {
            spanGaps: false,
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
            tooltips: {
                position: 'nearest',
                mode: 'index',
                intersect: false,
            },
            layout: {
                padding: {
                    left: 24,
                    right: 32,
                },
            },
            elements: {
                point: {
                    radius: 4,
                    borderWidth: 2,
                    hoverRadius: 4,
                    hoverBorderWidth: 2,
                },
            },
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            fontColor: 'rgba(0,0,0,0.54)',
                        },
                    },
                ],
                yAxes: [
                    {
                        gridLines: {
                            tickMarkLength: 16,
                        },
                        ticks: {
                            stepSize: 1000,
                        },
                    },
                ],
            },
            plugins: {
                filler: {
                    propagate: false,
                },
            },
        },
    },
}
