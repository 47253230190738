import { Ativo_con } from '../index'

export class Client {
    cpf_cnpj
    cpf_f
    name
    type
    nationality
    profession
    addr
    email
    phones
    mobile
    father_name
    sex
    matrial_status
    spouse_name
    n_ativos
    v_ativos
    n_pre_pag
    status
    step
    pending
    id
    ativoCons

    constructor(data) {
        Object.assign(this, data)
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `Client`.
     */
    static getModelName() {
        return 'Client'
    }

    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of Client for dynamic purposes.
     **/
    static factory(data) {
        return new Client(data)
    }

    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    static getModelDefinition() {
        return {
            name: 'Client',
            plural: 'Clients',
            path: 'Clients',
            properties: {
                cpf_cnpj: {
                    name: 'cpf_cnpj',
                    type: 'number',
                },
                cpf_f: {
                    name: 'cpf_f',
                    type: 'string',
                },
                name: {
                    name: 'name',
                    type: 'string',
                },
                type: {
                    name: 'type',
                    type: 'string',
                },
                nationality: {
                    name: 'nationality',
                    type: 'string',
                },
                profession: {
                    name: 'profession',
                    type: 'string',
                },
                addr: {
                    name: 'addr',
                    type: 'any',
                },
                email: {
                    name: 'email',
                    type: 'string',
                },
                phones: {
                    name: 'phones',
                    type: 'Array&lt;any&gt;',
                },
                mobile: {
                    name: 'mobile',
                    type: 'number',
                },
                father_name: {
                    name: 'father_name',
                    type: 'string',
                },
                sex: {
                    name: 'sex',
                    type: 'string',
                },
                matrial_status: {
                    name: 'matrial_status',
                    type: 'string',
                },
                spouse_name: {
                    name: 'spouse_name',
                    type: 'string',
                },
                n_ativos: {
                    name: 'n_ativos',
                    type: 'any',
                },
                v_ativos: {
                    name: 'v_ativos',
                    type: 'any',
                },
                n_pre_pag: {
                    name: 'n_pre_pag',
                    type: 'any',
                },
                status: {
                    name: 'status',
                    type: 'string',
                },
                step: {
                    name: 'step',
                    type: 'string',
                },
                pending: {
                    name: 'pending',
                    type: 'boolean',
                    default: true,
                },
                id: {
                    name: 'id',
                    type: 'any',
                },
            },
            relations: {
                ativoCons: {
                    name: 'ativoCons',
                    type: 'Ativo_con[]',
                    model: 'Ativo_con',
                },
            },
        }
    }
}
