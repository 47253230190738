import React, { Component } from 'react'
import { FuseAnimate, FusePageCarded } from '@fuse'
import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { isAbsolute } from 'upath'
import ProfileContent from './ProfileContent'

class Profile extends Component {
    state = {}

    componentWillMount() {
        const token = this.props.authsReducer.get('token')
        if (token === null) {
            this.props.history.push({
                pathname: '/',
            })
        }
    }

    render() {
        const { classes } = this.props
        return (
            <FusePageCarded
                header={
                    <div className="flex items-center justify-center">
                        <FuseAnimate animation="transition.slideLeftIn" delay={ 300 }>
                            <Typography className="mx-20 hidden sm:flex" variant="h4">
                                Atualizar Perfil
                            </Typography>
                        </FuseAnimate>
                    </div>
                }
                content={ <ProfileContent/> }
            />
        )
    }
}

const mapStateToProps = state => ({
    authsReducer: state.authsReducer,
})

export default connect(
    mapStateToProps,
    null,
)(Profile)
