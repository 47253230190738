export default (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
    cardGraph: {
        margin: ' 0 0 15px 0px',
    },
    card: {
        boxShadow: 'none',
        backgroundColor: theme.palette.bgtheme,
        marginBottom: '15px',
    },
    cardSmall: {
        flex: '1',
        margin: '0 15px',
    },
    cardFirst: {
        flex: '1',
        marginRight: '15px',
    },
    cardLast: {
        flex: '1',
        marginLeft: '15px',
    },
    cardSidebar: {
        paddinRigh: '15px',
        width: '255px',
        float: 'left',
        marginRight: '10px',
    },
    cardDashboard: {
        marginTop: '0px',
    },

    tabsRoot: {
        borderBottom: '1px solid #e8e8e8',
    },
    tabsIndicator: {
        backgroundColor: '#FFF',
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing.unit * 4,
        '&:hover': {
            color: '#FFF',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#FFF',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#FFF',
        },
    },
    tabSelected: {},
    // overrides: {
    //     MUIDataTableHeadCell: {
    //         root: {
    // '&:nth-child(1)': {
    //     width: 400,
    // },
    //         },
    //     },
    // },
})
