//row.v_prepag.total

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { FiberManualRecord } from '@material-ui/icons'

const MonthlyIncome11xFormatter = (props) => {
    let value = false
    // console.log(props.row)
    try {
        value =
            props.row.v_prepag['11x_monthly_income'] === undefined ||
            props.row.v_prepag['11x_monthly_income'] === null
                ? false
                : props.row.v_prepag['11x_monthly_income']
    } catch (err) {
        value = false
    }
    return (
        <React.Fragment>
            { value ? (
                <FiberManualRecord style={ { color: 'red' } }/>
            ) : (
                <FiberManualRecord style={ { color: '#ced5da' } }/>
            ) }
        </React.Fragment>
    )
}

export const MonthlyIncome11xProvider = (props) => (
    <DataTypeProvider
        formatterComponent={ MonthlyIncome11xFormatter }
        { ...props }
    />
)
