import { City } from '../index'


export class Country {
    'name'
    'fullname'
    'iso_3166_2'
    'iso_3166_3'
    'capital'
    'citizenship'
    'currency'
    'currency_code'
    'calling_code'
    'active'
    'archived'
    'created'
    'id'
    cities
    states

    constructor(data) {
        Object.assign(this, data)
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `Country`.
     */
    static getModelName() {
        return 'Country'
    }

    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of Country for dynamic purposes.
     **/
    static factory(data) {
        return new Country(data)
    }

    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    static getModelDefinition() {
        return {
            name: 'Country',
            plural: 'Countries',
            path: 'Countries',
            properties: {
                'name': {
                    name: 'name',
                    type: 'string',
                },
                'fullname': {
                    name: 'fullname',
                    type: 'string',
                },
                'iso_3166_2': {
                    name: 'iso_3166_2',
                    type: 'string',
                },
                'iso_3166_3': {
                    name: 'iso_3166_3',
                    type: 'string',
                },
                'capital': {
                    name: 'capital',
                    type: 'string',
                },
                'citizenship': {
                    name: 'citizenship',
                    type: 'string',
                },
                'currency': {
                    name: 'currency',
                    type: 'string',
                },
                'currency_code': {
                    name: 'currency_code',
                    type: 'string',
                },
                'calling_code': {
                    name: 'calling_code',
                    type: 'string',
                },
                'active': {
                    name: 'active',
                    type: 'boolean',
                    default: true,
                },
                'archived': {
                    name: 'archived',
                    type: 'boolean',
                    default: false,
                },
                'created': {
                    name: 'created',
                    type: 'Date',
                },
                'id': {
                    name: 'id',
                    type: 'any',
                },
            },
            relations: {
                cities: {
                    name: 'cities',
                    type: 'City[]',
                    model: 'City',
                },
                states: {
                    name: 'states',
                    type: 'State[]',
                    model: 'State',
                },
            },
        }
    }
}
