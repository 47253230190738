import * as React from 'react'
import Paper from '@material-ui/core/Paper/Paper'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import { client_card, client_coluns } from './data/client'
import * as _ from 'lodash'
import Services from 'services/Services'
import { FiberManualRecord } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'

export class CardClient extends React.PureComponent {
    columnName = (name) => {
        // console.log(client_coluns)
        const index = _.findIndex(client_coluns, { name })
        try {
            return client_coluns[index].title
        } catch (error) {
            return ''
        }
    }

    getValue = (name, row) => {
        // console.log(client_coluns)
        const index = _.findIndex(client_coluns, { name })
        try {
            //  console.log(client_coluns[index])
            return Services.insertMask(
                name,
                client_coluns[index].getCellValue(row),
            )
        } catch (error) {
            return ''
        }
    }

    // Renderiza a cell
    renderCell = (item, client) => {
        const columnName = item.columnName
        const value = this.getValue(columnName, client)

        if (columnName === 'status') {
            return this.renderStatus(value)
        } else if (typeof value === 'boolean') {
            if (value) {
                return <FiberManualRecord style={{ color: 'red' }} />
            } else {
                return <FiberManualRecord style={{ color: '#ced5da' }} />
            }
        }
        return value
    }

    renderStatus = (statusValue) => {
        let value = 'pending'
        try {
            value = statusValue
            if (
                value === undefined ||
                value === null ||
                value === '' ||
                value === 'highrisk'
            ) {
                value = 'pending'
            }
        } catch (err) {
            value = 'pending'
        }

        let color = ''
        let tooltip = ''

        if (value === 'pending') {
            color = '#ced5da'
            tooltip = 'Pendente'
        } else if (value === 'gray') {
            color = 'grey'
            tooltip = 'Gray List'
        } else if (value === 'black') {
            color = 'black'
            tooltip = 'Black List'
        } else if (value === 'blackd') {
            color = 'yellow'
            tooltip = 'Gerência'
        } else if (value === 'blackg') {
            color = 'orange'
            tooltip = 'Superintendência'
        }

        return (
            <React.Fragment>
                <Tooltip title={tooltip}>
                    <FiberManualRecord style={{ color: color }} />
                </Tooltip>
            </React.Fragment>
        )
    }

    renderCard = (client) => {
        // console.log(client)
        return client_card.map((item, key) => (
            <Paper key={key} style={{ margin: 5, flex: 1 }}>
                <Table>
                    <TableHead>
                        <TableRow component="tr" scope="row">
                            <TableCell
                                colSpan={2}
                                component="th"
                                style={{ fontSize: 16 }}
                            >
                                {item.title}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {item.children.map((item, key) => {
                            return (
                                <TableRow key={key}>
                                    <TableCell style={{ fontWeight: 'bold' }}>
                                        {this.columnName(item.columnName)}
                                    </TableCell>
                                    <TableCell>
                                        {this.renderCell(item, client)}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Paper>
        ))
    }

    render() {
        const { client } = this.props
        return (
            <div style={{ display: 'flex' }}>
                {client ? this.renderCard(client) : 'Carregando....'}
            </div>
        )
    }
}
