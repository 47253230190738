import { Map } from 'immutable'
import { FAIL_LOGIN, LOGIN, LOGOUT, SUCCESS_LOGIN } from './Auths.actions'

let authsInitial = Map({
    token: null,
    email: null,
    password: null,
    load: false,
    fail: null,
})

const authsReducer = (state = authsInitial, action) => {
    switch (action.type) {
        case LOGIN:
            state = state.set('load', true).set('fail', false)
            break

        case SUCCESS_LOGIN:
            state = state
                .set('id', action.payload.id)
                .set('firstName', action.payload.firstName)
                .set('email', action.payload.email)
                .set('type', action.payload.type)
                .set('token', action.payload.token)
                .set('load', false)
                .set('fail', false)
            break

        case FAIL_LOGIN:
            state = state.set('load', false).set('fail', true)
            break

        case LOGOUT:
            state = state
                .set('load', false)
                .set('fail', false)
                .set('email', null)
                .set('password', null)
                .set('token', null)
            break

        default:
            break
    }

    return state
} // Fim do reducers

export default authsReducer
