// v_ativos.total

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import Services from '../../../../services/Services'

const TotalAtivesFormatter = (props) => {
    const value = Services.maskMoney(props.row.v_ativos.total)
    return (
        <div>
            <span>{ value }</span>
        </div>
    )
}

export const TotalAtivesTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={ TotalAtivesFormatter }
        { ...props }
    />
)
