import { City, Country } from '../index'


export class State {
    'flyer'
    'uf'
    'name'
    'countryId'
    'id'
    'cityId'
    cities
    country

    constructor(data) {
        Object.assign(this, data)
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `State`.
     */
    static getModelName() {
        return 'State'
    }

    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of State for dynamic purposes.
     **/
    static factory(data) {
        return new State(data)
    }

    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    static getModelDefinition() {
        return {
            name: 'State',
            plural: 'States',
            path: 'States',
            properties: {
                'flyer': {
                    name: 'flyer',
                    type: 'any',
                },
                'uf': {
                    name: 'uf',
                    type: 'string',
                },
                'name': {
                    name: 'name',
                    type: 'string',
                },
                'countryId': {
                    name: 'countryId',
                    type: 'any',
                },
                'id': {
                    name: 'id',
                    type: 'any',
                },
                'cityId': {
                    name: 'cityId',
                    type: 'any',
                },
            },
            relations: {
                cities: {
                    name: 'cities',
                    type: 'City[]',
                    model: 'City',
                },
                country: {
                    name: 'country',
                    type: 'Country',
                    model: 'Country',
                },
            },
        }
    }
}
