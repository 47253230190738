export default (theme) => ({
    titles: {
        margin: '25px 0',
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        margin: theme.spacing.unit,
        marginTop: '30px',
        marginBottom: '30px',
        width: '33%',
        height: '50px',
        '&:hover': {
            backgroundColor: '#d3a210',
            color: theme.palette.secondary.contrastText + '!important',
        },
    },
    buttonSecondary: {
        margin: theme.spacing.unit,
        marginTop: '30px',
        marginBottom: '30px',
        width: '33%',
        height: '50px',
        backgroundColor: '#6D6D6D',
        color: '#FFF',
        '&:hover': {
            backgroundColor: '#424242',
            color: theme.palette.secondary.contrastText + '!important',
        },
    },
    buttonLarge: {
        width: '66%',
    },
    gray: {
        backgroundColor: 'grey',
        '&:hover': {
            backgroundColor: '#4c4c4c',
            color: theme.palette.secondary.contrastText + '!important',
        },
    },
    black: {
        backgroundColor: 'black',
        color: 'white',
        '&:hover': {
            backgroundColor: '#1c1b1b',
            color: theme.palette.secondary.contrastText + '!important',
        },
    },
    cardTable: {
        margin: '0 -15px',
        boxShadow: 'none',
    },
    formControl: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    linkEDD: {
        color: 'white !important',
        '&:hover': {
            color: 'white !important',
            textDecoration: 'none !important',
        },
    },
})
