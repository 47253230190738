export const navigation = [
    {
        id: 'analytics',
        title: 'Analytics',
        type: 'group',
        icon: 'equalizer',
        url: '/dashboard',
        children: [
            {
                id: 'analytics-dashboad',
                title: 'Dashboard',
                type: 'item',
                url: '/dashboard',
            },
            {
                id: 'clientes',
                title: 'Clientes',
                type: 'item',
                url: '/clientes',
            },
        ],
    },

    {
        id: 'config',
        title: 'Configuração',
        type: 'group',
        icon: 'settings_applications',
        url: '/configuracoes',
        children: [
            {
                id: 'imports',
                title: 'Importação',
                type: 'item',
                icon: 'import_export',
                url: '/imports',
            },
        ],
    },
]
