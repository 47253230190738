import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Avatar from '@material-ui/core/Avatar'
import { withStyles } from '@material-ui/core/styles'
import Person from '@material-ui/icons/Person'
import { Link } from 'react-router-dom'

const styles = (theme) => ({
    row: {
        display: 'flex',
        justifyContent: 'center',
    },
    wrapper: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        width: 60,
        height: 60,
    },
    link: {
        textDecoration: 'none',
        color: '#FFF',
    },
})

class MenuAvatar extends React.Component {
    state = {
        anchorEl: null,
    }

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
    }

    render() {
        const { anchorEl } = this.state
        const { classes, logout, name } = this.props

        return (
            <div className={ classes.wrapper }>
                <Button
                    aria-owns={ anchorEl ? 'simple-menu' : null }
                    aria-haspopup="true"
                    onClick={ this.handleClick }
                >
                    <Avatar className={ classes.avatar }>
                        <Person/>
                    </Avatar>
                    { name }
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={ anchorEl }
                    open={ Boolean(anchorEl) }
                    onClose={ this.handleClose }
                >
                    <MenuItem>
                        <Link className={ classes.link } to="/profile">
                            Perfil
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={ logout }>Logout</MenuItem>
                </Menu>
            </div>
        )
    }
}

export default withStyles(styles)(MenuAvatar)
