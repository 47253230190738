// n_pre_pag.total/renda

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const PercentPrePagRendaFormatter = (props) => {
    let total = 0
    try {
        const prePagTotal =
            isNaN(props.row.v_prepag.total) ? 0 : props.row.v_prepag.total
        const monthlyIncome =
            isNaN(props.row.monthly_income) ? 0 : props.row.monthly_income
        // console.log(prePagTotal, monthlyIncome);
        total = (parseFloat(prePagTotal) / parseFloat(monthlyIncome)) * 100
    } catch (err) {
        total = 0
    }
    const value = total.toFixed(2) + ''
    return (
        <div>
            {total === Infinity ? (
                <span />
            ) : (
                <span>{value.replace('.', ',')}</span>
            )}
        </div>
    )
}

export const PercentPrePagRendaProvider = (props) => (
    <DataTypeProvider
        formatterComponent={PercentPrePagRendaFormatter}
        {...props}
    />
)
