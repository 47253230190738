import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Card, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { DecisionApi, Trans_pre_pag_conApi, Trans_pre_pag_rtApi } from 'sdk'
import { InovaTable } from '../../components/InovaTable'
import { Trans_pre_pag_conApi_sort } from './datatable/consorcio_data'
import { historico_columns } from './datatable/historico_columns'
import { consorcio_columns } from './datatable/consorcios_columns'
import { CardClient } from 'components/CardClient'
import { Timeline } from 'components/InovaTimeline'
import {
    Trans_pre_pag_rtApi_columns,
    Trans_pre_pag_rtApi_sort,
} from './datatable/retail_data'
import CollapseTables from 'components/CollapseTables'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import HTMLEditor from 'components/HTMLEditor'
import AlineasViewer from '../../components/AlineasViewer'
import * as _ from 'lodash'
import { alineas } from './alineas'
import Parser from 'html-react-parser'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import 'sweetalert2/dist/sweetalert2.css'
import { withRouter } from 'react-router-dom'
import styles from './styles'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/pt-br'
import AlineasAccordion from 'components/AlineasAccordion'
import connect from 'react-redux/es/connect/connect'

const MySwal = withReactContent(Swal)

const mapStateToProps = (state) => ({
    auth: state.authsReducer,
})
const mapDispatchToProps = () => ({})

class DetailsComponent extends React.Component {
    state = {
        filter: [
            {
                id: 'cpf_cnpj',
                value: this.props.client.cpf_cnpj,
            },
        ],
        total: {
            con: 0,
            rt: 0,
            historico: 0,
        },

        finSelected: [],
        conSelected: [],
        alineas: [],
        historico_data: false,
        decision: {
            cpf_cnpj: this.props.client.cpf_cnpj,
            status: 'ok',
            step: 'analista',
            pending: false,
            analistaId: null,
            parecer_analista: '',
            gerenteId: null,
            parecer_gerente: '',
            supergerenteId: null,
            parecer_supergerente: '',
            alineas: [],
            consorcios: [],
            financiamentos: [],
            analista: {
                firstName: '',
            },
            gerente: {
                firstName: '',
            },
            supergerente: {
                firstName: '',
            },
        },

        editor: {
            analista: null,
            gerente: null,
            supergerente: null,
        },

        alineasView: null,

        open: false,
        data: [],
    }

    componentDidMount() {
        this.setState({
            decision: {
                ...this.state.decision,
                step: this.props.type,
            },
        })
        this.onAlienas()
    }

    onAlienas = async () => {
        try {
            const data = await new DecisionApi()
                .findOne({
                    where: { cpf_cnpj: this.props.client.cpf_cnpj },
                    order: 'id DESC',
                    include: ['analista', 'gerente', 'supergerente'],
                })
                .toPromise()

            console.log('------------data', data)
            if (data) {
                let viewConsorcios = null
                let viewFinanciamentos = null
                if (data.consorcios) {
                    viewConsorcios = data.consorcios.map((row) =>
                        historico_columns.map((col) =>
                            !row[col.field] ? null : row[col.field],
                        ),
                    )
                }
                if (data.financiamentos) {
                    viewFinanciamentos = data.financiamentos.map((row) =>
                        historico_columns.map((col) =>
                            !row[col.field] ? null : row[col.field],
                        ),
                    )
                }
                await this.alineasView(data.alineas)
                await this.setState({
                    decision: {
                        ...this.state.decision,
                        ...data,
                    },
                    viewConsorcios,
                    viewFinanciamentos,
                })

                console.log('---decision', this.state.decision)
            }
        } catch (e) {
            console.log('erro', e)
        }
    }

    alineasView = (selected) => {
        if (!selected) return false
        let result = []
        alineas.map((pai) => {
            let nPai = { ...pai }
            nPai.itens = []
            pai.itens.map((i) =>
                _.some(selected, { id: i.id }) ? nPai.itens.push(i) : null,
            )
            result.push(nPai)
        })

        result = result.filter((i) => i.itens.length)
        return this.setState({ alineas: result })
    }

    handleChangeStatus = (event) => {
        this.setState({
            decision: {
                ...this.state.decision,
                status: event.target.value,
            },
        })
    }

    renderHistorico = () => {
        const { classes } = this.props
        const { decision, viewConsorcios, viewFinanciamentos } = this.state
        console.log('render Historico', decision)
        return (
            <React.Fragment>
                <Typography className={classes.titles} variant="h4">
                    Histórico
                </Typography>
                <CollapseTables title={`Histórico `}>
                    <Timeline
                        color={this.renderColors()}
                        cpf_cnpj={this.props.client.cpf_cnpj}
                    />
                </CollapseTables>
            </React.Fragment>
        )
    }

    handleChangeCheckBox = (item) => {
        // console.log(item);
        const listChecks = [...this.state.decision.alineas]
        const exists = listChecks.find((check) => check.id === item.id)

        // Verifica se o item já está na lista, se sim remove, caso contrário remove.
        if (exists === undefined) {
            listChecks.push(item)
        } else {
            listChecks.forEach((check, i, listChecks) => {
                if (check.id === item.id) {
                    listChecks.splice(i, 1)
                }
            })
        }

        this.setState(
            {
                decision: {
                    ...this.state.decision,
                    alineas: [...listChecks],
                },
            },
            () => {
                console.log(this.state.decision)
            },
        )
    }

    // Pega os itens selecionados da datatable financiamentos
    selectedItemsRetail = (items) => {
        this.setState({ finSelected: [...items] }, () => {
            console.log(this.state.finSelected)
        })
    }

    // Pega os itens selecionados da datatable consórcio
    selectedItemsCons = (items) => {
        this.setState({ conSelected: [...items] }, () => {
            console.log(this.state.conSelected)
        })
    }

    onSubmit = async (back = false) => {
        let params = {
            ...this.state.decision,
        }

        delete params.id
        delete params.analista
        delete params.gerente
        delete params.supergerente

        // console.log('---STEP', params.step, step)
        console.log('-------params', this.refs.analista)

        switch (params.step) {
            case 'analista':
                params.analistaId = this.props.user
                params.step = 'gerente'
                params.pending = true
                params.analistaId = this.props.user
                params.consorcios = this.state.conSelected
                params.financiamentos = this.state.finSelected
                params.parecer_analista = this.refs.analista.state.text
                params.analistaDate = new Date()
                if (params.parecer_analista === '') {
                    return MySwal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text:
                            'É necessário digitar um parecer. Por favor, tente novamente.',
                    })
                }
                if (params.status === 'black' && params.alineas.length === 0) {
                    return MySwal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text:
                            'É necessário selecionar alguma alínea. Por favor, tente novamente.',
                    })
                }

                const total_select =
                    params.consorcios.length + params.financiamentos.length
                console.log(
                    'total_select',
                    total_select,
                    params.consorcios,
                    params.financiamentos,
                )
                if (params.status === 'black' && !total_select) {
                    return MySwal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text:
                            'É necessário selecionar um consórcio ou varejo. Por favor, tente novamente.',
                    })
                }
                break

            case 'gerente':
                params.step = back
                    ? 'analista'
                    : params.status === 'black'
                    ? 'supergerente'
                    : 'gerente'
                params.pending =
                    !back && params.status === 'black' ? true : back
                params.gerenteId = this.props.user
                params.parecer_gerente = this.refs.gerente.state.text
                params.gerenteDate = new Date()
                if (params.parecer_gerente === '') {
                    return MySwal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text:
                            'É necessário digitar um parecer. Por favor, tente novamente.',
                    })
                }
                break

            case 'supergerente':
                params.step = back ? 'gerente' : 'supergerente'
                params.pending = back
                params.supergerenteId = this.props.user
                params.parecer_supergerente = this.refs.supergerente.state.text
                params.supergerenteDate = new Date()
                if (params.parecer_supergerente === '') {
                    return MySwal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text:
                            'É necessário digitar um parecer. Por favor, tente novamente.',
                    })
                }
                break
        }
        if (this.refs.analista) {
            params.parecer_analista = this.refs.analista.state.text
        }
        if (this.refs.gerente) {
            params.parecer_gerente = this.refs.gerente.state.text
        }
        if (this.refs.supergerente) {
            params.parecer_supergerente = this.refs.supergerente.state.text
        }
        console.log('SUBMIT:', params)
        new DecisionApi().create(params).subscribe(
            (data) => {
                MySwal.fire({
                    type: 'success',
                    title: 'Parabéns',
                    text: 'Os dados foram enviados com sucesso.',
                })

                this.props.history.push({
                    pathname: '/clientes',
                })
            },
            (error) => {
                MySwal.fire({
                    type: 'error',
                    title: 'Oops...',
                    text:
                        'Houve um problema no envio dos dados. Por favor, tente novamente.',
                })
            },
        )
    }

    renderColors = () => {
        if (this.state.decision.status === 'black') {
            return 'black'
        } else if (this.state.decision.status === 'gray') {
            return 'grey'
        }
        return '#ced5da'
    }

    onViewEdd = () => {
        const { decision } = this.state
        window.open(`/edd/${decision.id}`, '_blank')
    }

    render() {
        const { classes, client, auth } = this.props
        const { filter, decision } = this.state

        const step = auth.get('type')
        const disabled = client.step !== step || !client.pending

        return (
            <React.Fragment>
                <div className="w-full flex flex-col">
                    <CardClient client={client} />
                </div>
                <Typography className={classes.titles} variant="h4">
                    Alertas Ativos
                </Typography>
                {client.n_pre_pag.consorcio ? (
                    <CollapseTables
                        title={`Consórcios (${client.n_pre_pag.consorcio}) `}
                    >
                        <Card className={classes.cardTable}>
                            <InovaTable
                                columns={consorcio_columns}
                                api={Trans_pre_pag_conApi}
                                sorting={Trans_pre_pag_conApi_sort}
                                where={filter}
                                selectableRows={
                                    !disabled && step === 'analista'
                                }
                                onSelect={(items) =>
                                    this.selectedItemsCons(items)
                                }
                            />
                        </Card>
                    </CollapseTables>
                ) : null}
                {client.n_pre_pag.retail ? (
                    <CollapseTables
                        title={`Varejo (${client.n_pre_pag.retail}) `}
                    >
                        <Card className={classes.cardTable}>
                            <InovaTable
                                columns={Trans_pre_pag_rtApi_columns}
                                api={Trans_pre_pag_rtApi}
                                sorting={Trans_pre_pag_rtApi_sort}
                                where={filter}
                                selectableRows={
                                    !disabled && step === 'analista'
                                }
                                onSelect={(items) =>
                                    this.selectedItemsRetail(items)
                                }
                            />
                        </Card>
                    </CollapseTables>
                ) : null}
                {this.renderHistorico()}
                <Typography className={classes.titles} variant="h4">
                    Decisão
                </Typography>
                <Card
                    className={classNames(
                        classes.root,
                        'flex flex-1 items-center justify-center p-8 sm:p-24 mb-24',
                    )}
                >
                    <FormControl
                        component="fieldset"
                        className={classes.formControl}
                    >
                        <RadioGroup
                            aria-label="Decisão"
                            value={this.state.decision.status}
                            onChange={this.handleChangeStatus}
                            className={classes.formControl}
                        >
                            <FormControlLabel
                                key={'ok'}
                                value="ok"
                                control={<Radio color="primary" />}
                                label="Prosseguir"
                                disabled={this.props.client.step !== 'analista'}
                            />
                            <FormControlLabel
                                key={'gray'}
                                value="gray"
                                control={<Radio color="primary" />}
                                label="Grey List"
                                disabled={this.props.client.step !== 'analista'}
                            />
                            <FormControlLabel
                                key={'black'}
                                value="black"
                                control={<Radio color="primary" />}
                                label="Black List"
                                disabled={this.props.client.step !== 'analista'}
                            />
                        </RadioGroup>
                    </FormControl>
                </Card>
                <Typography className={classes.titles} variant="h4">
                    {this.state.decision.status === 'black'
                        ? 'Informações KYC'
                        : 'Parecer Analista'}
                </Typography>
                <Card className={classNames(classes.root, ' p-8 sm:p-24')}>
                    {this.props.type === 'analista' && !disabled ? (
                        <HTMLEditor
                            status={this.state.decision.status}
                            ref="analista"
                            disabled={disabled}
                        />
                    ) : (
                        <div>
                            {Parser(this.state.decision.parecer_analista)}
                            {this.state.decision.analista ? (
                                <p>
                                    {this.state.decision.analista.firstName} -{' '}
                                    <Moment
                                        locale="pt-br"
                                        format="DD[/]MM[/]YYYY[ às ]HH:mm"
                                    >
                                        {this.state.decision.analistaDate}
                                    </Moment>
                                </p>
                            ) : null}
                        </div>
                    )}
                </Card>
                {this.state.decision.status === 'black' &&
                this.props.type === 'analista' &&
                !disabled ? (
                    <React.Fragment>
                        <Typography className={classes.titles} variant="h4">
                            Alíneas COAF
                        </Typography>
                        <AlineasAccordion
                            radioState={this.state.value}
                            changeCheckbox={this.handleChangeCheckBox}
                        />
                    </React.Fragment>
                ) : null}
                {this.state.decision.status === 'black' ? (
                    <React.Fragment>
                        <Typography className={classes.titles} variant="h4">
                            Alíneas selecionadas
                        </Typography>

                        <Card
                            className={classNames(classes.root, ' p-8 sm:p-24')}
                        >
                            <AlineasViewer alineas={this.state.alineas} />
                        </Card>
                    </React.Fragment>
                ) : null}
                {this.props.type === 'gerente' && decision.pending !== false ? (
                    <React.Fragment>
                        <Typography className={classes.titles} variant="h4">
                            Parecer Gerente
                        </Typography>

                        <Card
                            className={classNames(classes.root, ' p-8 sm:p-24')}
                        >
                            <HTMLEditor
                                status={this.state.decision.status}
                                ref="gerente"
                                disabled={disabled}
                            />
                        </Card>
                    </React.Fragment>
                ) : null}
                {this.props.type === 'supergerente' && (
                    <React.Fragment>
                        <Typography className={classes.titles} variant="h4">
                            Parecer Gerente
                        </Typography>

                        <Card
                            className={classNames(classes.root, ' p-8 sm:p-24')}
                        >
                            <div>
                                {Parser(this.state.decision.parecer_gerente)}
                            </div>
                        </Card>
                    </React.Fragment>
                )}
                {this.props.type === 'supergerente' && client.pending ? (
                    <React.Fragment>
                        <Typography className={classes.titles} variant="h4">
                            Parecer Superintendente
                        </Typography>

                        <Card
                            className={classNames(classes.root, ' p-8 sm:p-24')}
                        >
                            <HTMLEditor
                                status={this.state.decision.status}
                                ref="supergerente"
                                disabled={disabled}
                            />
                        </Card>
                    </React.Fragment>
                ) : null}

                {this.props.type === 'supergerente' &&
                    !client.pending &&
                    client.status === 'black' && (
                        <React.Fragment>
                            <Typography className={classes.titles} variant="h4">
                                Parecer Superintendente
                            </Typography>

                            <Card
                                className={classNames(
                                    classes.root,
                                    ' p-8 sm:p-24',
                                )}
                            >
                                <div>
                                    {Parser(
                                        this.state.decision
                                            .parecer_supergerente,
                                    )}
                                </div>
                            </Card>
                        </React.Fragment>
                    )}

                <div className="flex flex-1 items-center justify-center">
                    {this.state.decision.status === 'black' ? (
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => this.onViewEdd()}
                        >
                            Visualizar EDD
                        </Button>
                    ) : null}

                    {this.props.type !== 'analista' ? (
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.buttonSecondary}
                            onClick={() => this.onSubmit(true)}
                            disabled={disabled}
                        >
                            Devolver
                        </Button>
                    ) : null}
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => this.onSubmit(false)}
                        disabled={disabled}
                    >
                        Submeter
                    </Button>
                </div>
            </React.Fragment>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(withRouter(DetailsComponent)))
