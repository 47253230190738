/* tslint:disable */
import { BaseLoopBackApi } from '../core/base.service'
import { LoopBackConfig } from '../../lb.config'
import { LoopBackFilter } from '../../models/BaseModels'
import { Country } from '../../models/Country'


/**
 * Api services for the `Country` model.
 */

export class CountryApi extends BaseLoopBackApi {

    constructor() {

        super()

    }

    /**
     * Localize um item relacionado por ID para cities.
     *
     * @param {any} id Country id
     *
     * @param {any} fk Chave estrangeira para cities
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Country` object.)
     * </em>
     */
    findByIdCities(id, fk, customHeaders) {

        let _method = 'GET'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/:id/cities/:fk'
        let _routeParams = {
            id: id,
            fk: fk,
        }
        let _postBody = {}
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Excluir um item relacionado por ID para cities.
     *
     * @param {any} id Country id
     *
     * @param {any} fk Chave estrangeira para cities
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    destroyByIdCities(id, fk, customHeaders) {

        let _method = 'DELETE'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/:id/cities/:fk'
        let _routeParams = {
            id: id,
            fk: fk,
        }
        let _postBody = {}
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Atualizar um item relacionado por ID para cities.
     *
     * @param {any} id Country id
     *
     * @param {any} fk Chave estrangeira para cities
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Country` object.)
     * </em>
     */
    updateByIdCities(id, fk, data, customHeaders) {

        let _method = 'PUT'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/:id/cities/:fk'
        let _routeParams = {
            id: id,
            fk: fk,
        }
        let _postBody = {
            data: data,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Localize um item relacionado por ID para states.
     *
     * @param {any} id Country id
     *
     * @param {any} fk Chave estrangeira para states
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Country` object.)
     * </em>
     */
    findByIdStates(id, fk, customHeaders) {

        let _method = 'GET'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/:id/states/:fk'
        let _routeParams = {
            id: id,
            fk: fk,
        }
        let _postBody = {}
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Excluir um item relacionado por ID para states.
     *
     * @param {any} id Country id
     *
     * @param {any} fk Chave estrangeira para states
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    destroyByIdStates(id, fk, customHeaders) {

        let _method = 'DELETE'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/:id/states/:fk'
        let _routeParams = {
            id: id,
            fk: fk,
        }
        let _postBody = {}
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Atualizar um item relacionado por ID para states.
     *
     * @param {any} id Country id
     *
     * @param {any} fk Chave estrangeira para states
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Country` object.)
     * </em>
     */
    updateByIdStates(id, fk, data, customHeaders) {

        let _method = 'PUT'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/:id/states/:fk'
        let _routeParams = {
            id: id,
            fk: fk,
        }
        let _postBody = {
            data: data,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * cities consultas de Country.
     *
     * @param {any} id Country id
     *
     * @param {object} filter
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Country` object.)
     * </em>
     */
    getCities(id, filter, customHeaders) {

        let _method = 'GET'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/:id/cities'
        let _routeParams = {
            id: id,
        }
        let _postBody = {}
        let _urlParams = {}


        if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Cria uma nova instância no cities deste modelo.
     *
     * @param {any} id Country id
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Country` object.)
     * </em>
     */
    createCities(id, data, customHeaders) {

        let _method = 'POST'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/:id/cities'
        let _routeParams = {
            id: id,
        }
        let _postBody = {
            data: data,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Exclui todos os cities deste modelo.
     *
     * @param {any} id Country id
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    deleteCities(id, customHeaders) {

        let _method = 'DELETE'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/:id/cities'
        let _routeParams = {
            id: id,
        }
        let _postBody = {}
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * cities contagens de Country.
     *
     * @param {any} id Country id
     *
     * @param {object} where Criteria to match model instances
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * Data properties:
     *
     *  - `count` – `{number}` -
     */
    countCities(id, where, customHeaders) {

        let _method = 'GET'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/:id/cities/count'
        let _routeParams = {
            id: id,
        }
        let _postBody = {}
        let _urlParams = {}


        if (typeof where !== 'undefined' && where !== null) _urlParams.where = where
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * states consultas de Country.
     *
     * @param {any} id Country id
     *
     * @param {object} filter
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Country` object.)
     * </em>
     */
    getStates(id, filter, customHeaders) {

        let _method = 'GET'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/:id/states'
        let _routeParams = {
            id: id,
        }
        let _postBody = {}
        let _urlParams = {}


        if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Cria uma nova instância no states deste modelo.
     *
     * @param {any} id Country id
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Country` object.)
     * </em>
     */
    createStates(id, data, customHeaders) {

        let _method = 'POST'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/:id/states'
        let _routeParams = {
            id: id,
        }
        let _postBody = {
            data: data,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Exclui todos os states deste modelo.
     *
     * @param {any} id Country id
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    deleteStates(id, customHeaders) {

        let _method = 'DELETE'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/:id/states'
        let _routeParams = {
            id: id,
        }
        let _postBody = {}
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * states contagens de Country.
     *
     * @param {any} id Country id
     *
     * @param {object} where Criteria to match model instances
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * Data properties:
     *
     *  - `count` – `{number}` -
     */
    countStates(id, where, customHeaders) {

        let _method = 'GET'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/:id/states/count'
        let _routeParams = {
            id: id,
        }
        let _postBody = {}
        let _urlParams = {}


        if (typeof where !== 'undefined' && where !== null) _urlParams.where = where
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     *
     * @param {object} data Request data.
     *
     *  - `data` – `{object}` - Model instance data
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Country` object.)
     * </em>
     */
    patchOrCreate(data, customHeaders) {

        let _method = 'PATCH'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries'
        let _routeParams = {}
        let _postBody = {
            data: data,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     *
     * @param {any} id Country id
     *
     * @param {object} data Request data.
     *
     *  - `data` – `{object}` - An object of model property name/value pairs
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Country` object.)
     * </em>
     */
    patchAttributes(id, data, customHeaders) {

        let _method = 'PATCH'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/:id'
        let _routeParams = {
            id: id,
        }
        let _postBody = {
            data: data,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Country` object.)
     * </em>
     */
    paginate(query, customHeaders) {

        let _method = 'POST'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/paginate'
        let _routeParams = {}
        let _postBody = {
            query: query,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Cria uma nova instância no cities deste modelo.
     *
     * @param {any} id Country id
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Country` object.)
     * </em>
     */
    createManyCities(id, data, customHeaders) {

        let _method = 'POST'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/:id/cities'
        let _routeParams = {
            id: id,
        }
        let _postBody = {
            data: data,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Cria uma nova instância no states deste modelo.
     *
     * @param {any} id Country id
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Country` object.)
     * </em>
     */
    createManyStates(id, data, customHeaders) {

        let _method = 'POST'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/Countries/:id/states'
        let _routeParams = {
            id: id,
        }
        let _postBody = {
            data: data,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }


    /**
     * The name of the model represented by this $resource,
     * i.e. `Country`.
     */
    getModelName() {
        return 'Country'
    }
}

