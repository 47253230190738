//props.row.highrisk;

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { FiberManualRecord } from '@material-ui/icons'

const HighRiskFormatter = (props) => {
    let value = false
    try {
        value =
            props.row.highrisk === undefined || props.row.highrisk === null
                ? false
                : props.row.highrisk
    } catch (err) {
        value = false
    }
    return (
        <React.Fragment>
            { value ? (
                <FiberManualRecord style={ { color: 'red' } }/>
            ) : (
                <FiberManualRecord style={ { color: '#ced5da' } }/>
            ) }
        </React.Fragment>
    )
}

export const HighRiskProvider = (props) => (
    <DataTypeProvider formatterComponent={ HighRiskFormatter } { ...props } />
)
