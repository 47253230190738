import { User } from '../../models/User'
import { Client } from '../../models/Client'
import { Ativo_con } from '../../models/Ativo_con'
import { Ativo_rt } from '../../models/Ativo_rt'
import { Trans_pag_terc } from '../../models/Trans_pag_terc'
import { Trans_pre_pag_con } from '../../models/Trans_pre_pag_con'
import { Trans_pre_pag_rt } from '../../models/Trans_pre_pag_rt'
import { City } from '../../models/City'
import { Country } from '../../models/Country'
import { State } from '../../models/State'
import { Decision } from '../../models/Decision'

export class SDKModels {
    models = {
        User: User,
        Client: Client,
        Ativo_con: Ativo_con,
        Ativo_rt: Ativo_rt,
        Trans_pag_terc: Trans_pag_terc,
        Trans_pre_pag_con: Trans_pre_pag_con,
        Trans_pre_pag_rt: Trans_pre_pag_rt,
        City: City,
        Country: Country,
        State: State,
        Decision: Decision,
    }

    get(modelName) {
        return this.models[modelName]
    }

    getAll() {
        return this.models
    }

    getModelNames() {
        return Object.keys(this.models)
    }
}
