import React from 'react'
import { FiberManualRecord } from '@material-ui/icons'

export const PrePagRetGte10k = ({ value }) => (
    <React.Fragment>
        { value ? (
            <FiberManualRecord style={ { color: 'red' } }/>
        ) : (
            <FiberManualRecord style={ { color: '#ced5da' } }/>
        ) }
    </React.Fragment>
)
