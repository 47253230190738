const LIMIT = 10000

export const client_card = [
    {
        title: 'CLIENTE',
        children: [
            { columnName: 'cpf_f' },
            { columnName: 'name' },
            { columnName: 'type' },
            { columnName: 'monthly_income' },
            { columnName: 'monthly_income_final' },
            { columnName: 'highrisk' },
            { columnName: 'status' },
            { columnName: 'profession' },
            { columnName: 'addr_street' },
            { columnName: 'addr_neighbor' },
            { columnName: 'addr_city' },
            { columnName: 'addr_uf' },
            { columnName: 'addr_zipcode' },
        ],
    },
    {
        title: 'VISÃO GERAL PRÉ PAGAMENTO CONSÓRCIO',
        children: [
            { columnName: 'v_prepag.consorcio' },
            { columnName: 'n_pre_pag.consorcio' },
            { columnName: 'n_pre_pag.consorcio_10k' },
            { columnName: 'n_pre_pag.consorcio_carta' },
        ],
    },
    {
        title: 'VISÃO GERAL PRÉ PAGAMENTO VAREJO',
        children: [
            { columnName: 'v_prepag.retail' },
            { columnName: 'n_pre_pag.retail' },
            { columnName: 'n_pre_pag.retail_10k' },
        ],
    },
    {
        title: 'VISÃO GERAL DO CLIENTE',
        children: [
            { columnName: 'v_prepag.total' },
            { columnName: 'n_pre_pag.total' },
            { columnName: 'n_pre_pag.total/renda' },
            { columnName: '11x_monthly_income' },
        ],
    },
]
export const client_coluns = [
    // CLIENTE
    {
        name: 'cpf_f',
        title: 'CPF/CNPJ',
        getCellValue: (row) => {
            try {
                return row.cpf_f
            } catch (err) {
                return ''
            }
        },
    },
    {
        name: 'name',
        title: 'Nome',
        getCellValue: (row) => {
            try {
                return row.name
            } catch (err) {
                return ''
            }
        },
    },
    {
        name: 'type',
        title: 'Tipo',
        getCellValue: (row) => {
            try {
                return row.type
            } catch (err) {
                return ''
            }
        },
    },
    {
        name: 'highrisk',
        title: 'High Risk',
        getCellValue: (row) => {
            try {
                return row.highrisk === undefined || row.highrisk === null
                    ? false
                    : row.highrisk
            } catch (err) {
                return false
            }
        },
    },
    {
        name: 'status',
        title: 'Status',
        getCellValue: (row) => {
            try {
                console.log(row)
                return row.status === undefined || row.status === null
                    ? false
                    : row.status
            } catch (err) {
                return false
            }
        },
    },
    {
        name: 'profession',
        title: 'Profissão',
        getCellValue: (row) => {
            try {
                return row.profession
            } catch (err) {
                return ''
            }
        },
    },
    {
        name: 'monthly_income',
        title: 'Renda',
        getCellValue: (row) => {
            try {
                return row.monthly_income
            } catch (err) {
                return 0
            }
        },
    },
    {
        name: 'monthly_income_final',
        title: 'Renda Estimada',
        getCellValue: (row) => {
            try {
                return row.monthly_income_final
            } catch (err) {
                return 0
            }
        },
    },
    {
        name: 'addr_street',
        title: 'Rua',
        getCellValue: (row) => {
            try {
                return row.addr.street
            } catch (err) {
                return ''
            }
        },
    },
    {
        name: 'addr_neighbor',
        title: 'Bairro',
        getCellValue: (row) => {
            try {
                return row.addr.neighbor
            } catch (err) {
                return ''
            }
        },
    },
    {
        name: 'addr_city',
        title: 'Cidade',
        getCellValue: (row) => {
            try {
                return row.addr.city
            } catch (err) {
                return ''
            }
        },
    },
    {
        name: 'addr_uf',
        title: 'Estado',
        getCellValue: (row) => {
            try {
                return row.addr.uf
            } catch (err) {
                return ''
            }
        },
    },
    {
        name: 'addr_zipcode',
        title: 'CEP',
        getCellValue: (row) => {
            try {
                return row.addr.zipcode
            } catch (err) {
                return ''
            }
        },
    },
    // ALERTAS CLIENTE
    {
        name: 'v_prepag.total',
        title: 'Total Pré Pags',
        getCellValue: (row) => {
            try {
                return row.v_prepag.total
            } catch (err) {
                return 0
            }
        },
    },
    {
        name: 'n_pre_pag.total',
        title: 'Qtd Pré Pags',
        getCellValue: (row) => {
            try {
                return row.n_pre_pag.total
            } catch (err) {
                return 0
            }
        },
    },
    {
        name: 'n_pre_pag.total/renda',
        title: '% Pré Pags / Renda',
        getCellValue: (row) => {
            try {
                const total =
                    (parseFloat(row.v_prepag.total) /
                        parseFloat(row.monthly_income)) *
                    100

                const value = total === Infinity ? '' : total.toFixed(2) + ''
                return value.replace('.', ',')
            } catch (err) {
                return 0
            }
        },
    },
    // {
    //   name: "v_prepag.gte_10k",
    //   title: "Pre/Pag > 10.000",
    //   getCellValue: row => {
    //     try {
    //       return row.v_prepag.gte_10k === false ? "Não" : "Sim";
    //     } catch (err) {
    //       return "Não";
    //     }
    //   }
    // },
    {
        name: '11x_monthly_income',
        title: '11x Renda',
        getCellValue: (row) => {
            // console.log(row);
            try {
                return row.v_prepag['11x_monthly_income'] === undefined ||
                    row.v_prepag['11x_monthly_income'] === null
                    ? false
                    : row.v_prepag['11x_monthly_income']
            } catch (err) {
                return false
            }
        },
    },
    // ALERTAS PRÉ PAG CONSORCIO
    {
        name: 'v_prepag.consorcio',
        title: 'Total Pré Pags. Con',
        getCellValue: (row) => {
            try {
                return row.v_prepag.consorcio
            } catch (err) {
                return 0
            }
        },
    },
    {
        name: 'n_ativos.consorcio',
        title: 'Qtd Pré Pags Con',
        getCellValue: (row) => {
            try {
                return row.n_ativos.consorcio
            } catch (err) {
                return 0
            }
        },
    },
    {
        name: 'n_pre_pag.consorcio',
        title: 'Qtd. Cotas Con',
        getCellValue: (row) => {
            try {
                return row.n_pre_pag.consorcio
            } catch (err) {
                return 0
            }
        },
    },
    {
        name: 'n_pre_pag.consorcio_10k',
        title: 'Pré Pag. >= 10k Con',
        getCellValue: (row) => {
            try {
                const value =
                    row.v_prepag.consorcio === undefined ||
                    row.v_prepag.consorcio === null
                        ? 0
                        : row.v_prepag.consorcio
                return value > LIMIT ? true : false
            } catch (err) {
                return false
            }
        },
    },
    {
        name: 'n_pre_pag.consorcio_carta',
        title: 'Carta Crédito / Pré Pags Con',
        getCellValue: (row) => {
            try {
                return row.n_pre_pag.consorcio_carta
            } catch (err) {
                return 0
            }
        },
    },
    // ALERTAS FINANCIAMENTO
    {
        name: 'v_prepag.retail',
        title: 'Total Pré Pags Fin',
        getCellValue: (row) => {
            try {
                return row.v_prepag.retail
            } catch (err) {
                return 0
            }
        },
    },
    {
        name: 'n_pre_pag.retail',
        title: 'Qtd Pré. Pags Fin',
        getCellValue: (row) => {
            try {
                return row.n_pre_pag.retail
            } catch (err) {
                return 0
            }
        },
    },
    {
        name: 'n_pre_pag.retail_10k',
        title: 'Pré Pags Fin >= 10k',
        getCellValue: (row) => {
            console.log(row)
            try {
                const value =
                    row.v_prepag.retail === undefined ||
                    row.v_prepag.retail === null
                        ? 0
                        : row.v_prepag.retail
                return value > LIMIT ? true : false
            } catch (err) {
                return false
            }
        },
    },
    // {
    //   name: "n_ativos.total",
    //   title: "Qtd Ativos",
    //   getCellValue: row => row.n_ativos.total
    // },

    // { name: "monthly_income", title: "Renda" },

    // {
    //   name: "n_ativos.total_cotas",
    //   title: "Num Cotas",
    //   getCellValue: row => row.n_ativos.total_cotas
    // },
    // {
    //   name: "v_ativos.total_con_money",
    //   title: "Total Cotas",
    //   getCellValue: row => row.v_ativos.total_con_money
    // },
    // {
    //   name: "n_ativos.retail",
    //   title: "Num Financiamento",
    //   getCellValue: row => row.n_ativos.retail
    // },
    // {
    //   name: "v_ativos.total_ret_money",
    //   title: "Total Financiamento",
    //   getCellValue: row => row.v_ativos.total_ret_money
    // },
    // {
    //   name: "n_pre_pag.total",
    //   title: "Num Pre/Pagamento",
    //   getCellValue: row => {
    //     try {
    //       return row.n_pre_pag.total;
    //     } catch (err) {
    //       return 0;
    //     }
    //   }
    // },
    // {
    //   name: "red_flags.total",
    //   title: "Num Red/Pre/Pag",
    //   getCellValue: row => {
    //     try {
    //       return row.red_flags.total;
    //     } catch (err) {
    //       return 0;
    //     }
    //   }
    // },

    // { name: "actionButtonSomething", title: "Ação" }
]

export const client_data_sorting = [{ columnName: 'name', direction: 'asc' }]
