// props.v_ativos.total_ret_money

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import Services from '../../../../services/Services'

const TotalFinancingFormatter = (props) => {
    const value = Services.maskMoney(props.row.v_ativos.total_ret_money)
    return (
        <div>
            <span>{ value }</span>
        </div>
    )
}

export const TotalFinancingTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={ TotalFinancingFormatter }
        { ...props }
    />
)
