import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import styles from './styles'
import MUIDataTable from 'mui-datatables'
import { toast } from 'react-toastify'
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress'
import { textLabels } from './textLabels'

class InovaTableComponent extends React.Component {
    api = this.props.api ? new this.props.api() : null

    state = {
        columns: this.props.columns,
        data: this.props.data,
        pageSize: 10,
        totalCount: 0,
        sorting: null,
        page: 0,
        count: 0,
        loading: this.props.loading,
        filter: [],
        where: null,
        options: {
            responsive: this.props.responsive,
            filter: this.props.filter,
            print: this.props.print,
            search: this.props.search,
            download: this.props.download,
            viewColumns: this.props.viewColumns,
            pagination: this.props.pagination,
            selectableRows: this.props.selectableRows,
            sort: this.props.sort,
            serverSide: this.props.serverSide,
            count: this.props.count,
            page: this.props.page,
        },
    }

    componentDidMount() {
        if (this.props.api) setTimeout(() => this.loadData(), 2000)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.api && prevState.where !== this.state.where) {
            console.warn('update filters', prevState.where, this.props.where)
            this.loadData()
        }
    }

    componentWillReceiveProps({ where }, nextProps) {
        this.setState({ where })
        // console.log('componentWillReceiveProps', where, this.state.where)
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const cond =
            this.state.where !== nextState.where ||
            this.state.data !== nextState.data ||
            this.state.page !== nextState.page ||
            this.state.pageSize !== nextState.pageSize ||
            this.state.loading !== nextState.loading
        return cond
    }

    changeSorting = async (sorting) => {
        await this.setState({
            loading: true,
            sorting,
        })
        this.loadData()
    }

    onPaginate = async (page) => {
        await this.setState({
            loading: true,
            options: {
                ...this.state.options,
                page,
            },
        })
        this.loadData()
    }

    onPageSize = async (pageSize) => {
        await this.setState({
            loading: true,
            pageSize,
            options: {
                ...this.state.options,
                page: 0,
                rowsPerPage: pageSize,
            },
        })
        this.loadData()
    }

    queryString = () => {
        const { sorting, pageSize, where } = this.state
        const { page } = this.state.options
        this.setState({ where: this.props.where })
        const query = {
            skip: pageSize * page,
            limit: pageSize,
            where: {},
            include: this.props.include ? this.props.include : null,
        }
        if (where) {
            where.map((item) => {
                // console.log('query item', item)
                if (item.type === 'or') {
                    query.where['or'] = item.value
                    return query
                }

                if (item.type === 'query') {
                    query.where[item.id] = item.query
                    return query
                }
                if (item.like) {
                    query.where[item.id] = { like: item.value, options: 'i' }
                } else {
                    query.where[item.id] = item.value
                }

                if (item.type === 'number') {
                    query.where[item.id] = parseInt(
                        item.value.replace(/\D/g, ''),
                    )
                }
            })
        }

        if (sorting) {
            query.order = sorting
        }
        // console.info('query', query)
        return query
    }

    loadData = async () => {
        const queryString = this.queryString()
        if (queryString === this.lastQuery) {
            this.setState({ loading: false })
            return null
        }
        // console.log('paginate', queryString)
        try {
            await this.setState({ data: [] })
            let data = await this.api.paginate(queryString).toPromise()
            const rows = data.items
            const count = data.counters.itemsTotal
            if (data) {
                data = data.items.map((row) =>
                    this.props.columns.map((col) =>
                        !row[col.field] ? null : row[col.field],
                    ),
                )

                // console.log('data', data, rows)
                await this.setState({
                    data,
                    rows,
                    options: {
                        ...this.state.options,
                        count,
                    },
                })
            }
        } catch (e) {
            // console.log('error', e)
            toast.error('error')
        } finally {
            this.setState({ loading: false })
        }
        this.lastQuery = queryString
    }

    render() {
        const { data, loading } = this.state
        const { columns, title } = this.props
        const options = this.props.data
            ? {
                  filter: false,
                  print: false,
                  search: false,
                  download: false,
                  viewColumns: false,
                  pagination: false,
                  selectableRows: false,
                  sort: false,
                  responsive: 'scroll',
              }
            : {
                  ...this.state.options,
                  textLabels: textLabels,
                  onTableChange: (action, tableState) => {
                      // eslint-disable-next-line default-case
                      // console.log('action', action, tableState)
                      switch (action) {
                          case 'changePage':
                              this.onPaginate(tableState.page)
                              break
                          case 'sort':
                              let order = tableState.announceText
                                  .split(' : ')[1]
                                  .replace('ending', '')
                              const field =
                                  columns[tableState.activeColumn].field
                              return this.changeSorting(`${field} ${order}`)

                          case 'changeRowsPerPage':
                              return this.onPageSize(tableState.rowsPerPage)

                          case 'rowsSelect':
                              const result = tableState.selectedRows.data.map(
                                  (i) => this.state.rows[i.index],
                              )
                              return this.props.onSelect(result)
                          default:
                              break
                      }
                  },
              }
        return (
            <React.Fragment>
                {loading && <LinearProgress color="primary" />}
                <MUIDataTable
                    title={title}
                    data={data}
                    columns={columns}
                    options={options}
                    className={this.props.className}
                />
            </React.Fragment>
        )
    }
}

InovaTableComponent.defaultProps = {
    pageSizes: [10, 15, 50, 100],
    pageSize: 10,
    page: 1,
    responsive: 'scroll',
    filter: false,
    print: true,
    search: false,
    download: false,
    viewColumns: true,
    pagination: true,
    selectableRows: false,
    sort: true,
    serverSide: true,
    loading: true,
    count: 0,
    title: null,
}

export const InovaTable = withStyles(styles, { withTheme: true })(
    InovaTableComponent,
)
export * from './columnFormats'
