import BrM from 'br-masks'
import currencyFormatter from 'currency-formatter'

import i18n from './i18n'

export default class Services {
    // Verifica se dois objetos são iguais.
    static objIsEqual = (a, b) => {
        if (a === null || a === undefined || b === null || b === undefined) {
            return false
        }

        // Create arrays of property names
        let aProps = Object.getOwnPropertyNames(a)
        let bProps = Object.getOwnPropertyNames(b)

        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length !== bProps.length) {
            return false
        }

        for (let i = 0; i < aProps.length; i++) {
            let propName = aProps[i]

            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false
            }
        }

        // If we made it this far, objects
        // are considered equivalent
        return true
    }

    // Adiciona mascara ao cpf_cnpj
    static maskCpfCnpj = (value) => {
        const mask = BrM.cpfCnpj(value + '')
        return mask
    }

    // Adiciona máscara ao dinheiro
    static maskMoney = (value) => {
        const mask = currencyFormatter.format(value, { locale: 'pt-BR' })
        return mask
    }

    // Adicionar mascara
    static insertMask = (field, value) => {
        if (field === 'cpf_f') {
            const mask = BrM.cpfCnpj(value + '')
            return mask
        } else if (
            field === 'monthly_income' ||
            field === 'monthly_income_final' ||
            field === 'v_prepag.total' ||
            field === 'v_prepag.consorcio' ||
            field === 'n_pre_pag.consorcio_carta' ||
            field === 'v_prepag.retail' ||
            field === ''
        ) {
            const mask = currencyFormatter.format(value, { locale: 'pt-BR' })
            return mask
        } else if (field === 'phones' || field === 'mobile') {
            const mask = BrM.phone(value)
            return mask
        } else if (field === 'addr_zipcode' || field === 'zipcode') {
            const mask = BrM.cep(value)
            return mask
        }

        return value
    }

    // Traduz o campo
    static translate(palavra) {
        const tradutor = i18n
        return tradutor.t(`${palavra}.label`)
    }
} // Fim da classe
