export class Ativo_rt {
    'NATIONALID'
    'ZIPCODE'
    'STDISD'
    'PHONE1'
    'PHONE2'
    'MOBILE'
    'MONTHLY_INCOME'
    'CNPJ_CPF'
    'AGREEMENTNO'
    'CUSTOMERNAME'
    'NATIONALITY'
    'ADDRESS'
    'CITY'
    'STATEDESC'
    'EMAIL'
    'DEALER_CODE'
    'CUSTOMER_NAME_PAYEE'
    'VEHICLE_MODEL'
    'STATUS'
    'FATHERNAME'
    'SEX'
    'MARITAL_STATUS'
    'PROVINCE'
    'LEGAL_NAME'
    'PROFESSION'
    'dtref'
    'data_prod'
    'data_type'
    'id'

    constructor(data) {
        Object.assign(this, data)
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `Ativo_rt`.
     */
    static getModelName() {
        return 'Ativo_rt'
    }

    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of Ativo_rt for dynamic purposes.
     **/
    static factory(data) {
        return new Ativo_rt(data)
    }

    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    static getModelDefinition() {
        return {
            name: 'Ativo_rt',
            plural: 'Ativo_rts',
            path: 'Ativo_rts',
            properties: {
                'NATIONALID': {
                    name: 'NATIONALID',
                    type: 'number',
                },
                'ZIPCODE': {
                    name: 'ZIPCODE',
                    type: 'number',
                },
                'STDISD': {
                    name: 'STDISD',
                    type: 'number',
                },
                'PHONE1': {
                    name: 'PHONE1',
                    type: 'number',
                },
                'PHONE2': {
                    name: 'PHONE2',
                    type: 'number',
                },
                'MOBILE': {
                    name: 'MOBILE',
                    type: 'number',
                },
                'MONTHLY_INCOME': {
                    name: 'MONTHLY_INCOME',
                    type: 'number',
                },
                'CNPJ_CPF': {
                    name: 'CNPJ_CPF',
                    type: 'number',
                },
                'AGREEMENTNO': {
                    name: 'AGREEMENTNO',
                    type: 'number',
                },
                'CUSTOMERNAME': {
                    name: 'CUSTOMERNAME',
                    type: 'number',
                },
                'NATIONALITY': {
                    name: 'NATIONALITY',
                    type: 'string',
                },
                'ADDRESS': {
                    name: 'ADDRESS',
                    type: 'string',
                },
                'CITY': {
                    name: 'CITY',
                    type: 'string',
                },
                'STATEDESC': {
                    name: 'STATEDESC',
                    type: 'string',
                },
                'EMAIL': {
                    name: 'EMAIL',
                    type: 'string',
                },
                'DEALER_CODE': {
                    name: 'DEALER_CODE',
                    type: 'string',
                },
                'CUSTOMER_NAME_PAYEE': {
                    name: 'CUSTOMER_NAME_PAYEE',
                    type: 'string',
                },
                'VEHICLE_MODEL': {
                    name: 'VEHICLE_MODEL',
                    type: 'string',
                },
                'STATUS': {
                    name: 'STATUS',
                    type: 'string',
                },
                'FATHERNAME': {
                    name: 'FATHERNAME',
                    type: 'string',
                },
                'SEX': {
                    name: 'SEX',
                    type: 'string',
                },
                'MARITAL_STATUS': {
                    name: 'MARITAL_STATUS',
                    type: 'string',
                },
                'PROVINCE': {
                    name: 'PROVINCE',
                    type: 'string',
                },
                'LEGAL_NAME': {
                    name: 'LEGAL_NAME',
                    type: 'string',
                },
                'PROFESSION': {
                    name: 'PROFESSION',
                    type: 'string',
                },
                'dtref': {
                    name: 'dtref',
                    type: 'Date',
                },
                'data_prod': {
                    name: 'data_prod',
                    type: 'string',
                },
                'data_type': {
                    name: 'data_type',
                    type: 'string',
                },
                'id': {
                    name: 'id',
                    type: 'any',
                },
            },
            relations: {},
        }
    }
}
