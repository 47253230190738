import React from 'react'
import { FuseUtils } from '@fuse/index'
import { Config as Dashboard } from 'pages/dashboard/config'
import { Config as Profile } from 'pages/profile/config'
import { Config as EDD } from 'pages/EDD/config'
import { Config as Imports } from 'pages/imports/config'
import { Config as ClientDetails } from 'pages/clientDetails/config'
import { Config as Clientes } from 'pages/clientes/config'
import Login from 'pages/login'

const routeConfigs = [Dashboard, Profile, EDD, Imports, ClientDetails, Clientes]

export const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: '/',
        component: () => <Login />,
    },
]
