import React from 'react'
import WidgetContent from './WidgetContent'

export default class DashboardContent extends React.PureComponent {
    render() {
        return (
            <div>
                <WidgetContent />
            </div>
        )
    }
}
