// Adiciona botões de comandos á tabela.
//thumb_up_alt

import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { Button } from '@material-ui/core'

const something = (cpf_cnpj) => {
    // alert("Fazer alguma coisa: " + cpf_cnpj);
}

const ActionButtonFormatter = (props) => {
    return (
        <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={ () => something(props.row.cpf_cnpj) }
        >
            Ação
        </Button>
    )
}

export const ActionButtonTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={ ActionButtonFormatter }
        { ...props }
    />
)
