import React from 'react'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/pt-br'

export const Date = ({ value }) => {
    if (!value) return ''
    return (
        <React.Fragment>
            <Moment locale="pt-br" format="DD[/]MM[/]YYYY">
                {new Date(value)}
            </Moment>
        </React.Fragment>
    )
}
