import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export default class EditorConvertToHTML extends React.Component {
    modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
            ],
            ['link', 'image'],
            ['clean'],
        ],
    }
    formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
    ]

    state = {
        text: '',
    }

    handleChange = (value) => {
        this.setState({ text: value })
        // this.props.saveToState(value)
    }

    render() {
        return (
            <ReactQuill
                value={ this.state.text }
                onChange={ this.handleChange }
                // modules={this.modules}
                // formats={this.formats}
            />
        )
    }
}
