import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import { Divider, List, Paper, Typography } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { FuseAnimate } from '@fuse'
import { schema } from './schema'
import FormSchema from 'components/FormSchema'

const styles = (theme) => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit',
            },
        },
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
})

class ContactsSidebarContent extends React.PureComponent {
    state = {
        name: '',
    }

    render() {
        const { onFilter } = this.props
        return (
            <FuseAnimate animation="transition.slideLeftIn" delay={200}>
                <Paper elevation={1} className="rounded-8">
                    <div className="p-24 flex items-center">
                        <Typography>Filtros</Typography>
                    </div>
                    <Divider />
                    <List>
                        <FormSchema
                            name={'Filtros'}
                            schema={schema}
                            onFilter={onFilter}
                            // disabled={progress}
                        />
                    </List>
                </Paper>
            </FuseAnimate>
        )
    }
}

export default withStyles(styles, { withTheme: true })(
    withRouter(ContactsSidebarContent),
)
