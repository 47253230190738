import i18next from 'i18next'

i18next.init({
    interpolation: {
        // React already does escaping
        escapeValue: false,
    },
    lng: 'br', // 'en' | 'es'
    // Using simple hardcoded resources for simple example
    resources: {
        en: {
            translation: {
                street: { label: 'street' },
                neighborhood: { label: 'neighborhood' },
                numbers: { label: 'numbers' },
                number_floors: { label: 'number_floors' },
                condominium_name: { label: 'condominium_name' },
                total_area: { label: 'total_area' },

                number_towers: { label: 'number_towers' },
                builder_name: { label: 'builder_name' },
                construction_permit_date: { label: 'construction_permit_date' },
                construction_completion_date: {
                    label: 'construction_completion_date',
                },
                ground_area: { label: 'ground_area' },
                number_apartments: { label: 'number_apartments' },
                total_parking_lots: { label: 'total_parking_lots' },
                total_deposits: { label: 'total_deposits' },
                apartment_number: { label: 'apartment_number' },
                aparment_type: { label: 'aparment_type' },
                floor_number: { label: 'floor_number' },
                ideal_fraction: { label: 'ideal_fraction' },
                endorsement_number: { label: 'endorsement_number' },
                endorsement_date: { label: 'endorsement_date' },
                transaction_value: { label: 'transaction_value' },
                writing_date: { label: 'writing_date' },
                writing_data: { label: 'writing_data' },
                parking_lot_with_enrollment_number: {
                    label: 'parking_lot_with_enrollment_number',
                },
                deposit_with_enrollment_number: {
                    label: 'deposit_with_enrollment_number',
                },
                new_owners: { label: 'new_owners' },
                andress: { label: 'andress' },
            },
        },
        br: {
            translation: {
                name: { label: 'Nome' },
                cpf_f: { label: 'CPF/CNPJ' },
                email: { label: 'Email' },
                mobile: { label: 'Celular' },
                phones: { label: 'Telefone' },
                nationality: { label: 'Nacionalidade' },
                profession: { label: 'Ramo' },
                addr_street: { label: 'Rua' },
                addr_city: { label: 'Cidade' },
                addr_uf: { label: 'Estado' },
                neighbor: { label: 'Bairro' },
                zipcode: { label: 'CEP' },
                street: { label: 'Rua' },
                city: { label: 'Cidade' },
                uf: { label: 'Estado' },
                neighbor: { label: 'Bairro' },
                zipcode: { label: 'CEP' },
                addr: { label: 'Endereço' },
                monthly_income: { label: 'Renda' },
                sex: { label: 'Gênero' },
                father_name: { label: 'Pai' },
                spouse_name: { label: 'Conjuguê' },
            },
        },
    },
})

export default i18next
