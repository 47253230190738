/* tslint:disable */
import { BaseLoopBackApi } from '../core/base.service'
import { LoopBackConfig } from '../../lb.config'
import { LoopBackFilter } from '../../models/BaseModels'
import { Trans_pre_pag_con } from '../../models/Trans_pre_pag_con'


/**
 * Api services for the `Trans_pre_pag_con` model.
 */

export class Trans_pre_pag_conApi extends BaseLoopBackApi {

    constructor() {

        super()

    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     *
     * @param {object} data Request data.
     *
     *  - `data` – `{object}` - Model instance data
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Trans_pre_pag_con` object.)
     * </em>
     */
    patchOrCreate(data, customHeaders) {

        let _method = 'PATCH'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/trans_pre_pag_cons'
        let _routeParams = {}
        let _postBody = {
            data: data,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     *
     * @param {any} id trans_pre_pag_con id
     *
     * @param {object} data Request data.
     *
     *  - `data` – `{object}` - An object of model property name/value pairs
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Trans_pre_pag_con` object.)
     * </em>
     */
    patchAttributes(id, data, customHeaders) {

        let _method = 'PATCH'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/trans_pre_pag_cons/:id'
        let _routeParams = {
            id: id,
        }
        let _postBody = {
            data: data,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {object} data Request data.
     *
     *  - `gte_10k` – `{boolean}` -
     *
     *  - `months` – `{number}` -
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * Data properties:
     *
     *  - `result` – `{any}` -
     */
    graphic(gte_10k, months, customHeaders) {

        let _method = 'POST'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/trans_pre_pag_cons/graphic'
        let _routeParams = {}
        let _postBody = {}
        let _urlParams = {}


        if (typeof gte_10k !== 'undefined' && gte_10k !== null) _urlParams.gte_10k = gte_10k
        if (typeof months !== 'undefined' && months !== null) _urlParams.months = months
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Trans_pre_pag_con` object.)
     * </em>
     */
    paginate(query, customHeaders) {

        let _method = 'POST'
        let _url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
            '/trans_pre_pag_cons/paginate'
        let _routeParams = {}
        let _postBody = {
            query: query,
        }
        let _urlParams = {}


        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
        return result
    }


    /**
     * The name of the model represented by this $resource,
     * i.e. `Trans_pre_pag_con`.
     */
    getModelName() {
        return 'Trans_pre_pag_con'
    }
}

