import React from 'react'

export const Money = ({ value }) => {
    const nf = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })

    if (typeof value === 'string') {
        // 1.399,99
        value = value.replace('.', '')
        value = parseInt(value.replace(',', '.'))
    }

    return (
        <React.Fragment>
            <span>{nf.format(value)}</span>
        </React.Fragment>
    )
}
