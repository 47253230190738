import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const PendingFormatter = (props) => {
    return <div>{ props.row.pending ? <span>Sim</span> : <span>Não</span> }</div>
}

export const PendingProvider = (props) => (
    <DataTypeProvider formatterComponent={ PendingFormatter } { ...props } />
)
